import React, { useState } from 'react';
import {
  ILocalScriptCell,
  ILocalScriptCellData,
  LocalScriptCellType,
} from '@crowdcall/shared/src/interfaces/phoneBank/script/ILocalScriptCell';
import { Popover, Icon, Button } from 'antd';
import { CrowdCallTheme } from '@crowdcall/shared/src/components/style/CrowdCallTheme';
import { generateUUID } from '@crowdcall/shared/src/util/uuidUtil';
import { Spacing } from '@crowdcall/shared/src/components/container/Spacing';

export const defaultCellValues: {
  name: string;
  defaultValue: ILocalScriptCellData;
  id: LocalScriptCellType;
  icon: any;
}[] = [
  {
    name: 'Static Text',
    defaultValue: {},
    id: 'text',
    icon: <Icon type="file-text" />,
  },
  {
    name: 'Divider',
    defaultValue: {},
    id: 'divider',
    icon: <Icon type="line" />,
  },
  {
    name: 'Multiple Choice',
    defaultValue: {
      answers: [{ text: 'Yes', value: 'yes', id: generateUUID() }, { text: 'No', value: 'no', id: generateUUID() }],
      showAllOptions: true,
    },
    id: 'selection',
    icon: <Icon type="profile" />,
  },
  {
    name: 'Text Input',
    defaultValue: {},
    id: 'text-input',
    icon: <Icon type="form" />,
  },
];
export const AddCellButton = (props: { addCell(cell: ILocalScriptCell); defaultVisible?: boolean }) => {
  const [visible, setVisible] = useState(props.defaultVisible || false);
  return (
    <Popover
      placement="bottom"
      trigger="click"
      title="Add a new script section "
      visible={visible}
      onVisibleChange={setVisible}
      content={
        <div css={{ width: 340, display: 'flex', justifyContent: 'center' }}>
          <div>
            {defaultCellValues.map(cellType => {
              return (
                <Spacing inline all={1} key={cellType.id}>
                  <Button
                    css={{ width: 150 }}
                    onClick={() => {
                      props.addCell({ type: cellType.id, id: generateUUID(), data: cellType.defaultValue });
                      setVisible(false);
                    }}
                  >
                    {cellType.icon} {cellType.name}
                  </Button>
                </Spacing>
              );
            })}
          </div>
        </div>
      }
    >
      <Icon type="plus-circle" css={{ fontSize: 64, color: CrowdCallTheme.colors.blue.primary }} />
    </Popover>
  );
};
