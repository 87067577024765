import React from 'react';
import Row from 'reactstrap/lib/Row';
import DashboardContainer from 'src/components/layout/container/DashboardContainer';
import { ContactListCard } from './ContactListCard';

export const ContactsPage = () => {
  return (
    <DashboardContainer title="Contacts" description="Configure the contacts that you will call through phone banks">
      <Row css={{ display: 'flex', justifyContent: 'center' }}>
        <ContactListCard />
      </Row>
    </DashboardContainer>
  );
};
