import * as React from 'react';
import { format, formatDistance, formatRelative } from 'date-fns';
import HelperToolTip from './HelperTooltip';
import { capitalizeFirstLetter } from '../../lib/strings';

/**
 *
 * @param date Desired Date
 * @returns formatted absolute date (ie last Friday at 12AM)
 */
export const formatAbsoluteDate = (date: Date) => (
  <HelperToolTip helpText={format(date, 'MM/dd/yyyy h:mm a')}>
    {capitalizeFirstLetter(formatRelative(date, new Date()))}
  </HelperToolTip>
);

/**
 *
 * @param date Desired Date
 * @returns formatted relative date (ie 6 days ago)
 */
export const formatRelativeDate = (date: Date) => (
  <HelperToolTip helpText={format(date, 'MM/dd/yyyy h:mm a')}>{formatDistance(date, new Date())} ago</HelperToolTip>
);
