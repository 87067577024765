import styled from '@emotion/styled';
import { CrowdCallTheme } from './CrowdCallTheme';

export type SubtleLinkProps = {
  disabled?: boolean;
};

export const SubtleLink = styled.span`
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  cursor: ${(props: SubtleLinkProps) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: color 0.3s;
  color: ${(props: SubtleLinkProps) => (props.disabled ? CrowdCallTheme.colors.gray[6] : '#1890ff')};

  :hover {
    color: ${(props: SubtleLinkProps) => (props.disabled ? CrowdCallTheme.colors.gray[7] : '#18d3ff')};
  }
`;
