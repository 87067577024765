import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import { getCleanErrorMessage } from '@crowdcall/shared/src/util/errorUtil';
import { Button, Icon, Result, Table, Tag } from 'antd';
import 'antd/dist/antd.css';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Query, useApolloClient } from 'react-apollo';
import { Card, CardBody, CardTitle } from 'reactstrap';
import CopyButton from 'src/components/generic/CopyButton';
import HelperTooltip from 'src/components/generic/HelperTooltip';
import { formatAbsoluteDate } from 'src/components/generic/DateHelpers';
import { ContactUploadWizard } from './upload/ContactUploadWizard';

export const ContactListCard = () => {
  const [wizardOpen, setWizardState] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const client = useApolloClient();
  return (
    <>
      {wizardOpen && <ContactUploadWizard close={() => setWizardState(false)} />}
      <Card css={{ maxWidth: 900, minWidth: 600 }}>
        <CardBody className="table-responsive">
          <CardTitle className="text-uppercase h6">
            <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                Contact Groups
                <br />
                <Button
                  css={{ marginTop: 8 }}
                  type={showArchived ? 'primary' : 'dashed'}
                  onClick={() => setShowArchived(!showArchived)}
                  size="small"
                >
                  Show {showArchived ? 'Active' : 'Archived'}
                </Button>
              </div>
              <div>
                <Button
                  size="large"
                  onClick={() => setWizardState(true)}
                  type="primary"
                  icon="file-add"
                  css={{ marginRight: 16 }}
                >
                  Upload Contacts
                </Button>
              </div>
            </div>
          </CardTitle>
          <Query
            query={gql`
              query {
                getCurrentUser {
                  id
                  contactLists {
                    id
                    name
                    contactCount
                    archived
                    createDate
                    phoneBanks {
                      id
                      name
                    }
                  }
                }
              }
            `}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return <ThreeDotsLoader />;
              }
              if (error) {
                return (
                  <Result status="error" title="Could not fetch contact lists" subTitle={getCleanErrorMessage(error)} />
                );
              }
              const contactListsToRender = data.getCurrentUser.contactLists
                .map(list => ({
                  ...list,
                  key: list.id,
                }))
                .filter(contactList => (showArchived ? contactList.archived : !contactList.archived));
              return (
                <div>
                  <Table
                    columns={[
                      {
                        title: 'ID',
                        dataIndex: 'id',
                        key: 'id',
                        render: id => (
                          <HelperTooltip helpText="Copy ID to clipboard">
                            <CopyButton toCopy={id} />
                          </HelperTooltip>
                        ),
                      },
                      {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        render: (name, list: any) => (
                          <span>
                            {name} {showArchived && list.archived && <Tag color="orange">Archived</Tag>}
                          </span>
                        ),
                        sortDirections: ['descend', 'ascend'],
                        sorter: (a, b) => {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        },
                      },
                      {
                        title: 'Created',
                        dataIndex: 'createDate',
                        key: 'createDate',
                        render: date => formatAbsoluteDate(new Date(date)),
                        sortDirections: ['descend', 'ascend'],
                        defaultSortOrder: 'descend',
                        sorter: (a, b) => {
                          return new Date(a.createDate).getTime() - new Date(b.createDate).getTime();
                        },
                      },
                      {
                        title: 'Contact Count',
                        dataIndex: 'contactCount',
                        key: 'contactCount',
                        render: count => (
                          <span>
                            <Icon type="user" /> {count}
                          </span>
                        ),
                        sortDirections: ['descend', 'ascend'],
                        sorter: (a, b) => {
                          return a.contactCount - b.contactCount;
                        },
                      },
                      {
                        title: 'Phonebanks',
                        dataIndex: 'phoneBanks',
                        key: 'phoneBanks',
                        render: phoneBanks => (
                          <div>
                            {phoneBanks.length === 0 && <span>None</span>}
                            {phoneBanks.map(phoneBank => (
                              <Tag key={phoneBank.id} color="purple">
                                {phoneBank.name}
                              </Tag>
                            ))}
                          </div>
                        ),
                        sorter: (a, b) => {
                          return a.phoneBanks.length - b.phoneBanks.length;
                        },
                      },
                      {
                        title: '',
                        key: 'actions',
                        render: (_, list: any) => (
                          <Button
                            onClick={async () => {
                              await client.mutate({
                                mutation: gql`
                                  mutation($contactListId: String!, $archive: Boolean!) {
                                    setContactListArchiveStatus(contactListId: $contactListId, archive: $archive) {
                                      archived
                                    }
                                  }
                                `,
                                variables: { contactListId: list.id, archive: !list.archived },
                              });
                              await client.resetStore();
                            }}
                          >
                            {list.archived ? 'Unarchive' : 'Archive'}
                          </Button>
                        ),
                      },
                    ]}
                    dataSource={contactListsToRender}
                  />
                </div>
              );
            }}
          </Query>
        </CardBody>
      </Card>
    </>
  );
};
