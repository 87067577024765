/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-classes-per-file */
import * as React from 'react';
import Cards from 'react-credit-cards';
import { zeroFill } from 'src/lib/strings';
import NewCardModal from './NewCardModal';

export class NoPaymentMethod extends React.Component<{}, { modalOpen: boolean }> {
  public state = {
    modalOpen: true,
  };

  public render() {
    return (
      <>
        {this.state.modalOpen && <NewCardModal close={() => this.setState({ modalOpen: false })} />}
        <div onClick={() => this.setState({ modalOpen: true })}>
          <Cards cvc="" expiry="" name="None Yet" number="????????????????" preview />
          <h6>
            <a href="#">Add your credit card now</a>
          </h6>
        </div>
      </>
    );
  }
}

// tslint:disable-next-line:max-classes-per-file
export class ExistingPaymentMethod extends React.Component<{ cardInfo: any }, { modalOpen: boolean }> {
  public state = {
    modalOpen: false,
  };

  public render() {
    const { cardInfo } = this.props;
    return (
      <>
        {this.state.modalOpen && <NewCardModal close={() => this.setState({ modalOpen: false })} />}
        <div>
          <Cards
            cvc=""
            name={cardInfo.name}
            issuer={cardInfo.brand}
            number={`**** **** **** ${cardInfo.last4}`}
            expiry={`${zeroFill(cardInfo.exp_month, 2)}/${cardInfo.exp_year}`}
            preview
          />
          <h6>
            <a href="#" onClick={() => this.setState({ modalOpen: true })}>
              Change Payment Source
            </a>
          </h6>
        </div>
      </>
    );
  }
}
