import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import gql from 'graphql-tag';
import * as React from 'react';
import { Query } from 'react-apollo';
import Table from 'reactstrap/lib/Table';
import { formatCurrency } from '../../../lib/numbers';

export default () => (
  <Query
    query={gql`
      query {
        getRates {
          inbound
          outbound
          phoneNumber
        }
      }
    `}
  >
    {({ loading, error, data }) => {
      if (loading) {
        return <ThreeDotsLoader />;
      }
      if (error) {
        return <p>{error}</p>;
      }
      return (
        <Table striped>
          <tbody>
            <tr>
              <td>Outbound Calls</td>
              <td>
                {formatCurrency(data.getRates.outbound)}
                <small>/min</small>
              </td>
            </tr>
            <tr>
              <td>Inbound Calls</td>
              <td>
                {formatCurrency(data.getRates.inbound)}
                <small>/min</small>
              </td>
            </tr>
            <tr>
              <td>Phone Numbers</td>
              <td>
                {formatCurrency(data.getRates.phoneNumber)}
                <small>/month</small>
              </td>
            </tr>
          </tbody>
        </Table>
      );
    }}
  </Query>
);
