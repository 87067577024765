import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import gql from 'graphql-tag';
import * as React from 'react';
import { Query } from 'react-apollo';
import { Button } from 'reactstrap';
import ExtendedTransactionModal from './ExtendedTransactionModal';

interface IProps {
  usage: boolean;
  renderRow: (transaction: any) => JSX.Element;
}

interface IState {
  modalOpen: boolean;
}
export default class extends React.Component<IProps, IState> {
  public state = {
    modalOpen: false,
  };

  public render() {
    return (
      <>
        {this.state.modalOpen && (
          <ExtendedTransactionModal
            usage={this.props.usage}
            close={() => {
              this.setState({ modalOpen: false });
            }}
          />
        )}
        <Query
          variables={{ usage: this.props.usage }}
          query={gql`
            query($usage: Boolean!) {
              getRecentTransactions(usage: $usage, limit: 5) {
                id
                date
                amount
                shortDescription
                extendedDescription
                status
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <ThreeDotsLoader />;
            }
            if (error) {
              return <p>{error.message}</p>;
            }
            const transactions = (data.getRecentTransactions as any[]).map((transaction: any) => ({
              ...transaction,
              date: new Date(transaction.date),
            }));
            const RowToRender: any = this.props.renderRow;
            return (
              <>
                <div className="small mb-4 card-subtitle">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      this.setState({ modalOpen: true });
                    }}
                  >
                    View all
                  </Button>
                </div>

                <table className="table">
                  <tbody>
                    {transactions.map((transaction: any) => (
                      <RowToRender key={transaction.id} transaction={transaction} />
                    ))}
                  </tbody>
                </table>
              </>
            );
          }}
        </Query>
      </>
    );
  }
}
