import { TrendingUpIcon } from 'mdi-react';
import * as React from 'react';
import { Card, CardGroup, CardTitle, Row } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import DashboardContainer from '../../components/layout/container/DashboardContainer';
import CallMinutesChart from './charts/CallMinutesChart';
import GenericBlockChartPlaceholder from './charts/GenericBlockChartPlaceholder';
import WhosCallingChart from './charts/WhosCallingChart';
import CallList from './lists/CallList';
import VoicemailList from './lists/VoicemailList';
import './style.scss';

export default () => (
  <div>
    <DashboardContainer title="Dashboard" description="Latest statistics and summary">
      <CardGroup className="sales-card mb-4">
        <Card style={{ flex: '3 0 0' }}>
          <CardBody>
            <CardTitle className="text-uppercase h6">Minutes spent in calls</CardTitle>
            <div className="small mb-4 card-subtitle">Growth over time</div>
            <div style={{ width: '100%', height: '280px' }}>
              <CallMinutesChart />
            </div>
          </CardBody>
        </Card>
        <Card className="aggregate-quantities">
          <CardBody>
            <h6 className="text-uppercase title font-weight-bold small">Total Minutes Called</h6>
            <h4 className="font-weight-normal mb-0">23,500,459</h4>
          </CardBody>
          <CardBody>
            <h6 className="text-uppercase title font-weight-bold small text-nowrap">Total Calls Made</h6>
            <h4 className="font-weight-normal mb-0">83,230</h4>
          </CardBody>
          <CardBody>
            <h6 className="text-uppercase title font-weight-bold small">Total Numbers Called</h6>
            <h4 className="font-weight-normal mb-0">583</h4>
          </CardBody>
        </Card>
      </CardGroup>

      <Row>
        <div className="col-sm-6 col-md-3">
          <Card className="mb-4">
            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold">Average Call Duration</CardTitle>
              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">2 mins 6 seconds</h3>
                <div className="small">
                  <TrendingUpIcon size="14" />
                  <span className="badge badge-danger">-28%</span>
                </div>
              </div>
            </CardBody>
            <div style={{ width: '100%', height: '60px' }}>
              <GenericBlockChartPlaceholder dataKey="uv" stroke="#69F0AE" fill="#69F0AE" />
            </div>
          </Card>

          <Card className="mb-4">
            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold">Successful Call Rate</CardTitle>
              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">65%</h3>
                <div className="small">
                  <TrendingUpIcon size="14" />
                  <span className="badge badge-danger">-12%</span>
                </div>
              </div>
            </CardBody>
            <div style={{ width: '100%', height: '60px' }}>
              <GenericBlockChartPlaceholder dataKey="br" stroke="#7C4DFF" fill="#7C4DFF" />
            </div>
          </Card>
        </div>
        <div className="col-sm-6 col-md-3">
          <Card className="mb-4">
            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold">Calls Made</CardTitle>
              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">789</h3>
                <div className="small">
                  <TrendingUpIcon size="14" />
                  <span className="badge badge-success">+8%</span>
                </div>
              </div>
            </CardBody>
            <div style={{ width: '100%', height: '60px' }}>
              <GenericBlockChartPlaceholder dataKey="sales" stroke="#448AFF" fill="#448AFF" />
            </div>
          </Card>

          <Card className="mb-4">
            <CardBody>
              <CardTitle className="text-uppercase small font-weight-bold">Calls Received</CardTitle>
              <div className="d-flex align-items-center">
                <h3 className="mr-2 font-weight-normal">2994</h3>
                <div className="small">
                  <TrendingUpIcon size="14" />
                  <span className="badge badge-success">+19%</span>
                </div>
              </div>
            </CardBody>
            <div style={{ width: '100%', height: '60px' }}>
              <GenericBlockChartPlaceholder dataKey="ns" stroke="#40C4FF" fill="#40C4FF" />
            </div>
          </Card>
        </div>

        <div className="mb-4 col-sm-12 col-md-6">
          <Card>
            <CardBody>
              <CardTitle className="text-uppercase h6">Who&apos;s Calling?</CardTitle>
              <div style={{ width: '100%', height: '276px' }}>
                <WhosCallingChart />
              </div>
            </CardBody>
          </Card>
        </div>
      </Row>

      <Row>
        <div className="col-md-5 mb-4">
          <VoicemailList />
        </div>
        <div className="col-md-7 mb-4">
          <CallList />
        </div>
      </Row>
    </DashboardContainer>
  </div>
);
