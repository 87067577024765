import { Form, FormGroup, Button, FormText, Input, Label, FormFeedback } from 'reactstrap';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { gql, ApolloClient } from 'apollo-boost';
import { ThreeDotsLoader } from '../Loader';

const form = (props: LoginFormProps & FormikProps<IFormValues>) => {
  if (props.isSubmitting) {
    return (
      <div style={{ margin: '0 auto', textAlign: 'center' }}>
        <ThreeDotsLoader />
      </div>
    );
  }
  return (
    <Form>
      <FormGroup className="mb-4">
        <Label>Email Address</Label>
        <Input
          type="email"
          name="email"
          onChange={props.handleChange}
          value={props.values.email}
          placeholder="Email"
          invalid={props.errors.email !== undefined}
        />
        <FormFeedback>{props.errors.email}</FormFeedback>
      </FormGroup>
      <FormGroup className="mb-4">
        <Label>Password</Label>
        <Input
          name="password"
          onChange={props.handleChange}
          value={props.values.password}
          type="password"
          placeholder="Password"
          invalid={props.errors.password !== undefined}
        />
        <FormFeedback>{props.errors.password}</FormFeedback>
        <FormText className="text-right">
          {props.forgotPasswordLinkExternal ? (
            <a target="_blank" rel="noopener noreferrer" href="https://app.crowdcall.us/forgot-password">
              Forget your password?
            </a>
          ) : (
            <Link to="/forgot-password">Forget your password?</Link>
          )}
        </FormText>
      </FormGroup>
      <FormGroup className="text-right">
        <Button onClick={props.submitForm} disabled={props.isSubmitting} type="submit" color="primary">
          Sign In
        </Button>
      </FormGroup>
    </Form>
  );
};

interface IFormValues {
  email: string;
  password: string;
}

interface LoginFormProps {
  apolloClient: ApolloClient<any>;
  onTokenReceived: (token: string) => void;
  forgotPasswordLinkExternal: boolean;
  expiresIn?: string;
}

const LoginForm = withFormik<LoginFormProps, IFormValues>({
  // Initial values empty
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required!'),
    password: Yup.string().required('Password is required!'),
  }),

  handleSubmit: async (values, actions) => {
    try {
      const response: any = await actions.props.apolloClient.mutate({
        variables: { email: values.email, password: values.password, expiresIn: actions.props.expiresIn || '12w' },
        mutation: gql`
          mutation($email: String!, $password: String!, $expiresIn: String!) {
            token: loginAdmin(email: $email, password: $password, expiresIn: $expiresIn)
          }
        `,
      });
      const { token } = response.data;
      actions.props.onTokenReceived(token);
    } catch (e) {
      // do nothing since apollo.ts will display it already
    }
    actions.setSubmitting(false);
  },
})(form);

export default LoginForm;
