import React, { useContext } from 'react';
import { Skeleton } from 'antd';
import { CellRenderProps } from '../CellComponents';
import { SlateEditor } from '../../../editor/SlateEditor';
import { TextCell } from '../../../../interfaces/phoneBank/script/cell-types/TextCell';
import { InteractionDataContext } from '../../InteractionDataReducer';

export const TextCellRender = (props: CellRenderProps) => {
  const data = props.data as TextCell;
  const [{ loading, contact, caller }] = useContext(InteractionDataContext);
  if (loading) {
    return <Skeleton active />;
  }
  return (
    <div>
      <SlateEditor
        readOnly
        defaultValue={data.text}
        placeholderValues={{
          CONTACT_FIRST_NAME: contact.firstName,
          CONTACT_LAST_NAME: contact.lastName,
          CALLER_FIRST_NAME: caller.firstName,
          CALLER_LAST_NAME: caller.lastName,
        }}
      />
    </div>
  );
};
