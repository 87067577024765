import React from 'react';
import { ILocalScript } from '../../interfaces/phoneBank/script/ILocalScript';
import { cellComponents } from './cells/CellComponents';
import { ILocalScriptCell } from '../../interfaces/phoneBank/script/ILocalScriptCell';

export type ScriptProps = {
  script: ILocalScript;
};

const ScriptRenderCell = React.memo(({ cell }: { cell: ILocalScriptCell }) => {
  const cellComponent = cellComponents.find(c => c.id === cell.type);
  const Preview = cellComponent!.render;
  return <Preview data={cell.data} />;
});

export const Script = ({ script }: ScriptProps) => {
  return (
    <div>
      {script.cells.map(cell => (
        <ScriptRenderCell cell={cell} key={cell.id} />
      ))}
    </div>
  );
};
