import * as React from 'react';
import './centered-container.scss';
import ToastNotificationContainer from '@crowdcall/shared/src/components/ToastNotificationContainer';

export default (props: any) => (
  <>
    <ToastNotificationContainer centered />
    <div className="view">
      <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
        {props.children}
      </div>
    </div>
  </>
);
