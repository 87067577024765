import React, { ReactNode, useState } from 'react';
import { Icon } from 'antd';
import { CrowdCallTheme } from '../style/CrowdCallTheme';

export const LargeActionButton = ({
  content,
  onSelect,
  highlighted,
}: {
  content: ReactNode;
  onSelect: () => void | Promise<void>;
  highlighted?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const click = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    await onSelect();
    setLoading(false);
  };
  return (
    <div
      css={{ display: 'flex', justifyContent: 'center' }}
      onClick={click}
      onKeyPress={click}
      tabIndex={0}
      role="button"
    >
      <div
        css={{
          background: highlighted ? '#E6F7FF' : CrowdCallTheme.colors.gray[2],
          border: `1px solid ${highlighted ? '#91D5FF' : CrowdCallTheme.colors.gray[4]}`,
          boxSizing: 'border-box',
          borderRadius: '4px',
          width: '400px',
          maxWidth: '100%',
          margin: '5px 0',
          textAlign: 'left',
          padding: 16,
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          ':hover': {
            background: highlighted ? '#CFEBF9' : CrowdCallTheme.colors.gray[3],
          },
        }}
      >
        {content}

        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Icon type={loading ? 'loading' : 'right'} css={{ fontSize: 32, color: CrowdCallTheme.colors.gray[6] }} />
        </div>
      </div>
    </div>
  );
};

export const TitledLargeActionButton = ({
  title,
  description,
  onSelect,
  highlighted,
}: {
  title: string | ReactNode;
  description: string | ReactNode;
  onSelect: () => void | Promise<void>;
  highlighted?: boolean;
}) => (
  <LargeActionButton
    highlighted={highlighted}
    onSelect={onSelect}
    content={
      <div>
        <span
          css={{
            fontSize: 16,
            color: CrowdCallTheme.colors.gray[9],
          }}
        >
          {title}
        </span>
        <br />
        <span
          css={{
            fontSize: 14,
            color: CrowdCallTheme.colors.gray[8],
          }}
        >
          {description}
        </span>
      </div>
    }
  />
);
