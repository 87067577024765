import * as React from 'react';
import { AreaChart, Area, ResponsiveContainer, Tooltip } from 'recharts';

// Blocks Chart
// ------------
const blocksData = [
  { name: 'Monday', uv: 2034, sales: 623, br: 56, ns: 2343 },
  { name: 'Tuesday', uv: 2734, sales: 1223, br: 43, ns: 3200 },
  { name: 'Wednesday', uv: 2522, sales: 723, br: 64, ns: 3063 },
  { name: 'Thursday', uv: 2944, sales: 1043, br: 44, ns: 3666 },
  { name: 'Friday', uv: 1822, sales: 433, br: 74, ns: 1909 },
];

export default (props: { dataKey: string; stroke: string; fill: string }) => (
  <ResponsiveContainer>
    <AreaChart data={blocksData} margin={{ top: 0, bottom: 0, right: 0, left: 0 }}>
      <Tooltip labelStyle={{ display: 'none' }} itemStyle={{ fontSize: 10 }} wrapperStyle={{ borderColor: '#eee' }} />

      <Area
        type="monotone"
        dataKey={props.dataKey}
        stroke={props.stroke}
        fill={props.fill}
        strokeWidth={2}
        fillOpacity=".8"
      />
    </AreaChart>
  </ResponsiveContainer>
);
