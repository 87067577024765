/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import BalanceDisplay from '@crowdcall/dashboard/src/pages/billing/components/BalanceDisplay';
import { MenuIcon, PowerSettingsNewIcon } from 'mdi-react';
import * as React from 'react';
import Gravatar from 'react-gravatar';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { adminTokenStorage } from '../../../lib/storage';
import { getAdminUser } from '../../../lib/user';
import './style.scss';

export default (props: { toggleSidebar: (e: any) => void }) => {
  const user = getAdminUser();
  return (
    <header className="site-head d-flex align-items-center justify-content-between">
      <div className="wrap mr-4">
        <div onClick={props.toggleSidebar} style={{ cursor: 'pointer' }}>
          <MenuIcon size="24" color="#fff" />
        </div>
      </div>
      {/* <form className="col-7 col-sm-8 col-md-7 p-0 site-search">
        <SearchIcon color="#515151" size="22" />
        <input
          type="text"
          placeholder="Type your search ..."
          className="form-control"
        />
      </form> */}
      <div className="right-elems ml-auto d-flex">
        <div className="wrap profile">
          <UncontrolledDropdown>
            <DropdownToggle tag="div">
              <Gravatar email={user.email} default={'robohash' as any} />
            </DropdownToggle>
            <DropdownMenu className="right-dropdown" right>
              <DropdownItem header>
                Balance: <BalanceDisplay />
              </DropdownItem>
              <DropdownItem divider />
              {/* <DropdownItem>
                <FaceIcon size="16" />&emsp;<a href="#na">Your Profile</a>
              </DropdownItem>
              <DropdownItem>
                <HelpIcon size="16" />&emsp;<a href="#na">Help</a>
              </DropdownItem> */}
              <div className="text-right ml-3 mr-3 mt-2">
                <Button
                  block
                  color="danger"
                  size="sm"
                  onClick={() => {
                    adminTokenStorage.remove();
                    // eslint-disable-next-line no-restricted-globals
                    location.href = '/login';
                  }}
                >
                  <PowerSettingsNewIcon size="15" />
                  &emsp;Logout
                </Button>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </header>
  );
};
