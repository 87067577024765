import { HorizontalCenterDiv } from '@crowdcall/shared/src/components/container/HorizontalCenterDiv';
import { Spacing } from '@crowdcall/shared/src/components/container/Spacing';
import { CrowdCallTheme } from '@crowdcall/shared/src/components/style/CrowdCallTheme';
import { Badge, Progress, Tag, Icon, Button, Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import { formatRelativeDate } from 'src/components/generic/DateHelpers';
import { Redirect } from 'react-router-dom';

export type PhonebankCardProps = {
  id: string;
  name: string;
  createDate: Date;
  contactCount: number;
  completedInteractionCount: number;
  waitingInteractionCount: number;
};

export const PhonebankCard = ({
  id,
  name,
  createDate,
  contactCount,
  completedInteractionCount,
  waitingInteractionCount,
}: PhonebankCardProps) => {
  const [editWizardVisible, setEditWizardVisible] = useState(false);
  return (
    <Card
      style={{
        width: '375px',
        height: '375px',
        margin: '20px',
      }}
    >
      {editWizardVisible && <Redirect to={`/phonebanks/edit/${id}`} />}
      <CardBody>
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1
            className="text-uppercase h5"
            css={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          >
            {name}
          </h1>
          <div css={{ display: 'inline-block' }}>
            <Tag>
              <Badge status="processing" text="Active" />
            </Tag>
          </div>
        </div>
        <span css={{ color: CrowdCallTheme.colors.gray[6] }}>Created {formatRelativeDate(createDate)}</span>
        <br />
        <Spacing top={3}>
          <HorizontalCenterDiv>
            <Progress
              percent={
                (completedInteractionCount / contactCount) * 100 + (waitingInteractionCount / contactCount) * 100
              }
              successPercent={(completedInteractionCount / contactCount) * 100}
              width={150}
              type="dashboard"
              strokeColor="orange"
              format={(percent, successPercent) => (
                <div>
                  {Math.floor(successPercent!)}%
                  <br />
                  <span style={{ fontSize: 16 }}>complete</span>
                </div>
              )}
            />
            <br />

            <div css={{ color: CrowdCallTheme.colors.gray[7], fontSize: 14 }}>
              <Icon type="phone" /> {Number(contactCount).toLocaleString()} total contacts
              <br />
              <Icon type="clock-circle" /> {Number(waitingInteractionCount).toLocaleString()} waiting for callback
              {/* <br />
              <Icon type="smile" /> 6 callers */}
            </div>
            <Spacing top={2}>
              <Button.Group>
                <Button css={{ width: '150px' }} icon="edit" onClick={() => setEditWizardVisible(true)}>
                  Modify
                </Button>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <a href={`${process.env.REACT_APP_API_SERVER}/exports/phoneBank/${id}/contacts`}>
                          <Icon type="smile" css={{ marginRight: 2 }} /> Download Contact Data
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a href={`${process.env.REACT_APP_API_SERVER}/exports/phoneBank/${id}/interactions`}>
                          <Icon type="phone" css={{ marginRight: 2 }} /> Download Call Records
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a href={`${process.env.REACT_APP_API_SERVER}/exports/phoneBank/${id}/strikes`}>
                          <Icon type="stop" css={{ marginRight: 2 }} /> Download Strike List (Do Not Call)
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button icon="download" />
                </Dropdown>
              </Button.Group>
            </Spacing>
          </HorizontalCenterDiv>
        </Spacing>
      </CardBody>
    </Card>
  );
};
