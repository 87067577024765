import * as React from 'react';
import { Select } from 'antd';
import areaCodes, { AreaCodeInfo } from './areaCodes';

export type AreaCodeSearchProps = {
  onChange: (areaCodes: string[]) => void;
  defaultValue?: string[];
};
type AreaCodeSearchState = {
  relevantAreaCodes: AreaCodeInfo[];
};

let debounceTimeout;
const debounceTime = 100;

export default class AreaCodeSearch extends React.Component<AreaCodeSearchProps, AreaCodeSearchState> {
  state = {
    relevantAreaCodes: [],
  };

  render() {
    const { relevantAreaCodes } = this.state;
    return (
      <Select
        mode="multiple"
        optionLabelProp="value"
        onChange={this.props.onChange}
        defaultValue={this.props.defaultValue}
        onSearch={value => {
          if (debounceTimeout) {
            clearTimeout(debounceTimeout);
            debounceTimeout = null;
          }
          const fetch = () => {
            let newRelevantAreaCodes = areaCodes.filter(
              areaCode =>
                areaCode.area_code.indexOf(value) !== -1 ||
                areaCode.city.toLowerCase().indexOf(value.toLowerCase()) !== -1,
            );
            if (newRelevantAreaCodes.length > 20) {
              newRelevantAreaCodes = newRelevantAreaCodes.splice(20);
            }
            this.setState({ relevantAreaCodes: newRelevantAreaCodes });
          };
          debounceTimeout = setTimeout(fetch, debounceTime);
        }}
        tokenSeparators={[',']}
        placeholder="Enter area codes"
        filterOption={false}
        notFoundContent={null}
      >
        {relevantAreaCodes.map(areaCode => (
          <Select.Option key={areaCode.area_code} value={areaCode.area_code}>
            {areaCode.area_code} ({areaCode.city})
          </Select.Option>
        ))}
      </Select>
    );
  }
}
