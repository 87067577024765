import React from 'react';
import { SlateEditor } from '../../../editor/SlateEditor';
import { InputPrompt } from '../../../../interfaces/phoneBank/script/cell-types/InputPrompt';
import { InputHelper } from '../../../form/InputHelper';

export const QuestionTextInput = ({
  data,
  setData,
}: {
  data: InputPrompt;
  setData: (newData: InputPrompt) => void;
}) => {
  return (
    <InputHelper title="Question Text">
      <SlateEditor
        css={{ border: '1px black solid' }}
        enablePlaceholderEdits
        disableMultiline
        hover
        defaultValue={data.questionText}
        onChange={raw => setData({ ...data, questionText: raw })}
      />
    </InputHelper>
  );
};
