import * as React from 'react';
import { BootstrapTable } from 'react-bootstrap-table';

interface IProps {
  children: any;
  defaultSortField: string;
  defaultSortType: 'DESC' | 'ASC';
  getDataLength: (search: string) => Promise<number>;
  getData: (limit: number, offset: number, sortField: string, sortType: string, search: string) => Promise<any[]>;
}

interface IState {
  data: any[];
  limit: number;
  offset: number;
  sortField: string;
  sortType: 'DESC' | 'ASC';
  totalLength: number;
  search: string;
  loading: boolean;
}

export default class RemoteDataTable extends React.Component<IProps, IState> {
  public state = {
    data: [],
    limit: 10,
    offset: 0,
    totalLength: 0,
    sortField: this.props.defaultSortField,
    sortType: this.props.defaultSortType,
    search: '',
    loading: true,
  };

  public componentWillMount() {
    this.reloadData();
    this.reloadLength();
  }

  private refreshDataAndLength = (newState?: any) => {
    this.setState(
      {
        ...newState,
        data: [],
        totalLength: 0,
        loading: true,
      },
      () => {
        this.reloadData();
        this.reloadLength();
      },
    );
  };

  private refreshData = (newState?: any) => {
    this.setState(
      {
        ...newState,
        data: [],
        loading: true,
      },
      () => {
        this.reloadData();
      },
    );
  };

  private reloadLength = async () => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      totalLength: await this.props.getDataLength(this.state.search),
    });
  };

  private reloadData = async () => {
    const { limit, offset, sortField, sortType, search } = this.state;
    this.setState({
      data: await this.props.getData(limit, offset, sortField, sortType, search),
      loading: false,
    });
  };

  public render() {
    return (
      <>
        <BootstrapTable
          data={this.state.data}
          fetchInfo={{ dataTotalSize: this.state.totalLength }}
          options={{
            sortName: this.state.sortField,
            sortOrder: this.state.sortType.toLowerCase() as any,
            defaultSortName: this.props.defaultSortField,
            defaultSortOrder: this.props.defaultSortType.toLowerCase() as any,
            onSortChange: (sortName: string, sortOrder: 'desc' | 'asc') =>
              this.refreshData({ sortField: sortName, sortType: sortOrder.toUpperCase() as any }),
            noDataText: this.state.loading ? 'Loading...' : 'No results found',
            searchDelayTime: 100,
            sortIndicator: true,
            clearSearch: this.state.search !== '',
            onSearchChange: searchText => this.refreshDataAndLength({ search: searchText }),
            page: this.state.offset / this.state.limit + 1,
            sizePerPage: this.state.limit,
            onPageChange: (page: number, sizePerPage: number) => this.refreshData({ offset: (page - 1) * sizePerPage }),
            onSizePerPageList: (limit: number) => this.refreshData({ limit }),
            hidePageListOnlyOnePage: true,
          }}
          remote
          bordered={false}
          striped
          hover
          search
          version="4"
          pagination
        >
          {this.props.children}
        </BootstrapTable>
      </>
    );
  }
}
