import { Icon, Input, Switch } from 'antd';
import React from 'react';
import { Spacing } from '../../../container/Spacing';
import { CellEditorProps } from '../CellComponents';
import { FieldNameInput } from '../input/FieldNameInput';
import { QuestionTextInput } from '../input/QuestionTextInput';
import { TextInputCell } from '../../../../interfaces/phoneBank/script/cell-types/TextInputCell';
import { InputHelper } from '../../../form/InputHelper';

export const TextInputEditor = (props: CellEditorProps) => {
  const data = props.data as TextInputCell;

  return (
    <div css={{ padding: 16 }}>
      <div css={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <div>
          <FieldNameInput data={data} setData={props.setData} />
        </div>
        <div>
          <div css={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8 }}>
            <span>Multiple lines</span>
            <Switch
              css={{ marginLeft: 8 }}
              checked={data.block}
              onChange={newValue => props.setData({ ...data, block: newValue })}
            />
          </div>
        </div>
      </div>
      <Spacing top={2}>
        <QuestionTextInput data={data} setData={props.setData} />
      </Spacing>
      <Spacing top={2}>
        <InputHelper title="Placeholder Text" helper="This text will appear in the field before the user starts typing">
          <Input
            size="large"
            prefix={<Icon type="bulb" />}
            value={data.placeholder}
            onChange={e => props.setData({ ...data, placeholder: e.target.value })}
          />
        </InputHelper>
      </Spacing>
    </div>
  );
};
