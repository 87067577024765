export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const zeroFill = (val: string, width: number) => {
  const zeroesToAdd = width - val.toString().length;
  if (zeroesToAdd > 0) {
    return new Array(zeroesToAdd + (/\./.test(val) ? 2 : 1)).join('0') + val;
  }
  return `${val}`; // always return a string
};
