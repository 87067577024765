import * as React from 'react';
import { Button } from 'reactstrap';
import { ContentCopyIcon } from 'mdi-react';
import { toast } from 'react-toastify';

// tslint:disable-next-line:no-var-requires
const copy = require('clipboard-copy');

export default (props: { toCopy: string }) => {
  return (
    <Button
      onClick={async () => {
        await copy(props.toCopy);
        toast.success('Copied to clipboard!');
      }}
    >
      <ContentCopyIcon />
    </Button>
  );
};
