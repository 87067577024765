export const CrowdCallTheme = {
  colors: {
    blue: {
      dark: '#084E8A',
      primary: '#0A4BBB',
      accent: '#397DCF',
    },
    gray: {
      1: '#ffffff',
      2: '#fafafa',
      3: '#f5f5f5',
      4: '#e8e8e8',
      5: '#d9d9d9',
      6: '#bfbfbf',
      7: '#8c8c8c',
      8: '#595959',
      9: '#262626',
      10: '#000000',
    },
  },
};
