/* eslint-disable react/no-unused-state */
import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import AreaCodeSearch from '@crowdcall/shared/src/components/permit/areaCodeSearch/AreaCodeSearch';
import TimeRange from '@crowdcall/shared/src/components/timerange/TimeRange';
import { IOwnedPhoneNumber } from '@crowdcall/shared/src/interfaces/numbers/IOwnedPhoneNumber';
import { formatPhoneNumber } from '@crowdcall/shared/src/util/phoneNumberUtil';
import { Checkbox, DatePicker, Form, InputNumber, Select } from 'antd';
import 'antd/dist/antd.min.css';
import { gql } from 'apollo-boost';
import { Formik } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { ApolloConsumer, Query } from 'react-apollo';

type FormViewState = {
  dateInFocus: boolean;
  requireAreaCodeWhitelist: boolean;
  requireAllowedCallingHours: boolean;
};

export default class PermitUpdateForm extends React.Component<PermitGenerationFormProps, FormViewState> {
  state = {
    dateInFocus: false,
    requireAreaCodeWhitelist: false,
    requireAllowedCallingHours: false,
  };

  render() {
    return (
      <div style={{ textAlign: 'left', margin: '0 auto' }}>
        <ApolloConsumer>
          {client => (
            <Query
              query={gql`
                query($phoneBankId: String!) {
                  listOwnedPhoneNumbers {
                    id
                    nickname
                    number
                  }
                  getPhoneBank(id: $phoneBankId) {
                    id
                    callAccessPermit {
                      id
                      name
                      expirationDate
                      outboundMinuteLimit
                      areaCodeWhitelist
                      allowedCallingIdentities {
                        id
                        number
                      }
                      allowedCallHours
                      humanAccessCode
                    }
                  }
                }
              `}
              variables={{ phoneBankId: this.props.phoneBankId }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return <ThreeDotsLoader />;
                }
                if (error) {
                  return <p>{error}</p>;
                }
                const phoneNumbers: IOwnedPhoneNumber[] = data.listOwnedPhoneNumbers;
                const permit = data.getPhoneBank.callAccessPermit;
                let callingHoursCustom = false;
                permit.allowedCallHours.forEach(allowed => {
                  callingHoursCustom = callingHoursCustom || !allowed;
                }); // If any == false, calling hours is custom
                return (
                  <div>
                    <Formik<PermitGenerationFormValues>
                      initialValues={{
                        expirationDate: permit.expirationDate,
                        outboundMinuteLimit: permit.outboundMinuteLimit,
                        areaCodeWhitelist: permit.areaCodeWhitelist,
                        allowedCallingIdentities: permit.allowedCallingIdentities.map(identity => identity.number),
                        allowedCallHours: permit.allowedCallHours || new Array(24).fill(true),
                      }}
                      onSubmit={async values => {
                        await client.mutate({
                          variables: {
                            ...permit,
                            ...values,
                            enableHumanAccessCode: !!permit.humanAccessCode,
                          },
                          mutation: gql`
                            mutation(
                              $id: String!
                              $name: String!
                              $expirationDate: DateTime!
                              $outboundMinuteLimit: Float!
                              $areaCodeWhitelist: [String!]!
                              $allowedCallingIdentities: [String!]!
                              $allowedCallHours: [Boolean!]!
                              $enableHumanAccessCode: Boolean!
                            ) {
                              updateCallAccessPermit(
                                uuid: $id
                                name: $name
                                expirationDate: $expirationDate
                                allowedCallHours: $allowedCallHours
                                areaCodeWhitelist: $areaCodeWhitelist
                                outboundMinuteLimit: $outboundMinuteLimit
                                allowedCallingIdentities: $allowedCallingIdentities
                                enableHumanAccessCode: $enableHumanAccessCode
                              ) {
                                id
                                name
                                expirationDate
                                allowedCallHours
                                areaCodeWhitelist
                                outboundMinuteLimit
                                allowedCallingIdentities {
                                  id
                                }
                                humanAccessCode
                              }
                            }
                          `,
                        });
                        // const { permitId } = humanCodeResponse.data!.generateCallAccessPermit;
                      }}
                      render={form => {
                        const forceSubmit = () =>
                          setTimeout(() => {
                            form.submitForm();
                          }, 10);
                        return (
                          <Form onSubmit={form.handleSubmit} onReset={form.handleReset} layout="vertical">
                            <>
                              <Form.Item
                                label="Phone bank end date"
                                extra={`This ${this.props.entityName.toLowerCase()} will automatically end on the date above.`}
                              >
                                <DatePicker
                                  onChange={date => {
                                    form.setFieldValue('expirationDate', date!.toDate());
                                    forceSubmit();
                                  }}
                                  disabledDate={date => date!.toDate() < new Date()}
                                  defaultValue={moment(form.values.expirationDate)}
                                  onOpenChange={dateInFocus => this.setState({ dateInFocus })}
                                  open={this.state.dateInFocus}
                                />
                              </Form.Item>
                            </>
                            <Form.Item>
                              <Checkbox
                                checked={form.values.allowedCallingIdentities.length === 0}
                                onChange={event => {
                                  const { checked } = event.target;
                                  if (checked) {
                                    // They can use any number
                                    form.setFieldValue('allowedCallingIdentities', []);
                                  } else {
                                    // Restrict some numbers
                                    form.setFieldValue(
                                      'allowedCallingIdentities',
                                      phoneNumbers.map(number => number.number),
                                    );
                                  }
                                  forceSubmit();
                                }}
                              >
                                {this.props.entityName} can make calls using any phone number
                              </Checkbox>
                            </Form.Item>
                            {form.values.allowedCallingIdentities.length !== 0 && (
                              <Form.Item
                                label="Allowed Numbers"
                                extra={`This ${this.props.entityName} will only be able to make calls from the numbers listed above. CrowdCall will intelligently choose the area code that best fits the person being called. `}
                              >
                                <Select
                                  mode="multiple"
                                  onChange={values => {
                                    form.setFieldValue('allowedCallingIdentities', values);
                                    forceSubmit();
                                  }}
                                  optionLabelProp="title"
                                  value={form.values.allowedCallingIdentities}
                                  notFoundContent={null}
                                >
                                  {phoneNumbers.map(phoneNumber => (
                                    <Select.Option
                                      key={phoneNumber.number}
                                      value={phoneNumber.number}
                                      title={phoneNumber.nickname}
                                    >
                                      {phoneNumber.nickname}{' '}
                                      <span style={{ color: 'lightgray' }}>
                                        {formatPhoneNumber(phoneNumber.number)}
                                      </span>
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            )}

                            <Form.Item>
                              <Checkbox
                                checked={
                                  !(this.state.requireAreaCodeWhitelist || form.values.areaCodeWhitelist.length > 0)
                                }
                                onChange={event => {
                                  const { checked } = event.target;
                                  this.setState({ requireAreaCodeWhitelist: !checked });
                                  if (checked) {
                                    form.setFieldValue('areaCodeWhitelist', []);
                                    forceSubmit();
                                  }
                                }}
                              >
                                {this.props.entityName} can make calls to any area code
                              </Checkbox>
                            </Form.Item>

                            {(this.state.requireAreaCodeWhitelist || form.values.areaCodeWhitelist.length > 0) && (
                              <Form.Item
                                label="Allowed Area Codes"
                                extra={`This ${this.props.entityName.toLowerCase()} will only be able to make calls to numbers in the area codes above.
                        You may want to use this setting to restrict this ${this.props.entityName.toLowerCase()} to call phone numbers in a certain zone.`}
                              >
                                <AreaCodeSearch
                                  onChange={areaCodeWhitelist => {
                                    form.setFieldValue('areaCodeWhitelist', areaCodeWhitelist);
                                    forceSubmit();
                                  }}
                                  defaultValue={form.values.areaCodeWhitelist}
                                />
                              </Form.Item>
                            )}
                            <Form.Item>
                              <Checkbox
                                checked={!this.state.requireAllowedCallingHours && !callingHoursCustom}
                                onChange={event => {
                                  const { checked } = event.target;
                                  this.setState({ requireAllowedCallingHours: !checked });
                                  if (checked) {
                                    form.setFieldValue('allowedCallHours', new Array(24).fill(true));
                                    forceSubmit();
                                  }
                                }}
                              >
                                {this.props.entityName} can make calls at any time of day
                              </Checkbox>
                            </Form.Item>
                            {(this.state.requireAllowedCallingHours || callingHoursCustom) && (
                              <Form.Item>
                                Allow calls{' '}
                                <TimeRange
                                  onChange={values => {
                                    form.setFieldValue('allowedCallHours', values);
                                    forceSubmit();
                                  }}
                                  defaultValue={callingHoursCustom ? form.values.allowedCallHours : undefined}
                                />
                              </Form.Item>
                            )}

                            <Form.Item>
                              <Checkbox
                                checked={form.values.outboundMinuteLimit === -1}
                                onChange={event => {
                                  const { checked } = event.target;
                                  if (!checked) {
                                    form.setFieldValue('outboundMinuteLimit', 120);
                                  } else {
                                    form.setFieldValue('outboundMinuteLimit', -1);
                                  }
                                }}
                              >
                                {this.props.entityName} has no minute limit
                              </Checkbox>
                            </Form.Item>

                            {form.values.outboundMinuteLimit !== -1 && (
                              <Form.Item>
                                Only allow{' '}
                                <InputNumber
                                  value={form.values.outboundMinuteLimit}
                                  onChange={number => {
                                    form.setFieldValue('outboundMinuteLimit', number);
                                    forceSubmit();
                                  }}
                                />{' '}
                                minutes of calls per caller
                              </Form.Item>
                            )}
                          </Form>
                        );
                      }}
                    />
                  </div>
                );
              }}
            </Query>
          )}
        </ApolloConsumer>
      </div>
    );
  }
}

interface PermitGenerationFormValues {
  expirationDate: Date;
  outboundMinuteLimit: number;
  areaCodeWhitelist: string[];
  allowedCallingIdentities: string[];
  allowedCallHours: boolean[];
}

interface PermitGenerationFormProps {
  entityName: string;
  phoneBankId: string;
}
