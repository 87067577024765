/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { CurrencyUsdIcon } from 'mdi-react';
import * as React from 'react';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';
import NumberCard from '../../components/generic/NumberCard';
import DashboardContainer from '../../components/layout/container/DashboardContainer';
import BalanceChartContainer from './charts/BalanceChartContainer';
import BalanceDisplay from './components/BalanceDisplay';
import RatesTable from './components/RatesTable';
import { AddedFundsList, UsageList } from './lists/TransactionLists';
import DashboardPaymentDisplay from './payment/DashboardPaymentDisplay';

export default () => (
  <div>
    <DashboardContainer title="Billing" description="Manage payments and review usage">
      <Row>
        <div className="col-md-8 col-sm-12  mb-4">
          <Card style={{ flex: '2 0 0' }}>
            <CardBody>
              <CardTitle className="text-uppercase h6">Account Balance</CardTitle>
              <div
                style={{
                  width: '100%',
                  height: '280px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <BalanceChartContainer />
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-md-4 col-sm-12" style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ flex: 1 }} className="mb-4">
            <NumberCard title="Account Balance" icon={<CurrencyUsdIcon color="green" />}>
              <BalanceDisplay />
            </NumberCard>
          </div>
          <div style={{ flex: 1 }} className="mb-4">
            <Card>
              <CardBody>
                <h5>Your Rates</h5>
                <RatesTable />
                <small>
                  Have a question? <a href="#">Contact support</a>
                </small>
              </CardBody>
            </Card>
          </div>
        </div>
      </Row>
      <Row style={{ marginBottom: '29px' }}>
        <div className="col-12 mb-4">
          <Card style={{ height: '100%' }}>
            <CardBody>
              <CardTitle>Payment</CardTitle>
              <DashboardPaymentDisplay />
            </CardBody>
          </Card>
        </div>
      </Row>
      <Row>
        <div className="col-md-5 mb-4">
          <AddedFundsList />
        </div>
        <div className="col-md-7 mb-4">
          <UsageList />
        </div>
      </Row>
    </DashboardContainer>
  </div>
);
