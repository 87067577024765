import { AccountBalanceIcon, AndroidIcon, CardTravelIcon, DragVerticalIcon } from 'mdi-react';
import * as React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

export default () => (
  <Card>
    <CardBody>
      <CardTitle className="h6 text-uppercase">Recent Voicemails</CardTitle>
      <div className="small mb-4 card-subtitle">At a glance</div>
      <div className="d-flex mb-4 align-items-center">
        <AndroidIcon size="52" className="mr-3 text-success rounded-circle p-3" />
        <div>
          <h6 className="font-weight-semi-bold">Jenna enclosed a doc.</h6>
          <div className="small">3 min ago</div>
        </div>
      </div>

      <div className="d-flex mb-4 align-items-center">
        <CardTravelIcon size="52" className=" text-info mr-3 rounded-circle p-3" />
        <div>
          <h6 className="font-weight-semi-bold">Richard is now out of town.</h6>
          <div className="small">1 hour ago</div>
        </div>
      </div>

      <div className="d-flex mb-4 align-items-center">
        <DragVerticalIcon size="52" className=" text-danger mr-3 rounded-circle p-3" />
        <div>
          <h6 className="font-weight-semi-bold">Your domain will expire in 4 days</h6>
          <div className="small">5 hours ago</div>
        </div>
      </div>

      <div className="d-flex mb-4 align-items-center">
        <AccountBalanceIcon size="52" className=" text-primary mr-3 rounded-circle p-3" />
        <div>
          <h6 className="font-weight-semi-bold">Henry just purchased an item</h6>
          <div className="small">4 days ago</div>
        </div>
      </div>

      <div className="text-right">
        <button type="button" className="btn btn-sm btn-success">
          View All
        </button>
      </div>
    </CardBody>
  </Card>
);
