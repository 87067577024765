import { HorizontalCenterDiv } from '@crowdcall/shared/src/components/container/HorizontalCenterDiv';
import { WizardQuestion } from '@crowdcall/shared/src/components/container/wizard/WizardContainer';
import React, { useContext } from 'react';
import { WizardActions } from '@crowdcall/shared/src/components/container/wizard/WizardActions';
import { PhoneBankWizardContext } from '../../PhoneBankEditWizard';
import PermitUpdateForm from './PermitUpdateForm';
import { PhoneBankWizardPage } from '../../PhoneBankWizardNavigation';

export const PhoneBankPermitCreation = () => {
  const context = useContext(PhoneBankWizardContext);
  return (
    <>
      <HorizontalCenterDiv>
        <WizardQuestion>How do you want to restrict this phone bank?</WizardQuestion>
        <div css={{ width: 400, margin: '0 auto' }}>
          <PermitUpdateForm entityName="Phone bank" phoneBankId={context.phoneBankId} />
        </div>
        <WizardActions
          back={() => context.setPage(PhoneBankWizardPage.NAME)}
          next={() => context.setPage(PhoneBankWizardPage.CONTACT)}
        />
      </HorizontalCenterDiv>
    </>
  );
};
