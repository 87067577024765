import React, { useContext } from 'react';
import { Steps, Icon, Button } from 'antd';
import { Spacing } from '@crowdcall/shared/src/components/container/Spacing';
import { PhoneBankWizardContext } from './PhoneBankEditWizard';
import { PhoneBankNameInput } from './stages/PhoneBankNameInput';
import { PhoneBankPermitCreation } from './stages/permit/PhoneBankPermitCreation';
import { PhoneBankContactSelection } from './stages/PhoneBankContactSelection';
import { PhoneBankWelcomeText } from './stages/PhoneBankWelcomeText';
import { PhoneBankSharing } from './stages/PhoneBankSharing';
import { PhoneBankScriptSelection } from './stages/script/PhoneBankScriptSelection';

export enum PhoneBankWizardPage {
  NAME = 'name',
  PERMIT = 'permit',
  CONTACT = 'contact',
  WELCOME = 'welcome',
  SCRIPT = 'script',
  SHARE = 'share',
}

export const wizardPageData = [
  { title: 'Name', page: PhoneBankWizardPage.NAME, icon: <Icon type="tag" />, component: PhoneBankNameInput },
  {
    title: 'Restrictions',
    page: PhoneBankWizardPage.PERMIT,
    icon: <Icon type="lock" />,
    component: PhoneBankPermitCreation,
  },
  {
    title: 'Contacts',
    page: PhoneBankWizardPage.CONTACT,
    icon: <Icon type="contacts" />,
    component: PhoneBankContactSelection,
  },
  {
    title: 'Welcome',
    page: PhoneBankWizardPage.WELCOME,
    icon: <Icon type="rocket" />,
    component: PhoneBankWelcomeText,
  },
  {
    title: 'Script',
    page: PhoneBankWizardPage.SCRIPT,
    icon: <Icon type="profile" />,
    component: PhoneBankScriptSelection,
  },
  { title: 'Sharing', page: PhoneBankWizardPage.SHARE, icon: <Icon type="team" />, component: PhoneBankSharing },
];
export const PhoneBankWizardNavigation = () => {
  const { currentPage, setPage } = useContext(PhoneBankWizardContext);
  return (
    <>
      <Steps direction="vertical" onChange={() => {}}>
        {wizardPageData.map(step => (
          <Steps.Step
            status={step.page === currentPage ? 'process' : 'wait'}
            title={step.title}
            key={step.title}
            onClick={() => setPage(step.page)}
            icon={step.icon}
          />
        ))}
      </Steps>
      {/* <div css={{ textAlign: 'center' }}>
        <Spacing top={3}>
          <Button type="dashed" size="large">
            Archive
          </Button>
        </Spacing>
      </div> */}
    </>
  );
};
