import styled from '@emotion/styled';
import {
  FormatBoldIcon,
  FormatItalicIcon,
  FormatListBulletedIcon,
  FormatListNumberedIcon,
  FormatUnderlineIcon,
  Numeric1BoxIcon,
  Numeric2BoxIcon,
} from 'mdi-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Block } from 'slate';
import { Editor } from 'slate-react';
import { CrowdCallTheme } from '../../style/CrowdCallTheme';

const DEFAULT_NODE = 'paragraph';
const MarkButton = ({ editor, type, icon }: { editor: Editor; type: string; icon: JSX.Element }) => {
  const { value } = editor;
  const isActive = value.activeMarks.some(mark => mark.type === type);

  return (
    <IconButton
      icon={icon}
      active={isActive}
      onClick={event => {
        event.preventDefault();
        editor.toggleMark(type);
      }}
    />
  );
};

const BlockButton = ({ editor, type, icon }: { editor: Editor; type: string; icon: JSX.Element }) => {
  const { value } = editor;
  const hasBlock = (checking: string) => value.blocks.some(node => node.type === checking);
  let isActive = hasBlock(type);
  if (['numbered-list', 'bulleted-list'].includes(type)) {
    if (value.blocks.size > 0) {
      const parent = value.document.getParent(value.blocks.first().key) as Block;
      isActive = hasBlock('list-item') && parent && parent.type === type;
    }
  }
  return (
    <IconButton
      icon={icon}
      active={isActive}
      onClick={event => {
        event.preventDefault();
        if (type !== 'bulleted-list' && type !== 'numbered-list') {
          const isTypeActive = hasBlock(type);
          const isList = hasBlock('list-item');

          if (isList) {
            editor
              .setBlocks(isTypeActive ? DEFAULT_NODE : type)
              .unwrapBlock('bulleted-list')
              .unwrapBlock('numbered-list');
          } else {
            editor.setBlocks(isTypeActive ? DEFAULT_NODE : type);
          }
        } else {
          // Handle the extra wrapping required for list buttons.
          const isList = hasBlock('list-item');
          const isType = value.blocks.some(block => {
            return !!value.document.getClosest(block.key, (parent: Block) => parent.type === type);
          });

          if (isList && isType) {
            editor
              .setBlocks(DEFAULT_NODE)
              .unwrapBlock('bulleted-list')
              .unwrapBlock('numbered-list');
          } else if (isList) {
            editor.unwrapBlock(type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list').wrapBlock(type);
          } else {
            editor.setBlocks('list-item').wrapBlock(type);
          }
        }
      }}
    />
  );
};

const IconButton = ({
  icon,
  active,
  onClick,
}: {
  icon: JSX.Element;
  active: boolean;
  onClick: (e: React.MouseEvent<any, any>) => void;
}) => (
  <div
    style={{
      display: 'inline-flex',
    }}
    onMouseDown={onClick}
  >
    <span css={{ color: active ? CrowdCallTheme.colors.blue.primary : 'gray', padding: 8, fontSize: 16 }}>{icon}</span>
  </div>
);

const HoverMenuDiv = styled.div`
  padding: 8px 7px 6px;
  position: absolute;
  z-index: 5001;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: black;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  transition: opacity 0.75s;
  :after {
    content: '';
    position: absolute;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    top: 100%;
    left: calc(50% - 10px);
    background-color: black;
    width: 10px;
    height: 5px;

    /* The points are: (left top: x y, right top: x y, center bottom: x y) */
    clip-path: polygon(0 0, 100% 0, 50% 100%);
  }
`;
const ToolbarDiv = styled.div`
  position: relative;
  padding: 0px 32px;
  margin: 0 -32px;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
`;

export const SlateMenu = React.forwardRef(
  (
    { editor, allowBlocks, hover }: { editor: Editor; allowBlocks: boolean; hover: boolean },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const root = window.document.getElementById('root');
    const MenuComponent = hover ? HoverMenuDiv : ToolbarDiv;
    const Menu = (
      <MenuComponent ref={ref}>
        {allowBlocks && (
          <div css={{ display: 'inline-block', marginRight: 8 }}>
            <BlockButton editor={editor} type="header-1" icon={<Numeric1BoxIcon />} />
            <BlockButton editor={editor} type="header-2" icon={<Numeric2BoxIcon />} />
          </div>
        )}
        <div css={{ display: 'inline-block' }}>
          <MarkButton editor={editor} type="bold" icon={<FormatBoldIcon />} />
          <MarkButton editor={editor} type="italic" icon={<FormatItalicIcon />} />
          <MarkButton editor={editor} type="underlined" icon={<FormatUnderlineIcon />} />
        </div>
        {allowBlocks && (
          <div css={{ display: 'inline-block', marginLeft: 8 }}>
            <BlockButton editor={editor} type="bulleted-list" icon={<FormatListBulletedIcon />} />
            <BlockButton editor={editor} type="numbered-list" icon={<FormatListNumberedIcon />} />
          </div>
        )}
      </MenuComponent>
    );
    if (hover) {
      return ReactDOM.createPortal(Menu, root);
    }
    return Menu;
  },
);
