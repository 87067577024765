import * as React from 'react';
import { ResponsiveContainer, LineChart, YAxis, XAxis, CartesianGrid, Line, Tooltip } from 'recharts';

interface IProps {
  data: [
    {
      date: Date;
      balanace: number;
    },
  ];
}
export default (props: IProps) => (
  <ResponsiveContainer>
    <LineChart data={props.data} margin={{ top: 10, right: 10, left: -15, bottom: 0 }}>
      <XAxis dataKey="date" axisLine={false} tick={{ fontSize: 10 }} tickLine={false} padding={{ left: 0, right: 5 }} />
      <YAxis axisLine={false} tick={{ fontSize: 10 }} tickLine={false} />
      <CartesianGrid stroke="#eee" vertical={false} />
      <Tooltip wrapperStyle={{ borderColor: '#eee' }} />
      <Line
        name="Account Balance"
        type="monotone"
        dataKey="balance"
        strokeWidth={2}
        stroke="#448AFF"
        fill="#448AFF"
        fillOpacity=".8"
      />
    </LineChart>
  </ResponsiveContainer>
);
