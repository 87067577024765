import { TextCell } from '@crowdcall/shared/src/interfaces/phoneBank/script/cell-types/TextCell';
import React from 'react';
import { CellEditorProps } from '../CellComponents';
import { SlateEditor } from '../../../editor/SlateEditor';

export const TextCellEditor = (props: CellEditorProps) => {
  const data = props.data as TextCell;
  return (
    <SlateEditor
      enablePlaceholderEdits
      defaultValue={data.text}
      onChange={raw => props.setData({ ...data, text: raw })}
    />
  );
};
