import { HorizontalCenterDiv } from '@crowdcall/shared/src/components/container/HorizontalCenterDiv';
import { Spacing } from '@crowdcall/shared/src/components/container/Spacing';
import { WizardActions } from '@crowdcall/shared/src/components/container/wizard/WizardActions';
import { WizardDescription, WizardQuestion } from '@crowdcall/shared/src/components/container/wizard/WizardContainer';
import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import { useDebounce } from 'use-lodash-debounce';
import { Input } from 'antd';
import gql from 'graphql-tag';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useApolloClient } from 'react-apollo';
import { PhoneBankWizardContext } from '../PhoneBankEditWizard';
import { PhoneBankWizardPage } from '../PhoneBankWizardNavigation';

export const PhoneBankNameInput = () => {
  const [name, setName] = useState('');
  const debouncedName = useDebounce(name, 500);
  const [loading, setLoading] = useState(true);
  const context = useContext(PhoneBankWizardContext);
  const client = useApolloClient();

  useEffect(() => {
    async function fetchInitialData() {
      const resp = await client.query({
        query: gql`
          query($phoneBankId: String!) {
            getPhoneBank(id: $phoneBankId) {
              id
              name
            }
          }
        `,
        variables: { phoneBankId: context.phoneBankId },
      });
      let initialName = resp.data!.getPhoneBank.name;
      if (initialName === 'Untitled Phonebank') {
        initialName = '';
      }
      setName(initialName);
      setLoading(false);
    }
    fetchInitialData();
  }, [client, context.phoneBankId]);

  const save = useCallback(async () => {
    if (debouncedName === '') {
      return;
    }
    await client.mutate({
      mutation: gql`
        mutation($phoneBankId: String!, $name: String!) {
          updatePhoneBank(phoneBankId: $phoneBankId, name: $name) {
            id
            name
          }
        }
      `,
      variables: { phoneBankId: context.phoneBankId, name: debouncedName },
    });
  }, [client, context.phoneBankId, debouncedName]);

  useEffect(() => {
    save();
  }, [client, save]);

  if (loading) {
    return <ThreeDotsLoader />;
  }

  return (
    <HorizontalCenterDiv>
      <form>
        <WizardQuestion>What do you want to name this phone bank?</WizardQuestion>
        <Spacing top={2}>
          <WizardDescription>
            This name will be visible to you and your volunteers to help you find this phone bank in the future.
          </WizardDescription>
          <Spacing top={2}>
            <Input
              value={name}
              css={{ width: 400 }}
              onChange={e => setName(e.target.value)}
              size="large"
              placeholder="Enter a phone bank name"
            />
          </Spacing>
          <WizardActions
            back={context.close}
            backText="Exit"
            nextDisabled={name.length === 0}
            next={async () => {
              await save();
              context.setPage(PhoneBankWizardPage.PERMIT);
            }}
          />
        </Spacing>
      </form>
    </HorizontalCenterDiv>
  );
};
