import * as React from 'react';
import { Button } from 'reactstrap';

interface IProps {
  prompt: string | JSX.Element;
  confirmation: string | JSX.Element;
  onConfirm: () => void;
}

interface IState {
  clickedOnce: boolean;
}

export default class extends React.Component<IProps, IState> {
  public state = {
    clickedOnce: false,
  };

  public render() {
    if (!this.state.clickedOnce) {
      return (
        <Button
          color="danger"
          onClick={() => {
            this.setState({ clickedOnce: true });
          }}
        >
          {this.props.prompt}
        </Button>
      );
    }
    return (
      <Button
        color="warning"
        onClick={() => {
          this.props.onConfirm();
        }}
      >
        {this.props.confirmation}
      </Button>
    );
  }
}
