/* eslint-disable react/jsx-props-no-spreading */
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Tag } from 'antd';
import { isKeyHotkey } from 'is-hotkey';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Value, ValueJSON } from 'slate';
import { Editor, EditorProps, RenderBlockProps, RenderInlineProps, RenderMarkProps } from 'slate-react';
import { CrowdCallTheme } from '../style/CrowdCallTheme';
import { PlaceholderDrawer } from './components/placeholders/PlaceholderDrawer';
import { ScriptPlaceholders, ScriptPlaceholderValues } from './components/placeholders/ScriptPlaceholders';
import { SlateMenu } from './components/SlateMenu';

const isBoldHotkey = isKeyHotkey('mod+b');
const isItalicHotkey = isKeyHotkey('mod+i');
const isUnderlinedHotkey = isKeyHotkey('mod+u');

const emptyState: ValueJSON = {
  document: {
    nodes: [
      {
        object: 'block',
        type: 'line',
        nodes: [
          {
            object: 'text',
          },
        ],
      },
    ],
  },
};

const ContentContainer = styled.div<{ readOnly: boolean; disableMultiline: boolean; toolbar: boolean }>(
  ({ readOnly, disableMultiline, toolbar }) => {
    let padding = 32;
    if (readOnly) {
      padding = 0;
    }
    if (disableMultiline) {
      padding = 8;
    }
    return css`
      text-align: left;
      background-color: white;
      padding: ${padding}px;
      ${toolbar &&
        css`
          padding-top: 8px;
        `}
      ${disableMultiline &&
        `
        border: 1px ${CrowdCallTheme.colors.gray[5]} solid;
        borderRadius: 4px;
      `}
    `;
  },
);
export const SlateEditor = ({
  defaultValue,
  onChange,
  disableMultiline,
  readOnly,
  enablePlaceholderEdits,
  placeholderValues,
  hover,
}: {
  defaultValue?: ValueJSON;
  readOnly?: boolean;
  onChange?: (newValue: ValueJSON) => void;
  disableMultiline?: boolean;
  enablePlaceholderEdits?: boolean;
  placeholderValues?: ScriptPlaceholderValues;
  hover?: boolean;
}) => {
  const [value, setValue] = useState(Value.fromJSON(defaultValue || emptyState));
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (hover) {
      const menu = menuRef.current;
      if (!menu) return;

      const { fragment, selection } = value;

      if (selection.isBlurred || selection.isCollapsed || fragment.text === '') {
        menu.removeAttribute('style');
        return;
      }

      const native = window.getSelection();
      const range = native!.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      menu.style.opacity = '1';
      menu.style.top = `${rect.top + window.pageYOffset - menu.offsetHeight}px`;

      menu.style.left = `${rect.left + window.pageXOffset - menu.offsetWidth / 2 + rect.width / 2}px`;
    }
  }, [hover, menuRef, value]);

  const renderEditor = useCallback(
    (props: EditorProps, editor: any, next: () => any): any => {
      const children = next();
      return (
        <>
          <ContentContainer disableMultiline={disableMultiline} readOnly={readOnly} toolbar={!readOnly && !hover}>
            {!readOnly && (
              <SlateMenu allowBlocks={!disableMultiline} ref={menuRef} editor={editor} hover={hover || false} />
            )}
            {children}
          </ContentContainer>
          {enablePlaceholderEdits && <PlaceholderDrawer compact={disableMultiline || false} editor={editor} />}
        </>
      );
    },
    [disableMultiline, enablePlaceholderEdits, readOnly, hover],
  );
  const renderBlock = useCallback((props: RenderBlockProps, editor: any, next: () => any): any => {
    const { children, node, attributes } = props;

    switch (node.type) {
      case 'header-1':
        return <h2 {...attributes}>{children}</h2>;
      case 'header-2':
        return <h4 {...attributes}>{children}</h4>;
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      case 'list-item':
        return <li {...attributes}>{children}</li>;
      default:
        return next();
    }
  }, []);

  const renderMark = useCallback((props: RenderMarkProps, editor: any, next: () => any): any => {
    const { children, mark, attributes } = props;

    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;
      case 'italic':
        return <em {...attributes}>{children}</em>;
      case 'underlined':
        return <u {...attributes}>{children}</u>;
      default:
        return next();
    }
  }, []);

  const renderInline = (props: RenderInlineProps, editor: any, next: () => any) => {
    const { node } = props;
    if (node.type === 'placeholder') {
      const placeholder = ScriptPlaceholders.find(curr => curr.id === node.data.get('value'));
      return placeholderValues ? (
        <span>{placeholderValues[placeholder!.id]}</span>
      ) : (
        <Tag color="blue" css={{ margin: 0 }}>
          {placeholder!.name}
        </Tag>
      );
    }
    return next();
  };

  const schema = useMemo(
    () =>
      disableMultiline
        ? {
            // This schema will only allow a single block
            document: {
              nodes: [
                {
                  match: { type: 'line' },
                  min: 1,
                  max: 1,
                },
              ],
            },
            blocks: {
              paragraph: {
                marks: [{ type: 'underlined' }, { type: 'bold' }, { type: 'italic' }],
                nodes: [{ match: { object: 'text' } }],
              },
            },
            inlines: {
              placeholder: {
                isVoid: true,
              },
            },
          }
        : {
            inlines: {
              placeholder: {
                isVoid: true,
              },
            },
          },
    [disableMultiline],
  );

  const handleChange = useCallback(
    change => {
      setValue(change.value);
      if (onChange) {
        onChange(change.value.toJSON());
      }
    },
    [onChange],
  );

  const onKeyDown = useCallback((event, editor, next) => {
    let mark;

    if (isBoldHotkey(event)) {
      mark = 'bold';
    } else if (isItalicHotkey(event)) {
      mark = 'italic';
    } else if (isUnderlinedHotkey(event)) {
      mark = 'underlined';
    } else {
      return next();
    }

    event.preventDefault();
    editor.toggleMark(mark);
    return undefined;
  }, []);

  const placeholder = useMemo(() => (readOnly ? '' : 'Enter some text'), [readOnly]);

  return (
    <>
      <Editor
        placeholder={placeholder}
        value={readOnly ? Value.fromJSON(defaultValue || emptyState) : value}
        onChange={handleChange}
        renderEditor={renderEditor}
        renderMark={renderMark}
        renderBlock={renderBlock}
        renderInline={renderInline}
        readOnly={readOnly}
        onKeyDown={onKeyDown}
        schema={schema}
      />
    </>
  );
};
