import { gql } from 'apollo-boost';
import { FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import * as Yup from 'yup';
import { client } from '../../lib/apollo';

const form = (props: FormikProps<IFormValues>) => (
  <Form>
    <FormGroup className="mb-4">
      <Label>What&apos;s your email address?</Label>
      <Input
        type="email"
        name="email"
        onChange={props.handleChange}
        value={props.values.email}
        placeholder="Email"
        invalid={props.errors.email !== undefined}
      />
      <FormFeedback>{props.errors.email}</FormFeedback>
    </FormGroup>
    <FormGroup className="text-right">
      <Button onClick={props.submitForm} disabled={props.isSubmitting} type="submit" color="primary">
        Reset Password
      </Button>
    </FormGroup>
  </Form>
);

interface IFormValues {
  email: string;
}

export default withRouter(withFormik<{}, IFormValues>({
  // Initial values empty
  mapPropsToValues: () => {
    return {
      email: '',
    };
  },

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required!'),
  }),

  handleSubmit: async (values, actions) => {
    try {
      await client.mutate({
        variables: { email: values.email },
        mutation: gql`
          mutation($email: String!) {
            sent: sendAdminForgotPasswordEmail(email: $email)
          }
        `,
      });
      toast.success('Check your email for a link to reset your password!');
      (actions.props as any).history.push('/login');
    } catch (e) {
      actions.setSubmitting(false);
    }
  },
})(form) as any);
