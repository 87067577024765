import * as React from 'react';
import { Row } from 'reactstrap';
import DashboardContainer from '../../components/layout/container/DashboardContainer';
import PurchaseNewNumberCard from './buy/PurchaseNewNumberCard';
import OwnedNumberCards from './OwnedNumberCards';

export default () => (
  <div>
    <DashboardContainer title="Numbers" description="Buy and manage phone numbers">
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <PurchaseNewNumberCard />
        <OwnedNumberCards />
      </Row>
    </DashboardContainer>
  </div>
);
