import { HorizontalCenterDiv } from '@crowdcall/shared/src/components/container/HorizontalCenterDiv';
import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import { Script } from '@crowdcall/shared/src/components/script/Script';
import { CrowdCallTheme } from '@crowdcall/shared/src/components/style/CrowdCallTheme';
import { ILocalScript } from '@crowdcall/shared/src/interfaces/phoneBank/script/ILocalScript';
import { ILocalScriptCell } from '@crowdcall/shared/src/interfaces/phoneBank/script/ILocalScriptCell';
import { generateUUID } from '@crowdcall/shared/src/util/uuidUtil';
import arrayMove from 'array-move';
import gql from 'graphql-tag';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { useDebounce } from 'use-lodash-debounce';
import { WizardActions } from '@crowdcall/shared/src/components/container/wizard/WizardActions';
import {
  InteractionDataContext,
  InteractionDataActionTypes,
} from '@crowdcall/shared/src/components/script/InteractionDataReducer';
import { Button, Modal, Select } from 'antd';
import { Spacing } from '@crowdcall/shared/src/components/container/Spacing';
import { PhoneBankWizardContext } from '../../../PhoneBankEditWizard';
import { AddCellButton } from './AddCellButton';
import { ScriptCellEditor } from './ScriptCellEditor';
import { PhoneBankWizardPage } from '../../../PhoneBankWizardNavigation';
import { EmptyScript } from './EmptyScript';

const Cell = React.memo(
  SortableElement(
    ({
      cell,
      position,
      setScript,
    }: {
      cell: ILocalScriptCell;
      position: number;
      setScript: React.Dispatch<React.SetStateAction<ILocalScript>>;
    }) => (
      <ScriptCellEditor
        cellIndex={position}
        cell={cell}
        setData={cellData => {
          setScript(oldScript => {
            const newCells = [...oldScript.cells];
            newCells[position] = { ...oldScript.cells[position], data: cellData };
            return {
              ...oldScript,
              cells: newCells,
            };
          });
        }}
        duplicate={() => {
          setScript(oldScript => ({
            ...oldScript,
            cells: [...oldScript.cells, { ...cell, id: generateUUID() }],
          }));
        }}
        remove={() => {
          setScript(oldScript => {
            const newCells = [...oldScript.cells];
            newCells.splice(position, 1);
            return {
              ...oldScript,
              cells: newCells,
            };
          });
        }}
      />
    ),
  ),
);

const CellContainer = SortableContainer(
  ({
    cells,
    setScript,
  }: {
    setScript: React.Dispatch<React.SetStateAction<ILocalScript>>;
    cells: ILocalScriptCell[];
  }) => {
    return (
      <div>
        {cells.map((cell, i) => {
          return <Cell key={cell.id} cell={cell} index={i} position={i} setScript={setScript} />;
        })}
      </div>
    );
  },
);

export const ScriptEditor = () => {
  const context = useContext(PhoneBankWizardContext);
  const client = useApolloClient();
  const [script, setScript] = useState({} as ILocalScript);
  const [customFieldModalVisible, setCustomFieldModalVisible] = useState(false);
  const [, dispatch] = useContext(InteractionDataContext);
  const debouncedScript = useDebounce(script, 500);

  useEffect(() => {
    const loadInitialScript = async () => {
      dispatch({
        type: InteractionDataActionTypes.SET_DATA,
        value: {
          loading: true,
        },
      });
      const resp = await client.query({
        query: gql`
          query($phoneBankId: String!) {
            getCurrentUser {
              id
              firstName
              lastName
            }
            getPhoneBank(id: $phoneBankId) {
              id
              script {
                id
                ... on LocalScript {
                  cells {
                    id
                    type
                    data
                  }
                  shownCustomFields
                }
              }
            }
          }
        `,
        variables: { phoneBankId: context.phoneBankId },
      });
      dispatch({
        type: InteractionDataActionTypes.SET_DATA,
        value: {
          contact: { firstName: 'John', lastName: 'Smith' },
          caller: { firstName: resp.data!.getCurrentUser.firstName, lastName: resp.data!.getCurrentUser.lastName },
          loading: false,
          called: true,
        },
      });
      setScript(resp.data!.getPhoneBank.script as ILocalScript);
    };
    loadInitialScript();
  }, [client, context.phoneBankId, dispatch]);

  const save = useCallback(async () => {
    if (!debouncedScript || !debouncedScript.id) {
      return;
    }
    await client.mutate({
      mutation: gql`
        mutation($scriptId: String!, $cells: [JSONObject!]!, $shownCustomFields: [String!]!) {
          updateLocalScript(scriptId: $scriptId, cells: $cells, shownCustomFields: $shownCustomFields) {
            id
            ... on LocalScript {
              cells {
                type
                data
              }
            }
          }
        }
      `,
      variables: {
        scriptId: debouncedScript.id,
        cells: debouncedScript.cells,
        shownCustomFields: debouncedScript.shownCustomFields,
      },
    });
  }, [client, debouncedScript]);

  useEffect(() => {
    save();
  }, [client, save]);

  if (!script.id) {
    return <ThreeDotsLoader />;
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setScript({
      ...script,
      cells: arrayMove(script.cells, oldIndex, newIndex),
    });
  };

  return (
    <div css={{ display: 'flex', justifyContent: 'space-between', width: 1200, alignItems: 'flex-start' }}>
      <div>
        <CellContainer
          onSortEnd={onSortEnd}
          helperClass="drag-shadow"
          useDragHandle
          cells={script.cells}
          setScript={setScript}
        />
        <HorizontalCenterDiv css={{ width: 550 }}>
          <AddCellButton
            defaultVisible={!script.cells.length}
            addCell={newCell => {
              setScript({
                ...script,
                cells: [...script.cells, newCell],
              });
            }}
          />
        </HorizontalCenterDiv>
      </div>
      <div
        css={{
          width: 600,
          position: 'sticky',
          top: 50,
        }}
      >
        <p css={{ fontWeight: 'bold', fontSize: 18 }}>Script Preview</p>
        <p css={{ color: CrowdCallTheme.colors.gray[8] }}>
          This is what a caller would see if the person being called is named{' '}
          <span css={{ fontWeight: 'bold' }}>John Smith</span>
        </p>
        <div
          css={{
            backgroundColor: 'white',
            borderRadius: 16,
            padding: 32,
            overflow: 'scroll',
            maxHeight: '75vh',
          }}
        >
          {script.cells.length === 0 && <EmptyScript />}
          {script.cells.length > 0 && <Script script={script} />}
        </div>
        <Spacing top={2}>
          <Button type="dashed" onClick={() => setCustomFieldModalVisible(true)}>
            Configure Contact Data Visibility
          </Button>
          <Modal
            visible={customFieldModalVisible}
            onCancel={() => setCustomFieldModalVisible(false)}
            footer={[
              <Button key="back" type="primary" onClick={() => setCustomFieldModalVisible(false)}>
                OK
              </Button>,
            ]}
            title="Configure Contact Data Visibility"
          >
            <Select
              mode="tags"
              value={script!.shownCustomFields}
              style={{ width: '100%' }}
              onChange={shownCustomFields => {
                setScript({
                  ...script,
                  shownCustomFields,
                });
              }}
              placeholder="Enter column names for data that should be visible to volunteers"
            />
          </Modal>
        </Spacing>
        <WizardActions
          back={() => context.setPage(PhoneBankWizardPage.WELCOME)}
          next={() => context.setPage(PhoneBankWizardPage.SHARE)}
          nextText="Save"
        />
      </div>
    </div>
  );
};
