import { HorizontalWhiteLogo } from '@crowdcall/shared/src/components/branding/Logo';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import CenteredContainer from '../../components/layout/container/CenteredContainer';
import SignupForm from './SignupForm';

export default () => (
  <CenteredContainer>
    <div className="mb-3">
      <div style={{ width: '300px', margin: '0 auto', marginBottom: '8px' }}>
        <HorizontalWhiteLogo />
      </div>
      <Card className="form-card">
        <CardBody>
          <header>
            <h3>Create a new account</h3>
          </header>

          <SignupForm />
        </CardBody>
      </Card>
      <span style={{ float: 'right' }}>
        Already have an account? <Link to="/login">login</Link>
      </span>
    </div>
  </CenteredContainer>
);
