import React from 'react';
import { HorizontalWhiteLogo } from '../../branding/Logo';
import { CrowdCallTheme } from '../../style/CrowdCallTheme';
import { deviceSize } from '../../style/deviceSize';
import { FullScreenDiv } from '../FullScreenDiv';
import { VerticalCenterDiv } from '../VerticalCenterDiv';

export const WizardContainer = (props: { children: React.ReactElement; headerAction?: React.ReactElement }) => (
  <FullScreenDiv
    css={{
      backgroundColor: CrowdCallTheme.colors.gray[3],
    }}
  >
    <div
      css={{
        height: 90,
        backgroundColor: CrowdCallTheme.colors.blue.primary,
        width: '100vw',
        [deviceSize[0]]: {
          padding: '0px 50px',
        },
        [deviceSize[2]]: {
          padding: '0px 150px',
        },
      }}
    >
      <VerticalCenterDiv
        css={{
          width: 150,
          height: '100%',
        }}
      >
        <HorizontalWhiteLogo />
      </VerticalCenterDiv>
      {props.headerAction && (
        <VerticalCenterDiv
          css={{
            width: 150,
            float: 'right',
            height: '100%',
          }}
        >
          {props.headerAction}
        </VerticalCenterDiv>
      )}
    </div>

    <div css={{ margin: '70px 0px 30px 0px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <div
        css={{
          [deviceSize[0]]: {
            padding: '0px 50px',
          },
          [deviceSize[2]]: {
            padding: '0px 150px',
          },
        }}
      >
        {props.children}
      </div>
    </div>
  </FullScreenDiv>
);

export const WizardQuestion = (props: { children: string }) => (
  <h1 css={{ fontWeight: 'bold', fontSize: '32px' }}>{props.children}</h1>
);

export const WizardDescription = (props: { children: string | React.ReactElement }) => (
  <div
    css={{
      maxWidth: 400,
      fontSize: 16,
      fontWeight: 'normal',
      textAlign: 'left',
      margin: '0 auto',
    }}
  >
    {props.children}
  </div>
);
