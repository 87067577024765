import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import gql from 'graphql-tag';
import * as React from 'react';
import { Query } from 'react-apollo';
import 'react-credit-cards/lib/styles.scss';
import { Row } from 'reactstrap';
import StatusIndicator from 'src/components/generic/StatusIndicator';
import { ExistingPaymentMethod, NoPaymentMethod } from './PaymentMethod';

export default () => (
  <Row style={{ textAlign: 'center' }}>
    <Query
      query={gql`
        query {
          getDefaultPaymentSource {
            name
            address_line1
            address_line2
            address_city
            address_state
            address_zip
            last4
            exp_month
            exp_year
            brand
          }
        }
      `}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <ThreeDotsLoader />;
        }
        if (error) {
          return <p>{error}</p>;
        }

        const cardInfo = data.getDefaultPaymentSource;

        return (
          <>
            <div className="col-md-3 col-sm-12" style={{ textAlign: 'left' }}>
              <br />
              <span className="text-uppercase h6">Autopay </span>
              <StatusIndicator active message="Enabled" />
              <br />
              <span className="text-uppercase h6">Refill Point </span>
              <span style={{ color: 'green' }}>$1.00</span>
              <br />
              <span className="text-uppercase h6">Refill Amount </span>
              <span style={{ color: 'green' }}>$20.00</span>
              <br />
              <br />
              {cardInfo && (
                <>
                  <span className="text-uppercase h6">Billing Address: </span>
                  <div style={{ lineHeight: '5px', marginTop: '10px' }}>
                    <p>{cardInfo.name}</p>
                    <p>{cardInfo.address_line1}</p>
                    <p>{cardInfo.address_line2}</p>
                    <p>
                      {cardInfo.address_city}, {cardInfo.address_state} {cardInfo.address_zip}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-5 col-sm-12">
              {!cardInfo ? <NoPaymentMethod /> : <ExistingPaymentMethod cardInfo={cardInfo} />}
            </div>
            <div className="col-md-4 col-sm-12">
              <img style={{ width: '40%' }} alt="A secure credit card" src="assets/payment/payment-secure.png" />
              <h4>We take your security seriously</h4>
              <p>CrowdCall works with the world leader in secure payment processing.</p>
            </div>
          </>
        );
      }}
    </Query>
  </Row>
);
