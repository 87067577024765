import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { hot } from 'react-hot-loader';
import Router from './Router';
import './style/index.scss';

const render = () => {
  ReactDOM.render(<RootApp />, document.getElementById('root') as HTMLElement);
};

const RootApp = hot(module)(() => <Router />);

// testlog();
render();

// registerServiceWorker();

/*
if (module.hot) {
  module.hot.accept('./Router', () => {
    render();
  });
}
*/
