import React, { useContext } from 'react';
import { Button, Select, Skeleton } from 'antd';
import { SelectionCell } from '../../../../interfaces/phoneBank/script/cell-types/SelectionCell';
import { CellRenderProps } from '../CellComponents';
import { SlateEditor } from '../../../editor/SlateEditor';
import { InteractionDataContext, InteractionDataActionTypes, InteractionStatus } from '../../InteractionDataReducer';

export const SelectionRender = (props: CellRenderProps) => {
  const data = props.data as SelectionCell;
  const [interactionData, dispatch] = useContext(InteractionDataContext);
  if (interactionData.loading) {
    return <Skeleton title active />;
  }
  const selectedValues = ((interactionData.scriptData[data.fieldName] as string) || '')
    .split(', ')
    .filter(value => value);
  const setSelectedValues = (newValues: string[]) => {
    dispatch({
      type: InteractionDataActionTypes.SET_SCRIPT_DATA,
      key: data.fieldName,
      value: newValues.sort().join(', '),
    });
  };
  const answers = data.showAllOptions ? (
    data.answers.map(answer => {
      const selected = selectedValues.indexOf(answer.value) !== -1;
      return (
        <Button
          size="large"
          css={{ margin: 16, marginLeft: 0 }}
          disabled={!interactionData.called || interactionData.interactionStatus !== InteractionStatus.Contacted}
          key={answer.id}
          type={selected ? 'primary' : 'default'}
          onClick={() => {
            if (selected) {
              setSelectedValues(selectedValues.filter(value => value !== answer.value));
            } else {
              setSelectedValues(data.allowMultipleAnswers ? [...selectedValues, answer.value] : [answer.value]);
            }
          }}
        >
          {answer.text}
        </Button>
      );
    })
  ) : (
    <Select
      mode={data.allowMultipleAnswers ? 'multiple' : 'default'}
      size="large"
      placeholder="Select a response"
      disabled={!interactionData.called || interactionData.interactionStatus !== InteractionStatus.Contacted}
      css={{ minWidth: 200, marginTop: 16 }}
      value={selectedValues}
      onChange={value => {
        if (!data.allowMultipleAnswers) {
          setSelectedValues([value]);
        } else {
          setSelectedValues(value);
        }
      }}
    >
      {data.answers.map(answer => {
        return (
          <Select.Option value={answer.value} key={answer.id}>
            {answer.text}
          </Select.Option>
        );
      })}
    </Select>
  );

  return (
    <div>
      <div css={{ fontWeight: 'bold', textDecoration: 'underline', marginTop: 16 }}>
        <SlateEditor
          readOnly
          defaultValue={data.questionText}
          placeholderValues={{
            CONTACT_FIRST_NAME: interactionData.contact.firstName,
            CONTACT_LAST_NAME: interactionData.contact.lastName,
            CALLER_FIRST_NAME: interactionData.caller.firstName,
            CALLER_LAST_NAME: interactionData.caller.lastName,
          }}
        />
      </div>
      {answers}
    </div>
  );
};
