import { Button, Icon, Input, Switch } from 'antd';
import React from 'react';
import { SelectionCell } from '../../../../interfaces/phoneBank/script/cell-types/SelectionCell';
import { generateUUID } from '../../../../util/uuidUtil';
import { Spacing } from '../../../container/Spacing';
import { CrowdCallTheme } from '../../../style/CrowdCallTheme';
import { CellEditorProps } from '../CellComponents';
import { FieldNameInput } from '../input/FieldNameInput';
import { QuestionTextInput } from '../input/QuestionTextInput';

export const SelectionEditor = (props: CellEditorProps) => {
  const data = props.data as SelectionCell;

  const setAnswer = (answer: { id: string; text: string; value: string }) => {
    const newAnswers = [...data.answers];
    const i = newAnswers.findIndex(curr => answer.id === curr.id);
    newAnswers[i] = answer;
    props.setData({ ...data, answers: newAnswers });
  };
  return (
    <div css={{ padding: 16 }}>
      <div css={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <div>
          <FieldNameInput data={data} setData={props.setData} />
        </div>
        <div>
          <div css={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8 }}>
            <span>Use Dropdown</span>
            <Switch
              css={{ marginLeft: 8 }}
              checked={!data.showAllOptions}
              onChange={newValue => props.setData({ ...data, showAllOptions: !newValue })}
            />
          </div>
          <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span>Allow multiple answers</span>
            <Switch
              css={{ marginLeft: 8 }}
              checked={data.allowMultipleAnswers}
              onChange={newValue => props.setData({ ...data, allowMultipleAnswers: newValue })}
            />
          </div>
        </div>
      </div>
      <Spacing top={2}>
        <QuestionTextInput data={data} setData={props.setData} />
      </Spacing>
      <div>
        <span>Answers</span>
        <table css={{ borderSpacing: 4, borderCollapse: 'separate' }}>
          <tr css={{ color: CrowdCallTheme.colors.gray[7] }}>
            <th css={{ fontWeight: 'normal', width: '50%' }}>Text callers will see</th>
            <th css={{ fontWeight: 'normal', width: '50%' }}>How we will save this response</th>
            <th />
          </tr>

          {data.answers.map(answer => (
            <tr key={answer.id}>
              <td>
                <Input
                  size="small"
                  value={answer.text}
                  onChange={e => setAnswer({ ...answer, text: e.target.value })}
                />
              </td>
              <td>
                <Input
                  size="small"
                  value={answer.value}
                  onChange={e => setAnswer({ ...answer, value: e.target.value })}
                />
              </td>
              <td>
                <Icon
                  type="close-circle"
                  css={{ color: CrowdCallTheme.colors[5] }}
                  onClick={() =>
                    props.setData({ ...data, answers: data.answers.filter(curr => curr.id !== answer.id) })
                  }
                />
              </td>
            </tr>
          ))}
        </table>
        <div>
          <Button
            type="link"
            css={{ paddingLeft: 0 }}
            onClick={() =>
              props.setData({ ...data, answers: [...data.answers, { text: '', value: '', id: generateUUID() }] })
            }
          >
            Add another answer choice
          </Button>
        </div>
      </div>
    </div>
  );
};
