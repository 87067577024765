import { WizardContainer } from '@crowdcall/shared/src/components/container/wizard/WizardContainer';
import { Button } from 'antd';
import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { PhoneBankWizardNavigation, PhoneBankWizardPage, wizardPageData } from './PhoneBankWizardNavigation';

export type PhoneBankEditWizardProps = {
  phoneBankId: string;
  pageId: string;
};

export type PhoneBankWizardContextValue = {
  phoneBankId: string;
  currentPage: PhoneBankWizardPage;
  setPage: (page: PhoneBankWizardPage) => void;
  close: () => void;
};
export const PhoneBankWizardContext = React.createContext({} as PhoneBankWizardContextValue);

export const PhoneBankEditWizard = (props: RouteComponentProps<PhoneBankEditWizardProps>) => {
  const { phoneBankId, pageId } = props.match!.params;
  if (!pageId) {
    // TODO redirect smarter
    return <Redirect to={`/phonebanks/edit/${phoneBankId}/name`} />;
  }
  const currentPage = PhoneBankWizardPage[pageId.toUpperCase()] as PhoneBankWizardPage;
  const setCurrentPage = newPage => props.history.push(`/phonebanks/edit/${phoneBankId}/${newPage}`);
  const close = () => props.history.push(`/phonebanks/`);
  const PageComponent = wizardPageData.find(page => page.page === currentPage)!.component;
  return (
    <PhoneBankWizardContext.Provider
      value={{
        phoneBankId,
        currentPage,
        setPage: (page: PhoneBankWizardPage) => setCurrentPage(page),
        close,
      }}
    >
      <WizardContainer
        headerAction={
          <Button onClick={() => close()} ghost type="dashed">
            Save & Exit
          </Button>
        }
      >
        <div css={{ display: 'flex', width: '100%' }}>
          <div css={{ margin: '0 30px' }}>
            <PhoneBankWizardNavigation />
          </div>
          <div css={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <div>
              <PageComponent />
            </div>
          </div>
        </div>
      </WizardContainer>
    </PhoneBankWizardContext.Provider>
  );
};
