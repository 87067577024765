export enum ScriptPlaceholderType {
  CONTACT_FIRST_NAME = 'CONTACT_FIRST_NAME',
  CONTACT_LAST_NAME = 'CONTACT_LAST_NAME',
  CALLER_FIRST_NAME = 'CALLER_FIRST_NAME',
  CALLER_LAST_NAME = 'CALLER_LAST_NAME',
}

export const ScriptPlaceholders = [
  { name: 'Contact First', id: ScriptPlaceholderType.CONTACT_FIRST_NAME },
  { name: 'Contact Last', id: ScriptPlaceholderType.CONTACT_LAST_NAME },
  { name: 'Caller First', id: ScriptPlaceholderType.CALLER_FIRST_NAME },
  { name: 'Caller Last', id: ScriptPlaceholderType.CALLER_LAST_NAME },
];

export type ScriptPlaceholderValues = {
  CONTACT_FIRST_NAME: string;
  CONTACT_LAST_NAME: string;
  CALLER_FIRST_NAME: string;
  CALLER_LAST_NAME: string;
};
