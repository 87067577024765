// eslint-disable-next-line @typescript-eslint/camelcase
import jwt_decode from 'jwt-decode';
import { adminTokenStorage } from './storage';

export function getAdminUser(): any {
  return jwt_decode(adminTokenStorage.get()!);
}

export function getCurrentUserEmail(): string {
  return getAdminUser().email;
}
