import React from 'react';
import { HorizontalCenterDiv } from '@crowdcall/shared/src/components/container/HorizontalCenterDiv';
import { CrowdCallTheme } from '@crowdcall/shared/src/components/style/CrowdCallTheme';

export const EmptyScript = () => {
  return (
    <HorizontalCenterDiv>
      <img src="/assets/script/empty-clipboard.svg" css={{ width: '70%' }} alt="Empty clipboard" />
      <h2>Nothing yet</h2>
      <span css={{ fontSize: 18, color: CrowdCallTheme.colors.gray[6] }}>
        Add new sections to the script to see a preview
      </span>
    </HorizontalCenterDiv>
  );
};
