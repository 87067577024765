import { CrowdCallTheme } from '@crowdcall/shared/src/components/style/CrowdCallTheme';
import css from '@emotion/css';
import styled from '@emotion/styled';
import { MdiReactIconComponentType } from 'mdi-react';
import React from 'react';
import { Link } from 'react-router-dom';

export type SidebarSectionProps = {
  name: string;
  color: string;
  currentPath: string;
  links: {
    name: string;
    path: string;
    icon: MdiReactIconComponentType;
  }[];
};

const NavListLink = styled.li`
  cursor: pointer;
  display: block;
  padding: 1.1rem 1.5rem;
  position: relative;
`;

const NavListLinkName = styled.span`
  display: inline-block;
  margin-left: 1.5rem;
  text-transform: uppercase;
  font-size: 90%;
  transform: translate(0, 1.1px);

  &:hover {
    color: ${CrowdCallTheme.colors.blue.accent};
  }
`;

export const SidebarSection = (props: SidebarSectionProps) => {
  const { name, color, currentPath, links } = props;
  return (
    <li>
      <div
        css={css`
          padding: 0.4rem 1.6rem;
          margin: 1.4rem 0;
          font-size: 70%;
          text-transform: uppercase;
          font-weight: bold;
          position: relative;
          &:before {
            position: absolute;
            content: '';
            width: 15px;
            height: 2px;
            bottom: 0;
            background: ${color};
          }
        `}
      >
        {name}
      </div>
      {links.map(link => {
        const active = currentPath === link.path;
        const Icon = link.icon;
        const style = active
          ? { color: CrowdCallTheme.colors.blue.primary, fontWeight: 600 }
          : { color: CrowdCallTheme.colors.gray[9], fontWeight: 400 };
        return (
          <NavListLink>
            <Link css={style} to={`/${link.path}`}>
              <Icon size="18" color={color} />
              <NavListLinkName>{link.name}</NavListLinkName>
            </Link>
          </NavListLink>
        );
      })}
    </li>
  );
};
