import React from 'react';
import { CrowdCallTheme } from '../style/CrowdCallTheme';

export const InputHelper = ({
  children,
  title,
  helper,
}: {
  children: React.ReactNode | React.ReactNode[];
  title: string;
  helper?: string;
}) => {
  return (
    <div>
      <span>{title}</span>
      {children}
      <span css={{ color: CrowdCallTheme.colors.gray[6] }}>{helper}</span>
    </div>
  );
};
