/* eslint-disable react/jsx-props-no-spreading */
import { FormikProps, withFormik } from 'formik';
import * as React from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, Suggestion } from 'react-places-autocomplete';
import { Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import { Radio } from 'antd';

interface IProps {
  close: () => void;
  setQuery: (mode: string, query: string) => void;
}

const form = (props: IProps & FormikProps<IFormValues>) => (
  <Form>
    <ModalBody>
      <FormGroup className="mb-4">
        <Label css={{ marginRight: 8 }}>Search using</Label>
        <Radio.Group
          name="searchMode"
          onChange={props.handleChange}
          buttonStyle="solid"
          value={props.values.searchMode}
          defaultValue="LOCATION"
        >
          <Radio.Button value="LOCATION">Location</Radio.Button>
          <Radio.Button value="AREA_CODE">Area Code</Radio.Button>
        </Radio.Group>
        <FormFeedback>{props.errors.searchMode}</FormFeedback>
      </FormGroup>
      <FormGroup className="mb-4">
        <Label>{props.values.searchMode === 'LOCATION' ? 'Location' : 'Area Code'}</Label>

        {props.values.searchMode === 'LOCATION' && (
          <PlacesAutocomplete
            value={props.values.searchValue}
            onChange={value => props.setFieldValue('searchValue', value)}
            onSelect={value => props.setFieldValue('searchValue', value)}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <>
                  <Input
                    {...getInputProps({
                      id: 'address-input',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading ? <div>Loading...</div> : null}
                    {suggestions.map((suggestion: Suggestion) => {
                      const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';

                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: 3, paddingLeft: 0 }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };

                      const spread: any = {
                        ...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        }),
                      };

                      return (
                        <div {...spread} key={suggestion.id}>
                          <div>{suggestion.description}</div>
                        </div>
                      );
                    })}
                  </div>
                </>
              </div>
            )}
          </PlacesAutocomplete>
        )}

        {props.values.searchMode === 'AREA_CODE' && (
          <Input
            name="searchValue"
            type="number"
            onChange={props.handleChange}
            value={props.values.searchValue}
            placeholder="Desired Area Code (i.e. 828)"
            invalid={props.errors.searchValue !== undefined}
          />
        )}
        <FormFeedback>{props.errors.searchValue}</FormFeedback>
      </FormGroup>
    </ModalBody>
    <ModalFooter>
      <FormGroup className="text-left">
        <Button
          onClick={() => {
            props.close();
          }}
          type="button"
          color="secondary"
        >
          Cancel
        </Button>
      </FormGroup>
      <FormGroup className="text-right">
        <Button onClick={props.submitForm} disabled={props.isSubmitting} type="submit" color="primary">
          Search
        </Button>
      </FormGroup>
    </ModalFooter>
  </Form>
);

interface IFormValues {
  searchMode: string;
  searchValue: string;
}

export default withFormik<IProps, IFormValues>({
  mapPropsToValues: () => ({
    searchMode: 'LOCATION',
    searchValue: '',
  }),

  validationSchema: Yup.object().shape({
    searchValue: Yup.string().required('Search value is required!'),
  }),

  handleSubmit: async (submittedValues, actions) => {
    const values = submittedValues;
    if (values.searchMode === 'LOCATION') {
      const geocodes = await geocodeByAddress(values.searchValue);
      const latlng = await getLatLng(geocodes[0]);
      values.searchValue = `${latlng.lat},${latlng.lng}`;
    }

    try {
      actions.props.setQuery(values.searchMode, values.searchValue);
    } catch (e) {
      actions.setSubmitting(false);
    }
  },
})(form);
