import { gql } from 'apollo-boost';
import { FormikProps, withFormik } from 'formik';
import { QuestionMarkCircleIcon } from 'mdi-react';
import * as React from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { adminTokenStorage } from 'src/lib/storage';
import * as Yup from 'yup';
import PhoneNumberInput from '../../components/form/PhoneNumberInput';
import HelperTooltip from '../../components/generic/HelperTooltip';
import { client } from '../../lib/apollo';

const form = (props: FormikProps<IFormValues>) => (
  <Form>
    <FormGroup className="mb-4">
      <Label>Email Address</Label>
      <Input
        type="email"
        name="email"
        onChange={props.handleChange}
        value={props.values.email}
        placeholder="Email"
        invalid={props.errors.email !== undefined}
      />
      <FormFeedback>{props.errors.email}</FormFeedback>
    </FormGroup>
    <FormGroup className="mb-4">
      <Label>Password</Label>
      <Input
        name="password"
        onChange={props.handleChange}
        value={props.values.password}
        type="password"
        placeholder="Password"
        invalid={props.errors.password !== undefined}
      />
      <FormFeedback>{props.errors.password}</FormFeedback>
    </FormGroup>
    <h4>
      About You
      <HelperTooltip
        helpText={
          <>
            We collect some information about you to help us understand why you&apos;re signing up for CrowdCall.
            <br />
            <br />
            We promise we will never share any of this information with anyone.
          </>
        }
      >
        <QuestionMarkCircleIcon />
      </HelperTooltip>
    </h4>
    <FormGroup className="mb-4 col-6" style={{ float: 'left', padding: '0 1px' }}>
      <Label>First Name</Label>
      <Input
        name="first_name"
        onChange={props.handleChange}
        value={props.values.first_name}
        placeholder="First Name"
        invalid={props.errors.first_name !== undefined}
      />
      <FormFeedback>{props.errors.first_name}</FormFeedback>
    </FormGroup>
    <FormGroup className="mb-4 col-6" style={{ float: 'right', padding: '0 1px' }}>
      <Label>Last Name</Label>
      <Input
        name="last_name"
        onChange={props.handleChange}
        value={props.values.last_name}
        placeholder="Last Name"
        invalid={props.errors.last_name !== undefined}
      />
      <FormFeedback>{props.errors.last_name}</FormFeedback>
    </FormGroup>
    <FormGroup className="mb-4">
      <Label>Your Phone Number</Label>
      <PhoneNumberInput
        name="phone_number"
        error={props.errors.phone_number}
        onChange={props.handleChange}
        value={props.values.phone_number}
      />
      <FormFeedback>{props.errors.phone_number}</FormFeedback>
    </FormGroup>
    <FormGroup className="mb-4">
      <Label>Your Organization</Label>
      <Input
        name="organization"
        onChange={props.handleChange}
        value={props.values.organization}
        placeholder="Organization (i.e. Obama Campaign)"
        invalid={props.errors.organization !== undefined}
      />
      <FormFeedback>{props.errors.organization}</FormFeedback>
    </FormGroup>
    <FormGroup className="text-right">
      <Button onClick={props.submitForm} disabled={props.isSubmitting} type="submit" color="primary">
        Register
      </Button>
    </FormGroup>
  </Form>
);

interface IFormValues {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  organization: string;
}

export default withFormik<{}, IFormValues>({
  // Initial values empty
  mapPropsToValues: () => {
    return {
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      organization: '',
    };
  },

  validationSchema: Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required!'),
    password: Yup.string()
      .required('Password is required!')
      .test(
        'valid-password',
        'Your password must be at least 8 characters, contain an uppercase letter, contain a lowercase letter, and contain numbers.',
        (password: string) => {
          return /[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && password.length >= 8;
        },
      ),
    first_name: Yup.string().required('Name is required'),
    last_name: Yup.string().required('Name is required'),
    phone_number: Yup.string().required('Phone number is required'),
    organization: Yup.string().required('Organization is required'),
  }),

  handleSubmit: async (values, actions) => {
    try {
      const response: any = await client.mutate({
        variables: {
          email: values.email,
          password: values.password,
          firstName: values.first_name,
          lastName: values.last_name,
          phoneNumber: `1${values.phone_number.replace(/\D/g, '')}`,
          organization: values.organization,
        },
        mutation: gql`
          mutation(
            $email: String!
            $password: String!
            $firstName: String!
            $lastName: String!
            $phoneNumber: String!
            $organization: String!
          ) {
            token: signupAdmin(
              email: $email
              password: $password
              firstName: $firstName
              lastName: $lastName
              phoneNumber: $phoneNumber
              organization: $organization
            )
          }
        `,
      });

      const { token } = response.data;
      adminTokenStorage.set(token);
      // eslint-disable-next-line no-restricted-globals
      location.href = '/'; // Don't redirect w react router since its a new routing mode.*/
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      actions.setSubmitting(false);
    }
  },
})(form);
