import * as React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import AddNewCardForm from './AddNewCardForm';

interface IProps {
  close: () => void;
}

export default (props: IProps) => (
  <Modal isOpen toggle={props.close}>
    <ModalHeader>Add New Credit Card</ModalHeader>
    <ModalBody>
      <AddNewCardForm close={props.close} />
    </ModalBody>
  </Modal>
);
