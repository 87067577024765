import { client } from '@crowdcall/dashboard/src/lib/apollo';
import { adminTokenStorage } from '@crowdcall/dashboard/src/lib/storage';
import { HorizontalWhiteLogo } from '@crowdcall/shared/src/components/branding/Logo';
import LoginForm from '@crowdcall/shared/src/components/login/LoginForm';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import { toast } from 'react-toastify';
import CenteredContainer from '../../components/layout/container/CenteredContainer';

export default () => (
  <CenteredContainer>
    <div className="mb-3">
      <div style={{ width: '300px', margin: '0 auto', marginBottom: '8px' }}>
        <HorizontalWhiteLogo />
      </div>
      <Card className="form-card">
        <CardBody>
          <header>
            <h3>Sign In</h3>
          </header>

          <LoginForm
            apolloClient={client}
            onTokenReceived={token => {
              adminTokenStorage.set(token);
              // eslint-disable-next-line no-restricted-globals
              location.href = '/'; // Don't redirect w react router since its a new routing
            }}
            forgotPasswordLinkExternal={false}
          />
        </CardBody>
      </Card>
      <span style={{ float: 'right' }}>
        Don&apos;t have an account? <Link to="/signup">Register Now</Link>
      </span>
    </div>
  </CenteredContainer>
);
