/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  ILocalScriptCell,
  ILocalScriptCellData,
} from '@crowdcall/shared/src/interfaces/phoneBank/script/ILocalScriptCell';
import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { CrowdCallTheme } from '@crowdcall/shared/src/components/style/CrowdCallTheme';
import { Icon, Button, Popover, Tooltip } from 'antd';
import { Spacing } from '@crowdcall/shared/src/components/container/Spacing';
import { cellComponents } from '@crowdcall/shared/src/components/script/cells/CellComponents';
import { DragIcon } from 'mdi-react';

export type ScriptCellProps = {
  cellIndex: number;
  cell: ILocalScriptCell;
  setData: (cellData: ILocalScriptCellData) => void;
  duplicate: () => void;
  remove: () => void;
};

const DragHandle = SortableHandle(() => <DragIcon css={{ cursor: 'grab' }} />);

export const ScriptCellEditor = React.memo((props: ScriptCellProps) => {
  const { cell, setData, duplicate, remove } = props;
  const componentData = cellComponents.find(component => component.id === cell.type);
  const Editor = componentData!.editor;

  return (
    <div css={{ zIndex: 9999, width: 550, margin: '16px 0' }}>
      <div
        css={{
          width: '100%',
          color: 'white',
          alignItems: 'center',
          fontSize: 20,
          padding: '8px 16px',
          paddingLeft: 0,
          backgroundColor: CrowdCallTheme.colors.blue.accent,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <DragHandle />
          {componentData!.name}
        </div>
        <div>
          <Tooltip title="Duplicate this cell">
            <Icon type="copy" onClick={duplicate} css={{ marginRight: 8 }} />
          </Tooltip>
          <Popover
            content={
              <div css={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                Are you sure you want to delete this cell?
                <br />
                This action is irreversible.
                <Spacing top={2}>
                  <Button type="danger" css={{ margin: '0 auto' }} block onClick={remove}>
                    Confirm Deletion
                  </Button>
                </Spacing>
              </div>
            }
            trigger="click"
            title="Are you sure?"
          >
            <Tooltip title="Delete this cell">
              <Icon type="delete" />
            </Tooltip>
          </Popover>
        </div>
      </div>
      <div css={{ backgroundColor: 'white' }}>
        <Editor data={cell.data} setData={setData} />
      </div>
    </div>
  );
});
