import { Query, useApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { Result, Button } from 'antd';
import React, { useContext } from 'react';
import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import { getCleanErrorMessage } from '@crowdcall/shared/src/util/errorUtil';
import { HorizontalCenterDiv } from '@crowdcall/shared/src/components/container/HorizontalCenterDiv';
import { WizardDescription, WizardQuestion } from '@crowdcall/shared/src/components/container/wizard/WizardContainer';
import { Spacing } from '@crowdcall/shared/src/components/container/Spacing';
import { TitledLargeActionButton } from '@crowdcall/shared/src/components/button/LargeActionButton';
import { formatRelativeDate } from 'src/components/generic/DateHelpers';
import { PhoneBankWizardContext } from '../../PhoneBankEditWizard';

export const DuplicateScriptCreation = (props: { close: () => void }) => {
  const client = useApolloClient();
  const context = useContext(PhoneBankWizardContext);
  return (
    <Query
      query={gql`
        {
          getCurrentUser {
            id
            phoneBanks {
              id
              name
              script {
                id
                createDate
                updateDate
              }
            }
          }
        }
      `}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <ThreeDotsLoader />;
        }
        if (error) {
          return <Result status="error" title="Could not fetch other script" subTitle={getCleanErrorMessage(error)} />;
        }
        const phoneBanks = data.getCurrentUser.phoneBanks.filter(phoneBank => phoneBank.script);
        return (
          <HorizontalCenterDiv>
            <WizardQuestion>What script do you want to duplicate?</WizardQuestion>
            <WizardDescription>
              <>
                Note that this will create a <b>copy</b> of the script.
                <br />
                Edits you make will not be shared between copies.
                <Spacing top={1}>
                  <Button type="dashed" onClick={() => props.close()}>
                    Go Back
                  </Button>
                </Spacing>
              </>
            </WizardDescription>
            {phoneBanks.map(phoneBank => {
              const createDate = new Date(phoneBank.script.createDate);
              const updateDate = new Date(phoneBank.script.updateDate);
              return (
                <Spacing top={2} key={phoneBank.id}>
                  <TitledLargeActionButton
                    title={phoneBank.name}
                    description={
                      <span>
                        Created {formatRelativeDate(createDate)}
                        {createDate.getTime() !== updateDate.getTime() && (
                          <span> (Updated {formatRelativeDate(updateDate)})</span>
                        )}
                      </span>
                    }
                    onSelect={async () => {
                      await client.mutate({
                        mutation: gql`
                          mutation($oldPhoneBankId: String!, $newPhoneBankId: String!) {
                            duplicateLocalScript(oldPhoneBankId: $oldPhoneBankId, newPhoneBankId: $newPhoneBankId) {
                              id
                              script {
                                id
                              }
                            }
                          }
                        `,
                        variables: { oldPhoneBankId: phoneBank.id, newPhoneBankId: context.phoneBankId },
                      });
                      props.close();
                    }}
                  />
                </Spacing>
              );
            })}
          </HorizontalCenterDiv>
        );
      }}
    </Query>
  );
};
