export const getPhoneNumberFromDirtyString = (dirty: string) => {
  let clean = dirty.replace(/\D/g, '');
  if (clean.length < 10) {
    throw new Error('Invalid phone number...');
  } else if (clean.length === 10) {
    clean = `1${clean}`;
  }
  return clean;
};

export const getE164FromDirtyString = (dirty: string) => `+${getPhoneNumberFromDirtyString(dirty)}`;

export const isValidPhoneNumber = (dirty: string) => {
  try {
    getPhoneNumberFromDirtyString(dirty);
    return true;
  } catch {
    return false;
  }
};

export const getBandwidthPhoneNumber = (clean: string) => `+${clean}`;

export const formatPhoneNumber = (clean: string) =>
  `(${clean.substr(1, 3)}) ${clean.substr(4, 3)}-${clean.substr(7, 4)}`;

export const prepareCleanNumberForInput = (clean: string) => {
  if (clean && clean.length === 11) {
    return clean.substring(1);
  }
  return clean;
};

export const getAreaCode = (clean: string) => clean.substr(1, 3);
