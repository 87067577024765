import * as React from 'react';

export default (props: { title: string; description?: string | React.ReactNode; largeScreenText?: string }) => (
  <div className="view-header d-flex align-items-center">
    <header className="text-white">
      <h1 className="h5 title text-uppercase">{props.title}</h1>
      <p className="mb-0 subtitle text-nowrap">
        {props.description}
        <span className="hidden-sm-down">{props.largeScreenText}</span>
      </p>
    </header>
  </div>
);
