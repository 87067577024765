import { WizardContainer } from '@crowdcall/shared/src/components/container/wizard/WizardContainer';
import React, { useState } from 'react';
import { Portal } from 'react-portal';
import { ForceOverlayDiv } from 'src/components/layout/container/ForceOverlayDiv';
import { Button } from 'antd';
import { NewContactGroupForm } from './NewContactGroupForm';
import { ContactCSVImport } from './ContactCSVImport';

enum WizardStage {
  CREATE_CONTACT_GROUP,
  UPLOAD_FILE,
}

export const ContactUploadWizard = ({
  close,
  onSuccess,
}: {
  close: () => void;
  onSuccess?: (groupId: string) => void;
}) => {
  const [stage, setStage] = useState(WizardStage.CREATE_CONTACT_GROUP);
  const [groupId, setGroupId] = useState('');
  return (
    <Portal>
      <ForceOverlayDiv>
        <WizardContainer
          headerAction={
            <Button onClick={() => close()} ghost type="dashed">
              Exit
            </Button>
          }
        >
          <>
            {stage === WizardStage.CREATE_CONTACT_GROUP && (
              <NewContactGroupForm
                cancel={close}
                setContactGroupId={id => {
                  setGroupId(id);
                  setStage(WizardStage.UPLOAD_FILE);
                }}
              />
            )}
            {stage === WizardStage.UPLOAD_FILE && (
              <ContactCSVImport
                done={() => {
                  if (onSuccess) {
                    onSuccess(groupId);
                  }
                  close();
                }}
                contactGroupId={groupId}
              />
            )}
          </>
        </WizardContainer>
      </ForceOverlayDiv>
    </Portal>
  );
};
