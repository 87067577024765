import * as React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { formatPhoneNumber } from '../../../lib/phoneNumber';
import NumberConfiguration from '../edit/NumberConfiguration';
import NumberPurchaseConfirmDisplay from './NumberPurchaseConfirmDisplay';
import NumberSearchOptionsForm from './NumberSearchOptionsForm';
import NumberSearchResultsDisplay from './NumberSearchResultsDisplay';

interface IProps {
  close: () => void;
}

export enum Step {
  SEARCH,
  RESULTS,
  CONFIRM,
  CONFIGURE,
}

interface IState {
  step: Step;
  searchType: string;
  searchValue: string;
  selectedNumber: string;
}
export default class extends React.Component<IProps, IState> {
  public state = {
    step: Step.SEARCH,
    searchType: '',
    searchValue: '',
    selectedNumber: '',
  };

  private getTitle = () => {
    switch (this.state.step) {
      case Step.SEARCH:
        return 'Search for a new phone number';
      case Step.RESULTS:
        return 'Select a phone number to purchase';
      case Step.CONFIRM:
        return `Are you sure you want to buy ${formatPhoneNumber(this.state.selectedNumber)}?`;
      case Step.CONFIGURE:
        return `Configure ${formatPhoneNumber(this.state.selectedNumber)}`;
      default:
        return 'Error code 194';
    }
  };

  private getForm = () => {
    switch (this.state.step) {
      case Step.SEARCH:
        return (
          <NumberSearchOptionsForm
            setQuery={(type, query) => {
              this.setState({ searchType: type, searchValue: query, step: Step.RESULTS });
            }}
            close={this.props.close}
          />
        );
      case Step.RESULTS:
        return (
          <NumberSearchResultsDisplay
            onSelect={selectedNumber => {
              this.setState({ selectedNumber, step: Step.CONFIRM });
            }}
            type={this.state.searchType}
            query={this.state.searchValue}
            back={() => {
              this.setState({ step: Step.SEARCH });
            }}
          />
        );
      case Step.CONFIRM:
        return (
          <NumberPurchaseConfirmDisplay
            back={() => {
              this.setState({ step: Step.RESULTS });
            }}
            numberToBuy={this.state.selectedNumber}
            onComplete={() => {
              this.setState({ step: Step.CONFIGURE });
            }}
          />
        );
      case Step.CONFIGURE:
        return (
          <NumberConfiguration
            close={() => {
              this.props.close();
            }}
            number={this.state.selectedNumber}
          />
        );
      default:
        return <h1>Error</h1>;
    }
  };

  public render() {
    return (
      <Modal isOpen toggle={this.props.close}>
        <ModalHeader>{this.getTitle()}</ModalHeader>
        {this.getForm()}
      </Modal>
    );
  }
}
