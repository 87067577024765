import * as React from 'react';
import StatusIndicator from 'src/components/generic/StatusIndicator';

export default (props: { status: string }) => (
  <>
    <StatusIndicator
      active={props.status === 'active'}
      message={props.status === 'active' ? 'success' : props.status}
    />
  </>
);
