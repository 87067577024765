import FlatfileImporter from 'flatfile-csv-importer';
import React, { useEffect, useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { ApolloClient, gql } from 'apollo-boost';
import { getCleanErrorMessage } from '@crowdcall/shared/src/util/errorUtil';
import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';

const getDataFromUser = async (contactGroupId: string, done: () => void, client: ApolloClient<any>) => {
  const importer = new FlatfileImporter(process.env.FLATFILE_LICENSE_KEY!, {
    fields: [
      {
        label: 'Unique Identifier',
        key: 'personId',
        isRequired: true,
      },
      {
        label: 'First Name',
        key: 'firstName',
        isRequired: true,
      },
      {
        label: 'Last Name',
        key: 'lastName',
        isRequired: true,
      },
      {
        label: 'Phone Number',
        key: 'phoneNumber',
        isRequired: true,
        validators: [
          {
            validate: 'regex_matches',
            regex: /^(\+?)(1?)[\s.-]?(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            error: 'Must be a valid phone number (including area code)',
          },
        ],
      },
    ],
    type: 'contact',
    allowInvalidSubmit: false,
    managed: false,
    allowCustom: true,
    disableManualInput: false,
  });

  const waitForData = async () => {
    const results = await importer.requestDataFromUser();
    const contacts = results.validData;
    importer.displayLoader();
    try {
      await client.mutate({
        mutation: gql`
          mutation($listId: String!, $contacts: [JSONObject!]!) {
            uploadContactsToDatabaseAndAddToList(listId: $listId, contacts: $contacts)
          }
        `,
        variables: { listId: contactGroupId, contacts },
      });
    } catch (e) {
      importer.displayError(`There was an error with your data: ${getCleanErrorMessage(e)}`);
      await waitForData();
    }
    await client.resetStore();
    importer.displaySuccess('Import Successful!');
    importer.close();
    done();
  };

  await waitForData();
};

export const ContactCSVImport = (props: { contactGroupId: string; done: () => void }) => {
  const client = useApolloClient();
  const [opened, setOpened] = useState(false);
  const { contactGroupId, done } = props;
  useEffect(() => {
    if (opened) {
      return; // Don't open multiple timess
    }
    getDataFromUser(contactGroupId, done, client);
    setOpened(true);
  }, [client, contactGroupId, done, opened]);
  return <ThreeDotsLoader />;
};
