import * as Cookies from 'js-cookie';

export default class CookieStorage {
  private cookieName: string;

  constructor(cookieName: string) {
    this.cookieName = cookieName;
  }

  public set(body: any) {
    const cookieSettings: Cookies.CookieAttributes = { expires: 999 };
    if (process.env.COOKIE_DOMAIN) {
      cookieSettings.domain = process.env.COOKIE_DOMAIN;
    }
    Cookies.set(this.cookieName, body, cookieSettings);
  }

  public get() {
    return Cookies.get(this.cookieName);
  }

  public remove() {
    return Cookies.remove(this.cookieName);
  }

  public isSet() {
    return this.get() !== undefined;
  }
}

export const adminTokenStorage = new CookieStorage('admin_token');
export const callerTokenStorage = new CookieStorage('calling_token');
