import { HorizontalCenterDiv } from '@crowdcall/shared/src/components/container/HorizontalCenterDiv';
import { Spacing } from '@crowdcall/shared/src/components/container/Spacing';
import { WizardActions } from '@crowdcall/shared/src/components/container/wizard/WizardActions';
import { WizardDescription, WizardQuestion } from '@crowdcall/shared/src/components/container/wizard/WizardContainer';
import { Input } from 'antd';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';

export type NewContactGroupFormProps = {
  cancel: () => void;
  setContactGroupId: (id: string) => void;
};

export const NewContactGroupForm = ({ cancel, setContactGroupId }: NewContactGroupFormProps) => {
  const [contactListName, setContactListName] = useState('');

  return (
    <HorizontalCenterDiv>
      <>
        <WizardQuestion>What do you want to name this new contact group?</WizardQuestion>
        <WizardDescription>
          No volunteers will see this name. It is only used for your own internal reference.
        </WizardDescription>
      </>
      <Spacing top={2}>
        <Input
          css={{ width: 400 }}
          placeholder="Contact List Name"
          size="large"
          value={contactListName}
          onChange={e => setContactListName(e.target.value)}
        />
      </Spacing>

      <ApolloConsumer>
        {client => (
          <WizardActions
            next={async () => {
              const { id } = (await client.mutate({
                mutation: gql`
                  mutation($name: String!) {
                    createContactList(name: $name) {
                      id
                      name
                      createDate
                    }
                  }
                `,
                variables: { name: contactListName },
              })).data.createContactList;
              setContactGroupId(id);
            }}
            nextDisabled={contactListName.length < 4}
            back={cancel}
          />
        )}
      </ApolloConsumer>
    </HorizontalCenterDiv>
  );
};
