import { HorizontalLogo } from '@crowdcall/shared/src/components/branding/Logo';
import {
  BullhornIcon,
  ClipboardAccountIcon,
  CurrencyUsdIcon,
  MdiReactIconComponentType,
  PoundIcon,
  SecurityIcon,
  VoicemailIcon,
} from 'mdi-react';
import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { SidebarSection } from './SidebarSection';
import './style.scss';

class Sidebar extends React.Component<{ mini?: boolean; match: any; toggleSidebar: (e: any) => void }> {
  private getSidebarItem(
    color: string,
    name: string,
    link: string,
    activePath: string,
    Icon: MdiReactIconComponentType,
  ) {
    const active = activePath === link;
    return (
      <li>
        <Link to={`/${link}`} className={`${active && 'active'}`}>
          <Icon size="18" color={color} />
          <span className="name">{name}</span>
        </Link>
      </li>
    );
  }

  public render() {
    let currentPath = (this.props.match.path as string).substr(1);
    if (!this.props.match.isExact) {
      currentPath = 'dashboard';
    }

    return (
      <nav className={`site-nav ${this.props.mini ? 'mini' : ''}`}>
        <header className="nav-head" style={{ margin: '0 auto' }}>
          <Link to="/">
            <div style={{ margin: '5px' }}>
              <HorizontalLogo />
            </div>
          </Link>
        </header>
        <div className="nav-list-container">
          <ul className="list-unstyled nav-list clearfix">
            <SidebarSection
              name="Configuration"
              color="#66bb6a"
              currentPath={currentPath}
              links={[
                { name: 'Numbers', path: 'numbers', icon: PoundIcon },
                { name: 'Permits', path: 'permits', icon: SecurityIcon },
                { name: 'Voicemail', path: 'voicemail', icon: VoicemailIcon },
              ]}
            />
            <SidebarSection
              name="Phone Banking"
              color="#7c4dff"
              currentPath={currentPath}
              links={[
                { name: 'Phone Banks', path: 'phonebanks', icon: BullhornIcon },
                { name: 'Contacts', path: 'contacts', icon: ClipboardAccountIcon },
                // { name: 'Callers', path: 'callers', icon: AccountGroupIcon },
              ]}
            />
            <SidebarSection
              name="Administration"
              color="#f44242"
              currentPath={currentPath}
              links={[{ name: 'Billing', path: 'billing', icon: CurrencyUsdIcon }]}
            />
          </ul>
        </div>
      </nav>
    );
  }
}

export default withRouter(Sidebar as any) as any;
