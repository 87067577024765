import { CheckboxBlankCircleIcon } from 'mdi-react';
import * as React from 'react';
import { Button, Card, CardBody, CardTitle } from 'reactstrap';

export default () => (
  <Card>
    <CardBody className="table-responsive">
      <CardTitle className="text-uppercase h6">Latest Calls</CardTitle>
      <div className="small mb-4 card-subtitle">
        <Button color="primary" size="sm">
          View all
        </Button>
      </div>
      <TransactionTable />
    </CardBody>
  </Card>
);

const TransactionTable = () => {
  const TransactionTableData = [
    { date: '22 Mar', name: 'John Doe', earnings: '$304', status: 'pending' },
    { date: '12 Feb', name: 'Maria Smith', earnings: '$834', status: 'done' },
    { date: '28 Jan', name: 'Sofia Andre', earnings: '$943', status: 'done' },
    {
      date: '03 May',
      name: 'Jean Wilkinson',
      earnings: '$1234',
      status: 'pending',
    },
    { date: '10 Mar', name: 'Alisha Seth', earnings: '$534', status: 'done' },
  ];

  return (
    <table className="table">
      <tbody>
        {TransactionTableData.map(item => (
          <tr key={item.earnings}>
            <td className="d-flex flex-column">
              <strong>{item.name}</strong>
              <small>{item.date}</small>
            </td>
            <td className="align-middle">{item.earnings}</td>
            <td className="align-middle text-nowrap">
              <CheckboxBlankCircleIcon size="12" color={item.status === 'done' ? '#4CAF50' : '#F44336'} />
              &nbsp;
              {item.status}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
