import * as React from 'react';
import { CrowdCallTheme } from './style/CrowdCallTheme';

// tslint:disable-next-line:no-var-requires
const Loader = require('react-loader-spinner').default; // TODO no definitions

export const ThreeDotsLoader = (props: { width?: number; height?: number }) => (
  <div>
    <Loader
      color={CrowdCallTheme.colors.blue.primary}
      type="ThreeDots"
      height={props.height || 100}
      width={props.height || 100}
    />
  </div>
);
