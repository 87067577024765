import gql from 'graphql-tag';
import * as React from 'react';
import { Query } from 'react-apollo';

export default () => (
  <Query
    query={gql`
      {
        getBalance
      }
    `}
  >
    {({ loading, error, data }) => {
      if (loading || error) {
        return <></>;
      }
      return <>${data.getBalance.toFixed(2)}</>;
    }}
  </Query>
);
