import { ILocalScriptCellData } from '@crowdcall/shared/src/interfaces/phoneBank/script/ILocalScriptCell';
import { TextCellEditor } from './text/TextCellEditor';
import { TextCellRender } from './text/TextCellRender';
import { DividerCellRender } from './divider/DividerCellRender';
import { DividerCellEditor } from './divider/DividerCellEditor';
import { SelectionEditor } from './selection/SelectionEditor';
import { SelectionRender } from './selection/SelectionRender';
import { TextInputEditor } from './text-input/TextInputEditor';
import { TextInputRender } from './text-input/TextInputRender';

export type CellEditorProps = {
  data: ILocalScriptCellData;
  setData: (newData: ILocalScriptCellData) => void;
};
export type CellRenderProps = Omit<CellEditorProps, 'setData'>;

export const cellComponents: {
  id: string;
  name: string;
  editor: (props: CellEditorProps) => JSX.Element;
  render: (props: CellRenderProps) => JSX.Element;
}[] = [
  {
    id: 'text',
    name: 'Static Text',
    editor: TextCellEditor,
    render: TextCellRender,
  },
  {
    id: 'divider',
    name: 'Divider',
    editor: DividerCellEditor,
    render: DividerCellRender,
  },
  {
    id: 'selection',
    name: 'Multiple Choice',
    editor: SelectionEditor,
    render: SelectionRender,
  },
  {
    id: 'text-input',
    name: 'Text Input',
    editor: TextInputEditor,
    render: TextInputRender,
  }
];
