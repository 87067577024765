import * as React from 'react';
import { CheckboxBlankCircleIcon } from 'mdi-react';

export default (props: { active: boolean; message?: string }) => (
  <>
    <CheckboxBlankCircleIcon size="12" color={props.active ? '#4CAF50' : '#F44336'} />
    &nbsp;
    {props.message || (props.active ? 'Active' : 'Inactive')}
  </>
);
