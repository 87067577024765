import { TitledLargeActionButton } from '@crowdcall/shared/src/components/button/LargeActionButton';
import { HorizontalCenterDiv } from '@crowdcall/shared/src/components/container/HorizontalCenterDiv';
import { Spacing } from '@crowdcall/shared/src/components/container/Spacing';
import { WizardQuestion } from '@crowdcall/shared/src/components/container/wizard/WizardContainer';
import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import { getCleanErrorMessage } from '@crowdcall/shared/src/util/errorUtil';
import { Result } from 'antd';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';
import { Query, useApolloClient } from 'react-apollo';
import { InteractionDataProvider } from '@crowdcall/shared/src/components/script/InteractionDataReducer';
import { PhoneBankWizardContext } from '../../PhoneBankEditWizard';
import { DuplicateScriptCreation } from './DuplicateScriptCreation';
import { ScriptEditor } from './editor/ScriptEditor';

export const PhoneBankScriptSelection = () => {
  const [showDuplicateScreen, setShowDuplicateScreen] = useState(false);
  const client = useApolloClient();
  const context = useContext(PhoneBankWizardContext);
  if (showDuplicateScreen) {
    return <DuplicateScriptCreation close={() => setShowDuplicateScreen(false)} />;
  }
  return (
    <Query
      query={gql`
        query($phoneBankId: String!) {
          getPhoneBank(id: $phoneBankId) {
            id
            script {
              id
            }
          }
        }
      `}
      variables={{ phoneBankId: context.phoneBankId }}
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <ThreeDotsLoader />;
        }
        if (error) {
          return <Result status="error" title="Could not fetch script" subTitle={getCleanErrorMessage(error)} />;
        }
        const phoneBank = data.getPhoneBank;
        if (!phoneBank.script) {
          // Create new script
          return (
            <HorizontalCenterDiv>
              <WizardQuestion>What script do you want to use?</WizardQuestion>
              <Spacing top={2}>
                <TitledLargeActionButton
                  highlighted
                  onSelect={async () => {
                    await client.mutate({
                      mutation: gql`
                        mutation($phoneBankId: String!) {
                          createNewLocalScript(phoneBankId: $phoneBankId) {
                            id
                            script {
                              id
                            }
                          }
                        }
                      `,
                      variables: { phoneBankId: context.phoneBankId },
                    });
                  }}
                  title="A brand new script"
                  description="Design a new script from scratch. You will be directed to the script editor."
                />
                <Spacing top={2}>
                  <TitledLargeActionButton
                    onSelect={() => setShowDuplicateScreen(true)}
                    title="An existing script"
                    description="Duplicate a script from an existing phone bank. Changes between the scripts will not be shared."
                  />
                </Spacing>
              </Spacing>
            </HorizontalCenterDiv>
          );
        }
        // Script edit screen
        return (
          <InteractionDataProvider>
            <ScriptEditor />
          </InteractionDataProvider>
        );
      }}
    </Query>
  );
};
