import { HorizontalCenterDiv } from '@crowdcall/shared/src/components/container/HorizontalCenterDiv';
import { Spacing } from '@crowdcall/shared/src/components/container/Spacing';
import { WizardDescription, WizardQuestion } from '@crowdcall/shared/src/components/container/wizard/WizardContainer';
import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import { getCleanErrorMessage } from '@crowdcall/shared/src/util/errorUtil';
import { Button, Result, Select } from 'antd';
import gql from 'graphql-tag';
import React, { useContext, useState, useEffect } from 'react';
import { Query, useApolloClient } from 'react-apollo';
import { ContactUploadWizard } from 'src/pages/contacts/upload/ContactUploadWizard';
import { CrowdCallTheme } from '@crowdcall/shared/src/components/style/CrowdCallTheme';
import { formatRelativeDate } from 'src/components/generic/DateHelpers';
import { WizardActions } from '@crowdcall/shared/src/components/container/wizard/WizardActions';
import { PhoneBankWizardContext } from '../PhoneBankEditWizard';
import { PhoneBankWizardPage } from '../PhoneBankWizardNavigation';

export const PhoneBankContactSelection = () => {
  const context = useContext(PhoneBankWizardContext);
  const [importWizardOpen, setImportWizardOpen] = useState(false);
  const [contactListIds, setContactListIds] = useState([] as String[]);
  const client = useApolloClient();
  useEffect(() => {
    client.mutate({
      mutation: gql`
        mutation($phoneBankId: String!, $contactListIds: [String!]) {
          updatePhoneBank(phoneBankId: $phoneBankId, contactListIds: $contactListIds) {
            id
            contactLists {
              id
            }
          }
        }
      `,
      variables: { phoneBankId: context.phoneBankId, contactListIds },
    });
  }, [client, contactListIds, context.phoneBankId]);
  return (
    <>
      <HorizontalCenterDiv>
        <WizardQuestion>Who should recieve calls from this phone bank?</WizardQuestion>
        <WizardDescription>
          Who do you want this phone bank to target? The contact groups you select will recieve calls from this phone
          bank.
        </WizardDescription>
      </HorizontalCenterDiv>
      <div css={{ width: 400, margin: '0 auto' }}>
        <Spacing top={2}>
          <Button type="dashed" size="large" icon="user" onClick={() => setImportWizardOpen(true)}>
            Import New Contacts
          </Button>
          {importWizardOpen && (
            <ContactUploadWizard
              close={() => setImportWizardOpen(false)}
              onSuccess={groupId => {
                setContactListIds([...contactListIds, groupId]);
                client.resetStore();
              }}
            />
          )}
        </Spacing>
        <Spacing top={2}>
          <Query
            query={gql`
              query($phoneBankId: String!) {
                getPhoneBank(id: $phoneBankId) {
                  id
                  contactLists {
                    id
                  }
                }
                getCurrentUser {
                  id
                  contactLists {
                    id
                    name
                    contactCount
                    createDate
                    archived
                  }
                }
              }
            `}
            variables={{ phoneBankId: context.phoneBankId }}
            onCompleted={data => {
              setContactListIds(data.getPhoneBank.contactLists.map(contactList => contactList.id));
              /* if (data.getCurrentUser.contactLists.length === 0) {
                setImportWizardOpen(true);
              } */
            }}
          >
            {({ loading, data, error }) => {
              if (loading) {
                return <ThreeDotsLoader />;
              }
              if (error) {
                return (
                  <Result
                    status="error"
                    title="Could not fetch contact groups"
                    subTitle={getCleanErrorMessage(error)}
                  />
                );
              }
              return (
                <Select
                  mode="multiple"
                  size="large"
                  css={{ width: 400 }}
                  placeholder="Please select a contact group"
                  onChange={(values: String[]) => setContactListIds(values)}
                  value={contactListIds}
                  optionLabelProp="title"
                >
                  {data.getCurrentUser.contactLists.map(contactList => (
                    <Select.Option key={contactList.id} title={contactList.name}>
                      {contactList.name}
                      <br />
                      <span css={{ color: CrowdCallTheme.colors.gray[7] }}>
                        {contactList.contactCount} contacts – Created{' '}
                        {formatRelativeDate(new Date(contactList.createDate))}
                      </span>
                    </Select.Option>
                  ))}
                </Select>
              );
            }}
          </Query>
          <WizardActions
            back={() => context.setPage(PhoneBankWizardPage.PERMIT)}
            next={() => context.setPage(PhoneBankWizardPage.WELCOME)}
            nextDisabled={contactListIds.length === 0}
          />
        </Spacing>
      </div>
    </>
  );
};
