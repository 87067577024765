import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { client } from './lib/apollo';
import { adminTokenStorage, callerTokenStorage } from './lib/storage';
import BillingPage from './pages/billing/BillingPage';
import { ContactsPage } from './pages/contacts/ContactsPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import ForgotPasswordPage from './pages/forgot-password/ForgotPasswordPage';
import LoginPage from './pages/login/LoginPage';
import LogsPage from './pages/logs/LogsPage';
import NumbersPage from './pages/numbers/NumbersPage';
import PermitPage from './pages/permits/PermitPage';
import SignupPage from './pages/signup/SignupPage';
import { PhonebanksPage } from './pages/phonebanks/PhonebanksPage';
import VoicemailPage from './pages/voicemail/VoicemailPage';
import './style/app.scss';
import { PhoneBankEditWizard } from './pages/phonebanks/wizard/PhoneBankEditWizard';

export default class Router extends React.Component {
  public publicRoutes = [
    <Route key="signup" exact path="/signup" component={SignupPage} />,
    <Route key="forgot-password" exact path="/forgot-password" component={ForgotPasswordPage} />,
    <Route key="login" exact path="/login" component={LoginPage} />,
  ];

  public getRoutes = (): JSX.Element[] => {
    // If not authenticated, bring to login page no matter what
    if (!adminTokenStorage.isSet() && !callerTokenStorage.isSet()) {
      return [...this.publicRoutes, <Route key="redirect" path="*" render={() => <Redirect to="/login" />} />];
    }
    return [
      ...this.publicRoutes,
      <Route key="billing" exact path="/billing" component={BillingPage} />,
      <Route key="numbers" exact path="/numbers" component={NumbersPage} />,
      <Route key="logs" exact path="/logs" component={LogsPage} />,
      <Route key="voicemail" exact path="/voicemail" component={VoicemailPage} />,
      <Route key="permits" exact path="/permits" component={PermitPage} />,
      <Route key="contacts" exact path="/contacts" component={ContactsPage} />,
      <Route key="phonebanks" exact path="/phonebanks" component={PhonebanksPage} />,
      <Route key="phonebanks-edit" path="/phonebanks/edit/:phoneBankId/:pageId?" component={PhoneBankEditWizard} />,
      <Route key="dashboard" exact path="/dashboard" component={DashboardPage} />,
      <Route key="redirect" path="/" render={() => <Redirect to="/numbers" />} />,
    ];
  };

  public render() {
    return (
      <BrowserRouter>
        <ApolloProvider client={client}>
          <Switch>{this.getRoutes()}</Switch>
        </ApolloProvider>
      </BrowserRouter>
    );
  }
}
