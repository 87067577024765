import * as React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { formatPhoneNumber } from '../../../lib/phoneNumber';
import NumberConfiguration from './NumberConfiguration';

interface IProps {
  number: string;
  close: () => void;
}

export default (props: IProps) => (
  <Modal isOpen toggle={props.close}>
    <ModalHeader>Configure {formatPhoneNumber(props.number)}</ModalHeader>
    <NumberConfiguration number={props.number} close={props.close} />
  </Modal>
);
