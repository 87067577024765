import React, { useContext, useCallback, ChangeEvent } from 'react';
import { Input, Skeleton } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
  InteractionDataContext,
  InteractionDataActionTypes,
  InteractionStatus,
} from '@crowdcall/shared/src/components/script/InteractionDataReducer';
import { CellRenderProps } from '../CellComponents';
import { SlateEditor } from '../../../editor/SlateEditor';
import { TextInputCell } from '../../../../interfaces/phoneBank/script/cell-types/TextInputCell';
import { ThreeDotsLoader } from '../../../Loader';

export const TextInputRender = (props: CellRenderProps) => {
  const data = props.data as TextInputCell;
  const [interactionData, dispatch] = useContext(InteractionDataContext);
  const onChange = useCallback(
    (e: ChangeEvent<any>) => {
      dispatch({ type: InteractionDataActionTypes.SET_SCRIPT_DATA, key: data.fieldName, value: e.target.value });
    },
    [data.fieldName, dispatch],
  );
  const cellValue = interactionData.scriptData[data.fieldName] || '';

  if (interactionData.loading) {
    return <Skeleton title active />;
  }
  return (
    <div>
      <div css={{ fontWeight: 'bold', textDecoration: 'underline', marginTop: 16 }}>
        <SlateEditor
          readOnly
          defaultValue={data.questionText}
          placeholderValues={{
            CONTACT_FIRST_NAME: interactionData.contact.firstName,
            CONTACT_LAST_NAME: interactionData.contact.lastName,
            CALLER_FIRST_NAME: interactionData.caller.firstName,
            CALLER_LAST_NAME: interactionData.caller.lastName,
          }}
        />
      </div>
      {data.block ? (
        <TextArea
          disabled={!interactionData.called || interactionData.interactionStatus !== InteractionStatus.Contacted}
          placeholder={data.placeholder}
          defaultValue={cellValue}
          onChange={onChange}
        />
      ) : (
        <Input
          disabled={!interactionData.called || interactionData.interactionStatus !== InteractionStatus.Contacted}
          placeholder={data.placeholder}
          size="large"
          value={cellValue}
          onChange={onChange}
        />
      )}
    </div>
  );
};
