import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import gql from 'graphql-tag';
import * as React from 'react';
import { Query } from 'react-apollo';
import { Card, CardBody } from 'reactstrap';
import DashboardContainer from '../../components/layout/container/DashboardContainer';
import IVoicemail from './IVoicemail';
import VoicemailList from './VoicemailList';

export default () => (
  <div>
    <DashboardContainer title="Voicemail">
      <Card>
        <Query
          query={gql`
            query {
              listVoicemail {
                id
                from
                to {
                  nickname
                  number
                }
                startTime
                endTime
                lengthInMills
              }
            }
          `}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <CardBody>
                  <p className="text-uppercase h5">Loading voicemail...</p>
                  <div style={{ justifyContent: 'center', marginTop: '80px', display: 'flex' }}>
                    <ThreeDotsLoader />
                  </div>
                </CardBody>
              );
            }
            if (error) {
              return (
                <CardBody>
                  <p className="text-uppercase h5">An error occured...</p>
                  <span className="h7 mb-4">{error.message}</span>
                </CardBody>
              );
            }
            let voicemails: IVoicemail[] = data.listVoicemail.map(
              (incoming: any) =>
                ({
                  id: incoming.id,
                  from: incoming.from,
                  toName: incoming.to.nickname,
                  toNumber: incoming.to.number,
                  startDate: new Date(incoming.startTime),
                  endDate: new Date(incoming.endTime),
                } as IVoicemail),
            );

            voicemails = voicemails.sort((a, b) => {
              return b.endDate.getTime() - a.endDate.getTime();
            }); // Reverse chronological order

            return <VoicemailList voicemails={voicemails} />;
          }}
        </Query>
      </Card>
    </DashboardContainer>
  </div>
);
