import * as React from 'react';
import { TimePicker } from 'antd';
import moment from 'moment';

export type TimeRangeProps = {
  defaultValue?: Boolean[];
  onChange: (enabledHours: boolean[]) => void;
};

type TimeRangeState = {
  startTime?: moment.Moment;
  endTime?: moment.Moment;
};
export default class TimeRange extends React.Component<TimeRangeProps, TimeRangeState> {
  state = {
    startTime: (undefined as unknown) as moment.Moment, // inclusive
    endTime: (undefined as unknown) as moment.Moment, // exclusive
  };

  componentDidMount() {
    const { defaultValue } = this.props;
    if (defaultValue) {
      const startHour = defaultValue.indexOf(true);
      const endHour = defaultValue.lastIndexOf(true) + 1;
      this.setState({
        startTime: moment().hours(startHour),
        endTime: moment().hours(endHour),
      });
    }
  }

  triggerOnChange = (startTime, endTime) => {
    if (!startTime || !endTime) {
      return;
    }

    const arr = new Array<boolean>(24);
    for (let i = 0; i < 24; i++) {
      arr[i] = i >= startTime.hour() && i < endTime.hour();
    }
    this.props.onChange(arr);
  };

  render() {
    return (
      <span>
        from
        <TimePicker
          defaultOpenValue={moment('9 AM', 'h a')}
          value={this.state.startTime}
          use12Hours
          format="h a"
          onChange={startTime => {
            this.setState({ startTime });
            if (this.state.endTime) {
              if (this.state.endTime.isBefore(startTime)) {
                this.setState({ endTime: undefined });
              }
            }
            this.triggerOnChange(startTime, this.state.endTime);
          }}
        />{' '}
        to{' '}
        <TimePicker
          defaultOpenValue={moment('5 PM', 'h a')}
          value={this.state.endTime}
          use12Hours
          format="h a"
          onChange={endTime => {
            this.setState({ endTime });
            if (this.state.startTime) {
              if (endTime.isBefore(this.state.startTime)) {
                this.setState({ startTime: undefined });
              }
            }
            this.triggerOnChange(this.state.startTime, endTime);
          }}
        />
      </span>
    );
  }
}
