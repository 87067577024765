import { Card, CardTitle, CardBody, Button } from 'reactstrap';
import * as React from 'react';
import { LeadPencilIcon } from 'mdi-react';
import CardImg from 'reactstrap/lib/CardImg';
import { formatPhoneNumber } from '../../lib/phoneNumber';
import NumberEditModal from './edit/NumberEditModal';

interface IProps {
  nickname: string;
  location: string;
  number: string;
}
interface IState {
  modalOpen: boolean;
}

export default class PhoneNumberCard extends React.Component<IProps, IState> {
  public state = {
    modalOpen: false,
  };

  public render() {
    return (
      <>
        {this.state.modalOpen && (
          <NumberEditModal number={this.props.number} close={() => this.setState({ modalOpen: false })} />
        )}
        <Card
          style={{
            width: '350px',
            height: '350px',
            margin: '20px',
          }}
        >
          <CardImg
            top
            width="100%"
            src={`https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAc5Skn9jSndhUQ_6uhgjMK1nhjq52pPnY&center=${this.props.location}&zoom=13&scale=2&size=300x150&maptype=terrain&format=png&visual_refresh=true&style=feature:road|visibility:off&style=feature:poi|visibility:off&style=feature:administrative.neighborhood|visibility:off&style=feature:transit|visibility:off&style=feature:road|visibility:simplified`}
          />
          <CardBody>
            <CardTitle className="text-uppercase h5">{this.props.nickname}</CardTitle>
            <span className="h7 mb-4">{formatPhoneNumber(this.props.number)}</span>
            <div className="h7 mb-4">{this.props.location}</div>
            <Button
              color="warning"
              style={{ float: 'left' }}
              onClick={() => {
                this.setState({ modalOpen: true });
              }}
            >
              <LeadPencilIcon /> Configure
            </Button>
          </CardBody>
        </Card>
      </>
    );
  }
}
