import React, { useState } from 'react';
import { Tag, Icon, Button } from 'antd';
import { Editor } from 'slate';
import { CrowdCallTheme } from '../../../style/CrowdCallTheme';
import { ScriptPlaceholders } from './ScriptPlaceholders';
import { Spacing } from '../../../container/Spacing';

export const PlaceholderDrawer = ({ editor, compact }: { editor: Editor; compact: boolean }) => {
  const [visible, setVisible] = useState(!compact);

  if (!visible) {
    return (
      <div>
        <Button type="link" css={{ paddingLeft: 0 }} size="small" icon="plus" onClick={() => setVisible(true)}>
          Add Placeholder
        </Button>
      </div>
    );
  }

  return (
    <div
      css={{
        backgroundColor: 'white',
        borderTop: compact ? 'none' : `1px ${CrowdCallTheme.colors.gray[6]} solid`,
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {compact && (
        <Icon
          type="close-circle"
          css={{ color: CrowdCallTheme.colors[5], marginRight: 8 }}
          onClick={() => setVisible(false)}
        />
      )}
      {ScriptPlaceholders.map(placeholder => (
        <Tag
          key={placeholder.id}
          color="blue"
          onClick={() => {
            editor.insertInline({
              type: 'placeholder',
              data: {
                value: placeholder.id,
              },
            });
            editor.moveToStartOfNextText().focus();
          }}
        >
          {placeholder.name}
          <Spacing left={1} inline>
            <Icon type="plus" />
          </Spacing>
        </Tag>
      ))}
    </div>
  );
};
