/* eslint-disable jsx-a11y/media-has-caption */
import { formatDistance } from 'date-fns';
import * as React from 'react';
import { formatRelativeDate } from '../../components/generic/DateHelpers';
import HelperTooltip from '../../components/generic/HelperTooltip';
import { formatPhoneNumber } from '../../lib/phoneNumber';
import { Settings } from '../../lib/settings';
import IVoicemail from './IVoicemail';

interface IProps {
  voicemail: IVoicemail;
}

export default (props: IProps) => {
  const { voicemail } = props;
  return (
    <tr key={voicemail.id}>
      <td className="d-flex flex-column">
        <strong>From {formatPhoneNumber(voicemail.from)}</strong>
        <small>
          To{' '}
          <HelperTooltip helpText={formatPhoneNumber(voicemail.toNumber)}>
            <span style={{ fontStyle: 'italic' }}>{voicemail.toName}</span>
          </HelperTooltip>
        </small>
      </td>
      <td className="align-middle">
        Left {formatRelativeDate(voicemail.endDate)}
        <br />
        <small>{formatDistance(voicemail.startDate, voicemail.endDate)} long</small>
      </td>
      <td className="align-middle text-nowrap">
        <audio controls>
          <source src={`${Settings.api_server}/voicemail?id=${voicemail.id}`} type="audio/mp3" />
        </audio>
      </td>
    </tr>
  );
};
