import ApolloClient, { Operation } from 'apollo-boost';
import { ErrorResponse } from 'apollo-link-error';
import { GraphQLError } from 'graphql';

export default (
  apiServer: string,
  notifyError: (erorrMessage: string) => void,
  getHeaders: () => Promise<any>,
  onAccessDenied: () => void,
  additionalOptions: any = {},
) => {
  // Returns undefined  if error should be hidden
  function getErrorMessage(error: GraphQLError) {
    const errorMessages = {
      'Wrong email or password.': 'Wrong email or password.',
      'The user already exists.': 'There is already an account with that email address. Try logging in instead.',
    };

    if (error.message in errorMessages) {
      return errorMessages[error.message];
    }
    if ((error.message as any) instanceof String) {
      if (error.message.startsWith('Missing required parameter: ')) {
        let missingField = error.message.replace('Missing required parameter: ', '');
        missingField = missingField.charAt(0).toUpperCase() + missingField.substr(1);
        return `${missingField} field missing`;
      }
    }
    if ((error.message as any).error_description) {
      return (error.message as any).error_description;
    }
    return error.message;
  }

  async function onRequest(operation: Operation) {
    const existingHeaders = operation.getContext().headers || {};
    const headers = { ...(await getHeaders()), ...existingHeaders };
    operation.setContext({
      headers,
    });
  }

  function handleGraphQLError(errorResponse: ErrorResponse) {
    if (errorResponse.graphQLErrors) {
      for (const error of errorResponse.graphQLErrors) {
        const errorMessage = getErrorMessage(error);
        if (errorMessage.indexOf('Access denied!') !== -1) {
          onAccessDenied();
        }
        if (errorMessage !== undefined) {
          notifyError(errorMessage);
        } else {
          notifyError('An unknown error occured');
        }
      }
    }
    if (errorResponse.networkError) {
      notifyError('A network error occured. Are you connected to the internet?');
    }
  }

  return new ApolloClient({
    onError: handleGraphQLError,
    request: onRequest,
    uri: apiServer,
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    ...additionalOptions,
  });
};
