import { Spacing } from '@crowdcall/shared/src/components/container/Spacing';
import { Button, Icon } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useApolloClient } from 'react-apollo';
import Row from 'reactstrap/lib/Row';
import DashboardContainer from 'src/components/layout/container/DashboardContainer';
import { PhonebankCardGrid } from './PhonebankCardGrid';
import { PhoneBankEditWizard } from './wizard/PhoneBankEditWizard';
import { Redirect } from 'react-router-dom';

export const PhonebanksPage = () => {
  const [newPhoneBankId, setNewPhoneBankId] = useState('');
  const client = useApolloClient();

  return (
    <DashboardContainer
      title="Phone Banks"
      description={
        <div>
          <span>Build custom scripts and manage volunteer phone banks</span>
          <Spacing top={2}>
            <Button
              size="large"
              onClick={async () => {
                const resp = await client.mutate({
                  mutation: gql`
                    mutation {
                      createPhoneBank {
                        id
                      }
                    }
                  `,
                });
                client.resetStore();
                const phoneBankId = resp.data!.createPhoneBank.id;
                setNewPhoneBankId(phoneBankId);
              }}
            >
              Create New
              <Icon type="plus-circle" />
            </Button>
          </Spacing>
        </div>
      }
    >
      {newPhoneBankId && <Redirect to={`/phonebanks/edit/${newPhoneBankId}`} />}
      <Row css={{ display: 'flex', justifyContent: 'center' }}>
        <PhonebankCardGrid />
      </Row>
    </DashboardContainer>
  );
};
