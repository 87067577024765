import { ModalBody, FormGroup, Button, Table } from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import * as React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { formatPhoneNumber } from 'src/lib/phoneNumber';
import { ForwardIcon } from 'mdi-react';
import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import { CrowdCallTheme } from '@crowdcall/shared/src/components/style/CrowdCallTheme';

interface IProps {
  type: string;
  query: string;
  back: () => void;
  onSelect: (desiredNumber: string) => void;
}

export default (props: IProps) => (
  <>
    <ModalBody>
      <Query
        query={gql`
          query($method: NumberSearchMethod!, $query: String!) {
            searchAvailablePhoneNumbers(method: $method, query: $query) {
              number
              location
            }
          }
        `}
        variables={{ method: props.type, query: props.query.toString() }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <>
                <p className="text-uppercase h5">Loading results...</p>
                <div style={{ justifyContent: 'center', marginTop: '80px', display: 'flex' }}>
                  <ThreeDotsLoader />
                </div>
              </>
            );
          }
          if (error) {
            props.back();
            return <p>An error occured</p>;
          }

          return (
            <>
              <Table striped borderless>
                <tbody>
                  {data.searchAvailablePhoneNumbers.map((phoneNumber: any) => (
                    <tr key={phoneNumber.number}>
                      <td style={{ verticalAlign: 'middle' }}>
                        <div css={{ display: 'flex', flexDirection: 'column' }}>
                          <span>{formatPhoneNumber(phoneNumber.number)}</span>
                          <span css={{ color: CrowdCallTheme.colors.gray[6] }}>{phoneNumber.location}</span>
                        </div>
                      </td>
                      <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>
                        <Button
                          onClick={() => {
                            props.onSelect(phoneNumber.number);
                          }}
                          color="success"
                        >
                          Buy <ForwardIcon />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <p css={{ color: CrowdCallTheme.colors.gray[7] }}>
                This list includes every available phone number that match your criteria. If none of them fit your
                needs, please use a different search criteria, try again later, or reach out to support to discuss other
                options.
              </p>
            </>
          );
        }}
      </Query>
    </ModalBody>
    <ModalFooter>
      <FormGroup className="text-left">
        <Button
          onClick={() => {
            props.back();
          }}
          type="button"
          color="secondary"
        >
          Back
        </Button>
      </FormGroup>
    </ModalFooter>
  </>
);
