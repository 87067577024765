import React from 'react';
import { Input, Icon } from 'antd';
import { InputPrompt } from '../../../../interfaces/phoneBank/script/cell-types/InputPrompt';
import { InputHelper } from '../../../form/InputHelper';

export const FieldNameInput = ({ data, setData }: { data: InputPrompt; setData: (newData: InputPrompt) => void }) => {
  return (
    <div css={{ width: 250 }}>
      <InputHelper title="Field Name" helper="How do you want to refer to this data once it's saved in the database?">
        <Input
          prefix={<Icon type="save" />}
          value={data.fieldName}
          placeholder="Enter a field name"
          onChange={e => setData({ ...data, fieldName: e.target.value })}
        />
      </InputHelper>
    </div>
  );
};
