import { QuestionMarkCircleIcon } from 'mdi-react';
import * as React from 'react';
import { Tooltip } from 'reactstrap';

interface ITooltipProps {
  children?: any;
  helpText: string | JSX.Element;
}

interface IState {
  hashcode: string;
  tooltipOpen: boolean;
}

export default class HelperTooltip extends React.PureComponent<ITooltipProps, IState> {
  public state = {
    hashcode: `tooltip-${Math.floor(Math.random() * 100000)}`,
    tooltipOpen: false,
  };

  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    children: <QuestionMarkCircleIcon />,
  };

  public render() {
    const { hashcode, tooltipOpen } = this.state;
    const { helpText, children } = this.props;

    return (
      <>
        <span id={hashcode}>{children}</span>
        <Tooltip
          isOpen={tooltipOpen}
          toggle={() =>
            this.setState({
              tooltipOpen: !tooltipOpen,
            })
          }
          placement="right"
          target={hashcode}
        >
          {helpText}
        </Tooltip>
      </>
    );
  }
}
