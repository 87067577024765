import React from 'react';

export const HorizontalLogo = ({ width, height }: { width?: number; height?: number }) => (
  <img
    style={!width && !height ? { width: '100%' } : {}}
    width={width}
    height={height}
    alt=""
    src="/assets/logo/horizontal.svg"
  />
);
export const HorizontalWhiteLogo = ({ width, height }: { width?: number; height?: number }) => (
  <img
    style={!width && !height ? { width: '100%' } : {}}
    width={width}
    height={height}
    alt=""
    src="/assets/logo/horizontal-white.svg"
  />
);
export const SquareLogo = ({ width, height }: { width?: number; height?: number }) => (
  <img
    style={!width && !height ? { width: '100%' } : {}}
    width={width}
    height={height}
    alt=""
    src="/assets/logo/square.svg"
  />
);
