/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import MaskedInput from 'react-text-mask';
import Input from 'reactstrap/lib/Input';

export default (props: any) => (
  <MaskedInput
    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholder="Enter a phone number"
    {...props}
    // tslint:disable-next-line:no-shadowed-variable
    render={(ref: any, innerProps: any) => <Input invalid={props.error !== undefined} innerRef={ref} {...innerProps} />}
  />
);
