import { ReloadIcon } from 'mdi-react';
import React from 'react';
import { CardBody, CardTitle } from 'reactstrap';
import { client } from '../../lib/apollo';
import IVoicemail from './IVoicemail';
import VoicemailDisplay from './VoicemailDisplay';

interface IProps {
  voicemails: IVoicemail[];
}

export default (props: IProps) => (
  <CardBody className="table-responsive">
    <CardTitle className="text-uppercase h6">
      <a
        style={{ float: 'right' }}
        onClick={() => {
          client.resetStore();
        }}
        onKeyDown={() => {
          client.resetStore();
        }}
      >
        <ReloadIcon />
      </a>
    </CardTitle>
    <table className="table">
      <tbody>
        {props.voicemails.map(voicemail => (
          <VoicemailDisplay key={voicemail.id} voicemail={voicemail} />
        ))}
        {props.voicemails.length === 0 && <p>You have no voicemails...</p>}
      </tbody>
    </table>
  </CardBody>
);
