import * as React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ExtendedTransactionDataTable from './ExtendedTransactionDataTable';

interface IProps {
  usage: boolean;
  close: () => void;
}

export default (props: IProps) => (
  <Modal isOpen toggle={props.close} size="lg">
    <ModalHeader>All {props.usage ? 'Usage' : 'Transactions'}</ModalHeader>
    <ModalBody>
      <ExtendedTransactionDataTable usage={props.usage} />
    </ModalBody>
  </Modal>
);
