import gql from 'graphql-tag';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import RemoteDataTable from 'src/components/data-table/RemoteDataTable';
import CopyButton from 'src/components/generic/CopyButton';
import { formatAbsoluteDate } from 'src/components/generic/DateHelpers';
import HelperTooltip from 'src/components/generic/HelperTooltip';
import StatusIndicator from 'src/components/generic/StatusIndicator';
import { client } from 'src/lib/apollo';

export default () => <TransactionTable />;

const TransactionTable = () => {
  return (
    <RemoteDataTable
      getDataLength={async (search: string) => {
        return ((await client.query({
          query: gql`
            query($search: String!) {
              getNumberOfCallAccessPermits(search: $search)
            }
          `,
          variables: { search },
        })).data as any).getNumberOfCallPermit;
      }}
      getData={async (limit: number, offset: number, sortField: string, sortType: string, search: string) => {
        return ((await client.query({
          query: gql`
            query($limit: Int!, $offset: Int!, $sortField: String!, $sortType: String!, $search: String!) {
              getCallAccessPermitPage(
                limit: $limit
                offset: $offset
                sortField: $sortField
                sortType: $sortType
                search: $search
              ) {
                id
                createdDate
                name
                mostRecentCall {
                  end_time
                }
                callCount
                totalMillisecondsCalled
                valid
              }
            }
          `,
          variables: { limit, offset, sortField, sortType, search },
        })).data as any).getCallAccessPermitPage;
      }}
      defaultSortField="name"
      defaultSortType="DESC"
    >
      <TableHeaderColumn
        dataField="id"
        isKey
        width="80px"
        dataFormat={data => (
          <HelperTooltip helpText="Copy ID to clipboard">
            <CopyButton toCopy={data} />
          </HelperTooltip>
        )}
      >
        ID
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="createdDate"
        dataFormat={data => {
          return formatAbsoluteDate(new Date(data));
        }}
        dataSort
      >
        Created Date
      </TableHeaderColumn>
      <TableHeaderColumn dataSort dataField="name">
        Name
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="mostRecentCall"
        dataFormat={data => {
          if (!data) {
            return 'Never';
          }
          return formatAbsoluteDate(new Date(data.end_time));
        }}
      >
        Last Call Date
      </TableHeaderColumn>
      <TableHeaderColumn dataSort dataField="callCount" dataFormat={data => `${data} calls`}>
        Call Count
      </TableHeaderColumn>
      <TableHeaderColumn
        dataSort
        dataField="totalMillisecondsCalled"
        dataFormat={data => {
          return `${Math.ceil(data / 1000 / 60)} minutes`;
        }}
      >
        Call Length
      </TableHeaderColumn>
      <TableHeaderColumn dataFormat={data => <StatusIndicator active={data} />} dataField="valid" width="150px">
        Status
      </TableHeaderColumn>
    </RemoteDataTable>
  );
};
