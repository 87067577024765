import React from 'react';
import { Icon, Button } from 'antd';
import { Spacing } from '../Spacing';
import { CrowdCallTheme } from '../../style/CrowdCallTheme';
import { VerticalCenterDiv } from '../VerticalCenterDiv';
import { SubtleLink } from '../../style/SubtleLink';

export type WizardActionsProps = {
  back?: () => void;
  nextDisabled?: boolean;
  next: () => void;
  nextText?: string;
  backText?: string;
};

export type WizardActionsState = {
  loading: boolean;
};

export class WizardActions extends React.Component<WizardActionsProps, WizardActionsState> {
  state = {
    loading: false,
  };

  /* componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  onKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.onSubmit();
    }
  }; */

  onSubmit = async (e: MouseEvent) => {
    e.preventDefault();
    const { next } = this.props;
    this.setState({ loading: true });
    try {
      await next();
    } catch (e) {
      console.error(e);
    }
    this.setState({ loading: false });
  };

  render() {
    const { back, backText, nextDisabled, nextText } = this.props;
    const { loading } = this.state;
    return (
      <Spacing top={2}>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0 auto',
            width: '216px',
          }}
        >
          <div css={{ width: 50 }}>
            {back && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <SubtleLink onClick={back} css={{ color: CrowdCallTheme.colors.gray[7] }}>
                <VerticalCenterDiv>
                  <Icon type="arrow-left" />
                  <Spacing inline left={1}>
                    {backText || 'Back'}
                  </Spacing>
                </VerticalCenterDiv>
              </SubtleLink>
            )}
          </div>
          <Button
            htmlType="submit"
            disabled={nextDisabled}
            loading={loading}
            onClick={this.onSubmit}
            type="primary"
            size="large"
          >
            {nextText || 'Next'}
          </Button>
          <div css={{ width: 50 }}>{/* empty div to make button centered in flexbox */}</div>
        </div>
      </Spacing>
    );
  }
}
