import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default (props: { centered: boolean }) => (
  <ToastContainer
    position={props.centered ? 'top-center' : ('top-right' as any)}
    autoClose={7000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    pauseOnHover
    pauseOnFocusLoss
    draggable
  />
);
