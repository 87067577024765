import gql from 'graphql-tag';
import { client } from '../../../lib/apollo';

export default async function deleteNumber(toDelete: string) {
  await client.mutate({
    variables: { number: toDelete },
    mutation: gql`
      mutation($number: String!) {
        deletePhoneNumber(number: $number)
      }
    `,
  });
  client.resetStore();
}
