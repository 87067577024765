import React, { createContext, Dispatch, useReducer } from 'react';

export enum InteractionStatus {
  Started = 'STARTED',
  Skipped = 'SKIPPED',
  Contacted = 'CONTACTED',
  NotHome = 'NOT_HOME',
  CallBack = 'CALL_BACK',
  Busy = 'BUSY',
  LeftMessage = 'LEFT_MESSAGE',
  DoNotCall = 'DO_NOT_CALL',
  Deceased = 'DECEASED',
  Moved = 'MOVED',
  WrongNumber = 'WRONG_NUMBER',
  Disconncted = 'DISCONNCTED',
}

export type InteractionData = {
  contact: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  caller: {
    firstName: string;
    lastName: string;
  };
  loading: boolean;
  scriptData: Record<string, any>;
  customFieldData: Record<string, any>;
  interactionId: string;
  called: boolean;
  interactionStatus: InteractionStatus;
};

export enum InteractionDataActionTypes {
  SET_SCRIPT_DATA = 'SET_SCRIPT_DATA',
  SET_DATA = 'SET_DATA',
  SET_LOADING = 'SET_LOADING',
}

export type InteractionDataAction = {
  type: InteractionDataActionTypes;
  key?: string;
  value: any;
};

const reducer: React.Reducer<InteractionData, InteractionDataAction> = (
  state: InteractionData,
  action: InteractionDataAction,
) => {
  switch (action.type) {
    case InteractionDataActionTypes.SET_SCRIPT_DATA: {
      const { key, value } = action;
      return {
        ...state,
        scriptData: { ...state.scriptData, [key!]: value },
      };
    }
    case InteractionDataActionTypes.SET_DATA: {
      const { value } = action;
      return {
        ...state,
        ...value,
      };
    }
    case InteractionDataActionTypes.SET_LOADING: {
      const { value } = action;
      return {
        ...state,
        loading: value,
      };
    }
    default:
      throw new Error('Invalid interaction state action type');
  }
};

const initialState = {
  contact: {
    firstName: '',
    lastName: '',
  },
  caller: {
    firstName: '',
    lastName: '',
  },
  scriptData: {},
  customFieldData: {},
  interactionId: '',
  interactionStatus: InteractionStatus.Contacted,
  loading: true,
  called: false,
};

export const InteractionDataContext = createContext<[InteractionData, Dispatch<InteractionDataAction>]>(undefined);
export const InteractionDataProvider = ({
  children,
  customState,
}: {
  children: any;
  customState?: typeof initialState;
}) => {
  const reactReducer = useReducer(reducer, customState || initialState);
  return <InteractionDataContext.Provider value={reactReducer}>{children}</InteractionDataContext.Provider>;
};
