import React, { useContext } from 'react';
import { HorizontalCenterDiv } from '@crowdcall/shared/src/components/container/HorizontalCenterDiv';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import { Result } from 'antd';
import { getCleanErrorMessage } from '@crowdcall/shared/src/util/errorUtil';
import CopyButton from 'src/components/generic/CopyButton';
import { WizardActions } from '@crowdcall/shared/src/components/container/wizard/WizardActions';
import { PhoneBankWizardContext } from '../PhoneBankEditWizard';
import { PhoneBankWizardPage } from '../PhoneBankWizardNavigation';

export const PhoneBankSharing = () => {
  // const [useCode, setUseCode] = useState(false);
  const context = useContext(PhoneBankWizardContext);

  return (
    <HorizontalCenterDiv css={{ marginTop: 64 }}>
      <span
        css={{
          fontSize: 24,
        }}
      >
        To use this phone bank, have your callers navigate to
      </span>
      <Query
        query={gql`
          query($phoneBankId: String!) {
            getPhoneBank(id: $phoneBankId) {
              id
              callAccessPermit {
                id
                humanAccessCode
              }
            }
          }
        `}
        variables={{ phoneBankId: context.phoneBankId }}
      >
        {result => {
          const { data, loading, error } = result;
          if (loading) {
            return <ThreeDotsLoader />;
          }
          if (error) {
            return <Result status="error" title="An error occured" subTitle={getCleanErrorMessage(error)} />;
          }
          const prettyLink = `call.crowdcall.us/${data.getPhoneBank.callAccessPermit.humanAccessCode}`;
          return (
            <div css={{ display: 'flex', justifyContent: 'center', margin: 16 }}>
              <a
                href={`${process.env.DIALER_URL}/${data.getPhoneBank.callAccessPermit.humanAccessCode}`}
                css={{ fontSize: 24, marginRight: 16 }}
              >
                {prettyLink}
              </a>
              <CopyButton toCopy={`https://${prettyLink}`} />
            </div>
          );
        }}
      </Query>
      {/*
      <span css={{ color: CrowdCallTheme.colors.gray[7] }}>
        Don&apos;t like this link?
        <Popover
          content={
            <div css={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              Are you sure you want to generate a new link?
              <br />
              The old link will never work again.
              <Spacing top={2}>
                <Button
                  type="danger"
                  css={{ margin: '0 auto' }}
                  block
                  onClick={() => {
                    alert('new link generating!');
                  }}
                >
                  Generate New Link
                </Button>
              </Spacing>
            </div>
          }
          trigger="click"
          title="Are you sure?"
        >
          <Button type="link" css={{ paddingLeft: 8 }}>
            Generate a new one
          </Button>
        </Popover>
        </span>
        */}
      <WizardActions
        back={() => context.setPage(PhoneBankWizardPage.SCRIPT)}
        next={() => context.close()}
        nextText="Done"
      />
    </HorizontalCenterDiv>
  );
};
