import { adminTokenStorage, callerTokenStorage } from '@crowdcall/dashboard/src/lib/storage';
import genericApolloClient from '@crowdcall/shared/src/graphql/genericApolloClient';
import { toast } from 'react-toastify';
import { Settings } from './settings';

const onAccessDenied = () => {
  adminTokenStorage.remove();
  // eslint-disable-next-line no-restricted-globals
  location.href = '/login';
};

const notifyError = (errorMessage: string) => {
  toast.error(errorMessage);
};

const getHeaders = async () => {
  return {
    admin_token: adminTokenStorage.get(),
    caller_token: callerTokenStorage.get(),
  };
};

export const client = genericApolloClient(Settings.api_server, notifyError, getHeaders, onAccessDenied);
