import React from 'react';
import { Button } from 'antd';

export const CallLogExport = () => {
  return (
    <form method="get" action={`${process.env.REACT_APP_API_SERVER}/exports/outbound`}>
      <Button htmlType="submit">Export Call Logs</Button>
    </form>
  );
};
