import styled from '@emotion/styled';

export const ForceOverlayDiv = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 5000;
`;
