import * as React from 'react';
import { Card, CardTitle, Row } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import { Button } from 'antd';
import DashboardContainer from '../../components/layout/container/DashboardContainer';
import UserList from './PermitList';
import { CallLogExport } from './CallLogExport';

export default () => (
  <div>
    <DashboardContainer title="Permits" description="Manage who has access to make calls with this account">
      <Row>
        <div className="mb-4 col-12">
          <Card style={{ padding: '0 0 20px 0' }}>
            <CardBody className="table-responsive">
              <CardTitle className="text-uppercase h6">Permits with call access</CardTitle>
              <CallLogExport />
              <UserList />
            </CardBody>
          </Card>
        </div>
      </Row>
    </DashboardContainer>
  </div>
);
