/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import ToastNotificationContainer from '@crowdcall/shared/src/components/ToastNotificationContainer';
import Navbar from '../navbar/Navbar';
import PageHeader from '../page-header/PageHeader';
import Sidebar from '../sidebar/Sidebar';

export default class DashboardContainer extends React.Component<{
  title: string;
  description?: string | React.ReactNode;
  children: any;
}> {
  public state = {
    showSidebar: true,
  };

  private toggleSidebar = (e: any) => {
    e.preventDefault();
    const wasOpen = this.state.showSidebar;
    this.setState({ showSidebar: !wasOpen });
  };

  public render() {
    return (
      <div>
        <div
          className="menu-dropshadow"
          style={{ display: this.state.showSidebar ? 'none' : 'block' }}
          onClick={this.toggleSidebar}
        />
        <Sidebar mini={!this.state.showSidebar} toggleSidebar={this.toggleSidebar} />
        <div className={`content-container  ${!this.state.showSidebar ? 'full' : ''}`}>
          <div className="view">
            <Navbar toggleSidebar={this.toggleSidebar} />

            <PageHeader {...this.props} />

            <div className="view-content">{this.props.children}</div>
          </div>
        </div>
        <ToastNotificationContainer centered={false} />
      </div>
    );
  }
}
