import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import Card from 'reactstrap/lib/Card';
import { Result } from 'antd';
import { getCleanErrorMessage } from '@crowdcall/shared/src/util/errorUtil';
import { PhonebankCard } from './PhonebankCard';

export const PhonebankCardGrid = () => {
  return (
    <Query
      query={gql`
        query {
          getCurrentUser {
            id
            phoneBanks {
              id
              name
              createDate
              contactCount
              completedInteractionCount
              waitingInteractionCount
            }
          }
        }
      `}
    >
      {({ loading, data, error }) => {
        if (loading) {
          return <ThreeDotsLoader />;
        }
        if (error) {
          return (
            <Card css={{ width: 600 }}>
              <Result status="error" title="Could not fetch phone banks" subTitle={getCleanErrorMessage(error)} />
            </Card>
          );
        }
        return data.getCurrentUser.phoneBanks.map(phoneBank => (
          <PhonebankCard
            name={phoneBank.name}
            id={phoneBank.id}
            createDate={new Date(phoneBank.createDate)}
            key={phoneBank.id}
            completedInteractionCount={phoneBank.completedInteractionCount}
            waitingInteractionCount={phoneBank.waitingInteractionCount}
            contactCount={phoneBank.contactCount}
          />
        ));
      }}
    </Query>
  );
};
