import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import gql from 'graphql-tag';
import * as React from 'react';
import { Query } from 'react-apollo';
import BalanceChart from './BalanceChart';

export default () => (
  <Query
    query={gql`
      {
        historicalBalance: getHistoricalDailyBalance(length: 30)
      }
    `}
  >
    {({ loading, error, data }) => {
      if (loading) {
        return <ThreeDotsLoader />;
      }
      if (error) {
        return <p>{error}</p>;
      }
      return (
        <BalanceChart
          data={data.historicalBalance.map((balance: number, i: number) => {
            const daysAgo = 29 - i;
            const date = new Date(Date.now() - daysAgo * 24 * 60 * 60 * 1000);
            return {
              date: `${date.toLocaleString('en-us', { month: 'long' })} ${date.getDate()}`,
              balance: Number(balance.toFixed(2)),
            };
          })}
        />
      );
    }}
  </Query>
);
