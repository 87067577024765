import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import gql from 'graphql-tag';
import * as React from 'react';
import { Query } from 'react-apollo';
import CardBody from 'reactstrap/lib/CardBody';
import NumberEditForm from './NumberEditForm';

const query = gql`
  query($number: String!) {
    currentSettings: getPhoneNumber(number: $number) {
      id
      nickname
      callForwardNumber
      allowedCallForwardHours
      autoForward
      automatedVoicemailMessage
      automatedForwardMessage
    }
  }
`;

interface IProps {
  number: string;
  close: () => void;
}

export default (props: IProps) => (
  <Query query={query} variables={{ number: props.number }}>
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <CardBody>
            <p className="text-uppercase h5">Loading number data...</p>
            <div style={{ justifyContent: 'center', marginTop: '80px', display: 'flex' }}>
              <ThreeDotsLoader />
            </div>
          </CardBody>
        );
      }
      if (error) {
        return (
          <CardBody>
            <p className="text-uppercase h5">An error occured...</p>
            <span className="h7 mb-4">{error.message}</span>
          </CardBody>
        );
      }

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <NumberEditForm close={props.close} number={props.number} {...data.currentSettings} />;
    }}
  </Query>
);
