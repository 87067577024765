import { FormikProps, withFormik } from 'formik';
import gql from 'graphql-tag';
import { TrashIcon, WarningIcon } from 'mdi-react';
import * as React from 'react';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import TimeRange from '@crowdcall/shared/src/components/timerange/TimeRange';
import ConfirmButton from '../../../components/form/ConfirmButton';
import PhoneNumberInput from '../../../components/form/PhoneNumberInput';
import HelperTooltip from '../../../components/generic/HelperTooltip';
import { client } from '../../../lib/apollo';
import {
  getPhoneNumberFromDirtyString,
  isValidPhoneNumber,
  prepareCleanNumberForInput,
} from '../../../lib/phoneNumber';
import deleteNumber from './deleteNumberAction';

const form = (props: IProps & FormikProps<IFormValues>) => (
  <Form>
    <ModalBody>
      <FormGroup className="mb-4">
        <Label>Name</Label>{' '}
        <HelperTooltip helpText="What do you want to name this phone number? This is only for your reference. You should write something that will help you remember what you're using this phone number for." />
        <Input
          name="nickname"
          onChange={props.handleChange}
          value={props.values.nickname}
          placeholder="Obama Campaign Phone Line"
          invalid={props.errors.nickname !== undefined}
        />
        <FormFeedback>{props.errors.nickname}</FormFeedback>
      </FormGroup>

      <h5>Incoming Call Behavior</h5>
      <FormGroup className="mb-4">
        <Label>Call Forward Number</Label>{' '}
        <HelperTooltip helpText="What phone number should calls be forwarded to? This will only take effect during the business hours listed below." />
        <PhoneNumberInput
          name="callForwardNumber"
          onChange={props.handleChange}
          value={props.values.callForwardNumber ? prepareCleanNumberForInput(props.values.callForwardNumber) : ''}
          invalid={props.errors.callForwardNumber !== undefined}
        />
        <FormFeedback>{props.errors.callForwardNumber}</FormFeedback>
      </FormGroup>
      {props.values.callForwardNumber && isValidPhoneNumber(props.values.callForwardNumber) && (
        <>
          <FormGroup className="mb-4">
            Forward calls{' '}
            <TimeRange
              defaultValue={props.values.allowedCallForwardHours}
              onChange={value => {
                props.setFieldValue('allowedCallForwardHours', value);
              }}
            />
            <HelperTooltip helpText="What times every day should calls be forwarded? Calls will go straight to voice mail outside of these hours." />
            <FormFeedback>{props.errors.allowedCallForwardHours}</FormFeedback>
          </FormGroup>

          <FormGroup className="mb-4">
            <Label for="autoForward">Should callers be offered a menu during business hours?</Label>{' '}
            <HelperTooltip helpText="If turned on, callers will be offered to 'click 1 to be transferred' or 'press 2 to leave a message'. If turned off, callers will be automatically transferred during business hours." />
            <br />
            <Switch
              onChange={() => {
                props.setFieldValue('autoForward', !props.values.autoForward);
              }}
              id="autoForward"
              checked={!props.values.autoForward}
            />
            <FormFeedback>{props.errors.autoForward}</FormFeedback>
          </FormGroup>

          {!props.values.autoForward && (
            <FormGroup className="mb-4">
              <Label>Transfer Menu Message</Label>{' '}
              <HelperTooltip helpText="The message that will play when a user needs to choose if they want to be transfered or leave a message. This will only play during business hours. This should instruct callers to press 1 for a representative, or 2 to leave a message." />
              <Input
                name="automatedForwardMessage"
                onChange={props.handleChange}
                type="textarea"
                value={props.values.automatedForwardMessage}
                placeholder="Hello! Thanks for calling our campaign. To be redirected to a campaign representative, press 1. To leave a message, press 2."
                invalid={props.errors.automatedForwardMessage !== undefined}
              />
              <FormFeedback>{props.errors.automatedForwardMessage}</FormFeedback>
            </FormGroup>
          )}
        </>
      )}
      <FormGroup className="mb-4">
        <Label>Voicemail Message</Label>{' '}
        <HelperTooltip helpText="The message that will play when a user cannot be transferred and is about to leave a voicemail. This should prompt them to leave their message after the beep." />
        <Input
          name="automatedVoicemailMessage"
          onChange={props.handleChange}
          type="textarea"
          value={props.values.automatedVoicemailMessage}
          placeholder="Hello! Thanks for calling our campaign. Please leave a message and well get back to you as soon as possible."
          invalid={props.errors.automatedVoicemailMessage !== undefined}
        />
        <FormFeedback>{props.errors.automatedVoicemailMessage}</FormFeedback>
      </FormGroup>

      {!props.new && (
        <FormGroup className="text-left">
          <Label>Delete this number?</Label>{' '}
          <HelperTooltip helpText="If you click the button below, the number will be deleted. This action is irreversible." />
          <br />
          <ConfirmButton
            prompt={
              <>
                <TrashIcon /> Delete Number
              </>
            }
            confirmation={
              <>
                <WarningIcon /> Confirm Deletion
              </>
            }
            onConfirm={async () => {
              await deleteNumber(props.number);
              props.close();
            }}
          />
        </FormGroup>
      )}
    </ModalBody>

    <ModalFooter>
      <FormGroup className="text-left">
        <Button
          onClick={() => {
            props.close();
          }}
          type="button"
          color="secondary"
        >
          Cancel
        </Button>
      </FormGroup>
      <FormGroup className="text-right">
        <Button disabled={props.isSubmitting} onClick={props.submitForm} type="submit" color="success">
          Save Changes
        </Button>
      </FormGroup>
    </ModalFooter>
  </Form>
);

interface IFormValues {
  nickname: string;
  callForwardNumber?: string;
  allowedCallForwardHours: boolean[];
  autoForward: boolean;
  automatedVoicemailMessage: string;
  automatedForwardMessage: string;
}

interface IProps extends IFormValues {
  number: string;
  new?: boolean;
  close: () => void;
}

export default withFormik<IProps, IFormValues>({
  // Initial values empty
  mapPropsToValues: props => {
    const callHours = [...props.allowedCallForwardHours];
    while (callHours.length < 24) {
      callHours.push(false);
    }
    return { ...props, allowedCallForwardHours: callHours };
  },

  validationSchema: Yup.object().shape({
    nickname: Yup.string().required('Number name is required!'),
    allowedCallForwardHours: Yup.array()
      .ensure()
      .min(24)
      .max(24),
  }),

  handleSubmit: async (values, actions) => {
    try {
      await client.mutate({
        variables: {
          ...values,
          number: actions.props.number,
          callForwardNumber: values.callForwardNumber
            ? getPhoneNumberFromDirtyString(values.callForwardNumber)
            : undefined,
        },
        mutation: gql`
          mutation(
            $number: String!
            $nickname: String!
            $callForwardNumber: String
            $allowedCallForwardHours: [Boolean!]!
            $autoForward: Boolean!
            $automatedVoicemailMessage: String!
            $automatedForwardMessage: String!
          ) {
            updatePhoneNumber(
              numberToModify: $number
              nickname: $nickname
              callForwardNumber: $callForwardNumber
              allowedCallForwardHours: $allowedCallForwardHours
              autoForward: $autoForward
              automatedVoicemailMessage: $automatedVoicemailMessage
              automatedForwardMessage: $automatedForwardMessage
            ) {
              id
              nickname
              callForwardNumber
              allowedCallForwardHours
              autoForward
              automatedVoicemailMessage
              automatedForwardMessage
            }
          }
        `,
      });
      if (!actions.props.new) {
        toast.success('Number updated!');
      }
      actions.setSubmitting(false);
      actions.props.close();
    } catch (e) {
      actions.setSubmitting(false);
      // do nothing since apollo.ts will display it already
    }
  },
})(form);
