import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import gql from 'graphql-tag';
import * as React from 'react';
import { Query } from 'react-apollo';
import { Card, CardBody, CardTitle } from 'reactstrap';
import PhoneNumberCard from './PhoneNumberCard';

const query = gql`
  {
    numbers: listOwnedPhoneNumbers {
      id
      nickname
      number
      location
    }
  }
`;

export default () => {
  return (
    <>
      <Query query={query}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <Card
                style={{
                  width: '350px',
                  height: '350px',
                  margin: '20px',
                }}
              >
                <CardBody>
                  <CardTitle className="text-uppercase h5">Loading your numbers...</CardTitle>
                  <div style={{ justifyContent: 'center', marginTop: '80px', display: 'flex' }}>
                    <ThreeDotsLoader />
                  </div>
                </CardBody>
              </Card>
            );
          }
          if (error) {
            return (
              <Card
                style={{
                  width: '350px',
                  height: '350px',
                  margin: '20px',
                }}
              >
                <CardBody>
                  <CardTitle className="text-uppercase h5">An error occured...</CardTitle>
                  <span className="h7 mb-4">{error.message}</span>
                </CardBody>
              </Card>
            );
          }

          return (
            <>
              {data.numbers.map((currentNumber: any) => (
                <PhoneNumberCard
                  key={currentNumber.number}
                  nickname={currentNumber.nickname}
                  location={currentNumber.location}
                  number={currentNumber.number}
                />
              ))}
            </>
          );
        }}
      </Query>
    </>
  );
};
