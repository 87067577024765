import * as React from 'react';
import { Card, CardBody, CardTitle, Button } from 'reactstrap';
import { PlusIcon } from 'mdi-react';
import NumberBuyModal from './NumberBuyModal';

interface IState {
  modalOpen: boolean;
}

export default class extends React.Component<any, IState> {
  public state = {
    modalOpen: false,
  };

  public render() {
    return (
      <>
        {this.state.modalOpen && <NumberBuyModal close={() => this.setState({ modalOpen: false })} />}
        <Card
          style={{
            width: '350px',
            height: '350px',
            margin: '20px',
          }}
        >
          <CardBody>
            <CardTitle className="text-uppercase h5">Add more numbers!</CardTitle>
            <span className="h7 mb-4">
              People are more likely to pick up from a phone number in a local area code...
            </span>
            <Button
              onClick={() => {
                this.setState({ modalOpen: true });
              }}
              color="success"
              style={{ float: 'left', marginTop: '60%' }}
            >
              <PlusIcon /> Buy New Number
            </Button>
          </CardBody>
        </Card>
      </>
    );
  }
}
