export const getNumberOfDigitsAfterDecimal = (value: number): number => {
  return (value - Math.floor(Math.abs(value))).toString().length;
};

export const formatCurrency = (value: number): string => {
  let num = Math.abs(value).toFixed(2);
  if (getNumberOfDigitsAfterDecimal(value) >= 3) {
    num = Math.abs(value).toFixed(3);
  }
  return `${value < 0 ? '-' : ''}$${num.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};
