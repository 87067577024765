import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

import { gql } from 'apollo-boost';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import HelperTooltip from 'src/components/generic/HelperTooltip';

import RemoteDataTable from '../../../components/data-table/RemoteDataTable';
import CopyButton from '../../../components/generic/CopyButton';
import { formatAbsoluteDate } from '../../../components/generic/DateHelpers';
import { client } from '../../../lib/apollo';
import { formatCurrency } from '../../../lib/numbers';
import PaymentStatusIndicator from '../components/PaymentStatusIndicator';

interface IProps {
  usage: boolean;
}
export default (props: IProps) => {
  return (
    <>
      <RemoteDataTable
        getDataLength={async (search: string) => {
          return ((await client.query({
            query: gql`
              query($usage: Boolean!, $search: String!) {
                getNumberOfTransactions(usage: $usage, search: $search)
              }
            `,
            variables: { usage: props.usage, search },
          })).data as any).getNumberOfTransactions;
        }}
        getData={async (limit: number, offset: number, sortField: string, sortType: string, search: string) => {
          return ((await client.query({
            query: gql`
              query(
                $usage: Boolean!
                $limit: Int!
                $offset: Int!
                $sortField: String!
                $sortType: String!
                $search: String!
              ) {
                getTransactionPage(
                  usage: $usage
                  limit: $limit
                  offset: $offset
                  sortField: $sortField
                  sortType: $sortType
                  search: $search
                ) {
                  id
                  date
                  amount
                  shortDescription
                  extendedDescription
                  status
                  transactionId
                }
              }
            `,
            variables: { usage: props.usage, limit, offset, sortField, sortType, search },
          })).data as any).getTransactionPage.map((transaction: any) => ({
            ...transaction,
            amount: formatCurrency(Math.abs(transaction.amount)),
          }));
        }}
        defaultSortField="date"
        defaultSortType="DESC"
      >
        <TableHeaderColumn
          dataField="id"
          isKey
          width="80px"
          dataFormat={data => (
            <HelperTooltip helpText="Copy ID to clipboard">
              <CopyButton toCopy={data} />
            </HelperTooltip>
          )}
        >
          ID
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="date"
          dataFormat={data => {
            return formatAbsoluteDate(new Date(data));
          }}
          dataSort
        >
          Date
        </TableHeaderColumn>
        <TableHeaderColumn dataField="amount" dataSort width="100px">
          Amount
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="shortDescription"
          width="220px"
          dataFormat={(data, row) => <HelperTooltip helpText={row.extendedDescription}>{data}</HelperTooltip>}
        >
          Explanation
        </TableHeaderColumn>
        <TableHeaderColumn
          dataFormat={data => <PaymentStatusIndicator status={data} />}
          dataField="status"
          width="150px"
        >
          Status
        </TableHeaderColumn>
        <TableHeaderColumn hidden export dataField="extendedDescription" />
        <TableHeaderColumn hidden export dataField="transactionId" />
      </RemoteDataTable>
    </>
  );
};
