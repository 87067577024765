import * as React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import HelperToolTip from 'src/components/generic/HelperTooltip';
import { formatAbsoluteDate, formatRelativeDate } from '../../../components/generic/DateHelpers';
import { formatCurrency } from '../../../lib/numbers';
import PaymentStatusIndicator from '../components/PaymentStatusIndicator';
import TransactionList from './TransactionList';

export const AddedFundsList = () => (
  <Card style={{ height: '100%' }}>
    <CardBody className="table-responsive">
      <CardTitle className="text-uppercase h6">Latest Added Funds</CardTitle>
      <TransactionList
        usage={false}
        renderRow={({ transaction }) => (
          <tr>
            <td className="d-flex flex-column">
              <strong>Added {formatCurrency(transaction.amount)}</strong>
              <small>{formatAbsoluteDate(transaction.date)}</small>
            </td>
            <td className="align-middle">{transaction.shortDescription}</td>
            <td className="align-middle text-nowrap">
              <PaymentStatusIndicator status={transaction.status} />
            </td>
          </tr>
        )}
      />
    </CardBody>
  </Card>
);

export const UsageList = () => (
  <Card>
    <CardBody className="table-responsive">
      <CardTitle className="text-uppercase h6">Latest Usage</CardTitle>
      <TransactionList
        usage
        renderRow={({ transaction }) => (
          <tr>
            <td className="d-flex flex-column">
              <strong>
                <HelperToolTip helpText={transaction.extendedDescription}>{transaction.shortDescription}</HelperToolTip>
              </strong>
              <small>{formatRelativeDate(transaction.date)}</small>
            </td>
            <td className="align-middle">{formatCurrency(transaction.amount)}</td>
          </tr>
        )}
      />
    </CardBody>
  </Card>
);
