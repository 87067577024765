import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import { gql } from 'apollo-boost';
import { withFormik } from 'formik';
import * as React from 'react';
import ScriptLoader from 'react-script-loader-hoc';
import { CardElement, Elements, injectStripe, StripeProvider } from 'react-stripe-elements';
import { toast } from 'react-toastify';
import { Button, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { client } from '../../../lib/apollo';
import { Settings } from '../../../lib/settings';

const wrapper = (props: { close: () => void; scriptsLoadedSuccessfully: boolean }) => {
  if (!props.scriptsLoadedSuccessfully) {
    return <ThreeDotsLoader />;
  }
  return (
    <StripeProvider apiKey={Settings.stripe_public_key}>
      <Elements>
        <InnerForm close={props.close} />
      </Elements>
    </StripeProvider>
  );
};

export default ScriptLoader('https://js.stripe.com/v3/')(wrapper);

const innerFormView = (props: any) => {
  const { isSubmitting } = props;

  return (
    <>
      {isSubmitting && (
        <div style={{ margin: '0 auto' }}>
          <ThreeDotsLoader />
        </div>
      )}
      <div style={isSubmitting ? { display: 'none' } : {}}>
        <FormGroup>
          <Label>Your Name</Label>
          <Input
            name="name"
            onChange={props.handleChange}
            value={props.values.name}
            placeholder="Your Name"
            invalid={props.errors.name !== undefined}
          />
          <FormFeedback>{props.errors.name}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Credit Card Details</Label>
          <CardElement className="form-control" />
        </FormGroup>
        <br />
        <FormGroup>
          <Label>Address Line 1</Label>
          <Input
            name="address_line_1"
            onChange={props.handleChange}
            value={props.values.address_line_1}
            placeholder="Address Line 1"
            invalid={props.errors.address_line_1 !== undefined}
          />
          <FormFeedback>{props.errors.address_line_1}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Address Line 2</Label>
          <Input
            name="address_line_2"
            onChange={props.handleChange}
            value={props.values.address_line_2}
            placeholder="Address Line 2"
            invalid={props.errors.address_line_2 !== undefined}
          />
          <FormFeedback>{props.errors.address_line_2}</FormFeedback>
        </FormGroup>
        <Row>
          <FormGroup className="col-md-6 col-sm-12">
            <Label>City</Label>
            <Input
              name="city"
              onChange={props.handleChange}
              value={props.values.city}
              placeholder="City"
              invalid={props.errors.city !== undefined}
            />
            <FormFeedback>{props.errors.city}</FormFeedback>
          </FormGroup>
          <FormGroup className="col-md-6 col-sm-12">
            <Label>State</Label>
            <Input
              name="state"
              onChange={props.handleChange}
              value={props.values.state}
              placeholder="State"
              invalid={props.errors.state !== undefined}
            />
            <FormFeedback>{props.errors.state}</FormFeedback>
          </FormGroup>
        </Row>

        <FormGroup className="text-right">
          <Button type="submit" onClick={props.submitForm} color="primary">
            {' '}
            Add Credit Card{' '}
          </Button>
        </FormGroup>
      </div>
    </>
  );
};

interface IFormValues {
  creditcard: string;
  name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
}

const InnerForm = injectStripe(
  withFormik<{ close: () => void }, IFormValues>({
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      address_line_1: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
    }),

    handleSubmit: async (values, actions) => {
      try {
        const { token } = await (actions.props as any).stripe.createToken({
          name: values.name,
          address_line1: values.address_line_1,
          address_line2: values.address_line_2,
          address_state: values.state,
          address_city: values.city,
          address_country: 'US',
        });
        if (!token) {
          toast.error('Please enter a valid credit card...');
          actions.setSubmitting(false);
          return;
        }
        const response = await client.mutate({
          mutation: gql`
            mutation($token: String!) {
              addPaymentSource(token: $token)
            }
          `,
          variables: { token: token.id },
        });
        if (response.data && response.data.addPaymentSource) {
          toast.success('New credit card successfully added to your account');
          client.resetStore();
          actions.props.close();
        }
        actions.setSubmitting(false);
      } catch (e) {
        actions.setSubmitting(false);
      }
    },
  })(innerFormView),
);
