import * as React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';

export default class CallMinutesChart extends React.Component<{}, { activeIndex: number }> {
  private callData = [
    { name: 'Goldsberg', minutes: 5034 },
    { name: 'Brooks', minutes: 953 },
    { name: 'Davis', minutes: 3443 },
  ];

  private trafficSourceColors = ['#448AFF', '#00E676', '#7C4DFF', '#40C4FF'];

  public state = { activeIndex: 0 };

  public onPieEnter = (data: any, index: any) => {
    this.setState({
      activeIndex: index,
    });
  };

  private renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" fontSize={12}>
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  public render() {
    return (
      <ResponsiveContainer>
        <PieChart>
          <Pie
            onMouseEnter={this.onPieEnter}
            data={this.callData}
            activeIndex={this.state.activeIndex}
            activeShape={this.renderActiveShape}
            outerRadius={90}
            dataKey="minutes"
            innerRadius={70}
            paddingAngle={4}
          >
            {this.callData.map((entry, index) => (
              <Cell fill={this.trafficSourceColors[index]} key={entry.minutes} />
            ))}
          </Pie>
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
