import React from 'react';

export type SpacingProps = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  all?: number;
  horizontal?: number;
  vertical?: number;
  inline?: boolean; // inline-block instead of block
  children?: any;
  inner?: boolean; // padding instead of margin
};

const unit = 8;

function toNumber(key: string, props: SpacingProps) {
  return props[key] || 0;
}

export const Spacing = (props: SpacingProps) => {
  const topSum = (toNumber('top', props) + toNumber('all', props) + toNumber('vertical', props)) * unit;
  const bottomSum = (toNumber('bottom', props) + toNumber('all', props) + toNumber('vertical', props)) * unit;
  const leftSum = (toNumber('left', props) + toNumber('all', props) + toNumber('horizontal', props)) * unit;
  const rightSum = (toNumber('right', props) + toNumber('all', props) + toNumber('horizontal', props)) * unit;

  const paddingCss = props.inner
    ? {
        paddingTop: topSum,
        paddingBottom: bottomSum,
        paddingLeft: leftSum,
        paddingRight: rightSum,
      }
    : {
        marginTop: topSum,
        marginBottom: bottomSum,
        marginLeft: leftSum,
        marginRight: rightSum,
      };

  return (
    <div
      css={{
        ...paddingCss,
        display: props.inline ? 'inline-block' : 'block',
      }}
    >
      {props.children}
    </div>
  );
};
