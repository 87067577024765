/* eslint-disable react/prefer-stateless-function */
import * as React from 'react';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export default class CallMinutesChart extends React.Component {
  public render() {
    const callData = [
      { name: 'Monday', goldsberg: 4000, brooks: 2400, davis: 392 },
      { name: 'Tuesday', goldsberg: 3000, brooks: 1398, davis: 5934 },
      { name: 'Wednesday', goldsberg: 2000, brooks: 2200, davis: 500 },
      { name: 'Thursday', goldsberg: 2780, brooks: 3008, davis: 1006 },
      { name: 'Friday', goldsberg: 1890, brooks: 2800, davis: 1963 },
      { name: 'Saturday', goldsberg: 2390, brooks: 3200, davis: 2932 },
      { name: 'Sunday', goldsberg: 3490, brooks: 3300, davis: 4039 },
    ];

    return (
      <ResponsiveContainer>
        <AreaChart data={callData} margin={{ top: 10, right: 10, left: -15, bottom: 0 }}>
          <XAxis
            dataKey="name"
            axisLine={false}
            tick={{ fontSize: 10 }}
            tickLine={false}
            padding={{ left: 0, right: 5 }}
          />
          <YAxis axisLine={false} tick={{ fontSize: 10 }} tickLine={false} />
          <CartesianGrid stroke="#eee" vertical={false} />
          <Tooltip wrapperStyle={{ borderColor: '#eee' }} />
          <Legend />
          <Area
            name="Goldsberg Campaign"
            type="monotone"
            dataKey="goldsberg"
            stackId="1"
            strokeWidth={2}
            stroke="#448AFF"
            fill="#448AFF"
            fillOpacity=".8"
          />
          <Area
            name="Brooks Campaign"
            type="monotone"
            dataKey="brooks"
            stackId="1"
            strokeWidth={2}
            stroke="#69F0AE"
            fill="#69F0AE"
            fillOpacity=".8"
          />
          <Area
            name="Davis Campaign"
            type="monotone"
            dataKey="davis"
            stackId="1"
            strokeWidth={2}
            stroke="#ffd740"
            fill="#ffd740"
            fillOpacity=".8"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
