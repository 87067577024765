import * as React from 'react';
import { Card, CardBody } from 'reactstrap';

export default (props: { icon: JSX.Element; title: string; children: any }) => (
  <Card>
    <CardBody className="d-flex p-3 align-items-center">
      <div
        className="mr-4 bg-success d-flex align-items-center justify-content-center"
        style={{ borderRadius: '2px', width: '48px', height: '48px' }}
      >
        {props.icon}
      </div>
      <div>
        <h5>{props.children}</h5>
        <div>{props.title}</div>
      </div>
    </CardBody>
  </Card>
);
