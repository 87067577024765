import { HorizontalCenterDiv } from '@crowdcall/shared/src/components/container/HorizontalCenterDiv';
import { Spacing } from '@crowdcall/shared/src/components/container/Spacing';
import { WizardActions } from '@crowdcall/shared/src/components/container/wizard/WizardActions';
import { WizardDescription, WizardQuestion } from '@crowdcall/shared/src/components/container/wizard/WizardContainer';
import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import gql from 'graphql-tag';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useApolloClient } from 'react-apollo';
import { SlateEditor } from '@crowdcall/shared/src/components/editor/SlateEditor';
import { useDebounce } from 'use-lodash-debounce';
import { ValueJSON } from 'slate';
import { PhoneBankWizardContext } from '../PhoneBankEditWizard';
import { PhoneBankWizardPage } from '../PhoneBankWizardNavigation';

export const PhoneBankWelcomeText = () => {
  const [welcomeText, setWelcomeText] = useState((undefined as unknown) as ValueJSON);
  const debouncedWelcomeText = useDebounce(welcomeText, 500);
  const [loading, setLoading] = useState(true);
  const context = useContext(PhoneBankWizardContext);
  const client = useApolloClient();

  useEffect(() => {
    async function fetchInitialData() {
      const resp = await client.query({
        query: gql`
          query($phoneBankId: String!) {
            getPhoneBank(id: $phoneBankId) {
              id
              welcomeText
            }
          }
        `,
        variables: { phoneBankId: context.phoneBankId },
      });
      setWelcomeText(resp.data!.getPhoneBank.welcomeText);
      setLoading(false);
    }
    fetchInitialData();
  }, [client, context.phoneBankId]);

  const save = useCallback(async () => {
    if (!debouncedWelcomeText) {
      return;
    }
    await client.mutate({
      mutation: gql`
        mutation($phoneBankId: String!, $welcomeText: JSONObject!) {
          updatePhoneBank(phoneBankId: $phoneBankId, welcomeText: $welcomeText) {
            id
            welcomeText
          }
        }
      `,
      variables: { phoneBankId: context.phoneBankId, welcomeText: debouncedWelcomeText },
    });
  }, [client, context.phoneBankId, debouncedWelcomeText]);

  useEffect(() => {
    save();
  }, [client, save]);

  if (loading) {
    return <ThreeDotsLoader />;
  }

  return (
    <HorizontalCenterDiv>
      <WizardQuestion>How do you want to welcome new callers?</WizardQuestion>
      <WizardDescription>Callers will be presented with this text before they begin making calls.</WizardDescription>
      <div css={{ width: 650, margin: '0 auto' }}>
        <Spacing top={3}>
          <SlateEditor defaultValue={welcomeText} onChange={raw => setWelcomeText(raw)} />
        </Spacing>
        <Spacing top={2}>
          <WizardActions
            back={() => context.setPage(PhoneBankWizardPage.CONTACT)}
            next={async () => {
              await save();
              context.setPage(PhoneBankWizardPage.SCRIPT);
            }}
          />
        </Spacing>
      </div>
    </HorizontalCenterDiv>
  );
};
