import { ModalFooter, FormGroup, Button, ModalBody } from 'reactstrap';
import * as React from 'react';
import gql from 'graphql-tag';
import { ThreeDotsLoader } from '@crowdcall/shared/src/components/Loader';
import { client } from '../../../lib/apollo';
import { formatPhoneNumber } from '../../../lib/phoneNumber';

interface IProps {
  numberToBuy: string;
  back: () => void;
  onComplete: () => void;
}

interface IState {
  loading: boolean;
}

export default class extends React.Component<IProps, IState> {
  public state = {
    loading: false,
  };

  public render() {
    if (this.state.loading) {
      return (
        <ModalBody>
          <ThreeDotsLoader />
        </ModalBody>
      );
    }
    return (
      <ModalFooter>
        <div style={{ display: 'inline-flex', margin: '0 auto', padding: '20px' }}>
          <FormGroup className="text-left">
            <Button
              onClick={async () => {
                const response: any = await client.mutate({
                  variables: { number: this.props.numberToBuy, nickname: formatPhoneNumber(this.props.numberToBuy) },
                  mutation: gql`
                    mutation($number: String!, $nickname: String!) {
                      buyPhoneNumber(nickname: $nickname, numberToPurchase: $number) {
                        id
                      }
                    }
                  `,
                });
                if (response.data) {
                  client.resetStore();
                  this.props.onComplete();
                }
              }}
              type="button"
              size="lg"
              color="primary"
            >
              Yes
            </Button>
          </FormGroup>
          <FormGroup className="text-right">
            <Button
              style={{ marginLeft: '20px' }}
              onClick={() => {
                this.props.back();
              }}
              type="button"
              size="lg"
              color="secondary"
            >
              No
            </Button>
          </FormGroup>
        </div>
      </ModalFooter>
    );
  }
}
